import React from "react";
//components
import { SvgCalendarIcon } from "@/components/SvgIcon";

//styles
import { Wrapper, CardHeader, CardBody, SmallButton, CalendarBtn } from "./styles";

const TeamCard = ({data}) => {

  const [ isHovered, setIsHovered ] = React.useState(false);

  return (
    <Wrapper>
      <CardHeader>
        <p>{data.title}</p>
      </CardHeader>
      {
        data.name ?
          <CardBody>
            <div className={'body'}>
              <div className="d-flex align-items-center">
                <img src={data.profile_img} alt={'Profile Image'} />
                <h3>{data.name}</h3>
              </div>
              <CalendarBtn onMouseOver={() => setIsHovered(true)} onMouseLeave={() =>setIsHovered(false)}>
                <SvgCalendarIcon isHovered={isHovered} />
              </CalendarBtn>
            </div>
            <div className={'footer mobile-hidden'}>
              <SmallButton href={`mailto:${data.email}`}>EMAIL</SmallButton> &nbsp;
              <SmallButton href={data.calendly} target="_blank">BOOK MEETING</SmallButton>
            </div>
          </CardBody>
          :
          <CardBody bgImage={data.bgImage}>
            <div className={'content'}>
              <p>{data.desc}</p>
            </div>
          </CardBody>
      }

    </Wrapper>
  )
};

export default TeamCard;
