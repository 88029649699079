import React from "react";

//styles
import {Wrapper, MobileFooter} from "./styles";

const ClientServiceCard = ({data}) => {
  
  return (
    <Wrapper inActive={data.status === 'Canceled' || data.status === 'Completed'}>
      <div className={'name'}>
        <div className="d-flex align-items-center">
          <img src={data.icon} alt={"Image"} />
          <div>
            <h3>{data.service_name}</h3>
            <p>{data.client_name}</p>
          </div>
        </div>
      </div>
      <div className={'price mobile-hidden'}>
        <p>My Price</p>
        <h3 className={'price-num'}>${data.price} <p>{data.currency} { data.unit && <span>/{data.unit}</span>}</p></h3>
      </div>
      <div className={'subscribed mobile-hidden'}>
        <p>Subscribed</p>
        <h3>{data.subscribed}</h3>
      </div>
      <div className={'status mobile-hidden'}>
        <p>Status</p>
        <h3>{data.status}</h3>
      </div>
      <MobileFooter>
        <div className={'price'}>
          <p>My Price</p>
          <h3 className={'price-num'}>${data.price} <p>{data.currency} { data.unit && <span>/{data.unit}</span>}</p></h3>
        </div>
        <div className={'subscribed'}>
          <p>Subscribed</p>
          <h3>{data.subscribed}</h3>
        </div>
        <div className={'status'}>
          <p>Status</p>
          <h3>{data.status}</h3>
        </div>
      </MobileFooter>
    </Wrapper>
  );
};

export default ClientServiceCard;
