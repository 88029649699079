import React, { Component } from 'react';
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import {STORE_KEYS} from '@/stores';
import { ClientsTargetPanel } from "@/containers/Targets/styles";
import TargetBlock from "@/containers/Targets/TargetBlock";
import {SubTitle} from "../styles";

class ClientTargets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailViewId: '',
    }
  }

  componentDidMount() {
    this.props.requestCloudmebTargetsByClients();
  }

  setDetailViewId = (id) => {
    this.setState({
      detailViewId: id,
    });
  };

  render() {
    const { detailViewId } = this.state;
    const {
      cloudmebTargetsByClients, countOfClientTargets, markTaskAsComplete,
    } = this.props;

    const targetsKeys = Object.keys(cloudmebTargetsByClients);
    const targetsValues = Object.values(cloudmebTargetsByClients);

    return (
      <>
        <SubTitle pl20 className="pt-4">Client Targets <span>{countOfClientTargets}</span></SubTitle>
        {
          targetsKeys.map((key, index) => {
            return (
              <ClientsTargetPanel key={index}>
                <div className={'title'}>
                  <h3>{(key || "").split(":")[0]}</h3>
                  <p>{(key || "").split(":")[1]}</p>
                </div>
                {
                  targetsValues[index].map((item, id) => {
                    const date1 = new Date();
                    const date2 = new Date(item.date);
                    const Difference_In_Time = date2.getTime() - date1.getTime();
                    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                    if (Difference_In_Days < 45) {
                      return (
                        <TargetBlock
                          key={item.index}
                          target={item}
                          showAll={detailViewId !== ''}
                          setDetailViewId={this.setDetailViewId}
                          showContent={false}
                          isClientTasks={true}
                          markTaskAsComplete={markTaskAsComplete}
                          activeClientName={(key || "").split(":")[0]}
                          activeClientId={(key || "").split(":")[2]}
                        />
                      );
                    }
                  })
                }
              </ClientsTargetPanel>
            )
          })
        }
      </>
    );
  }
}

export default compose(
  inject(STORE_KEYS.TARGETSSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.TARGETSSTORE]: {
         markTaskAsComplete,
         cloudmebTargetsByClients,
         requestCloudmebTargetsByClients,
         countOfClientTargets,
       },
     }) => ({
      markTaskAsComplete,
      cloudmebTargetsByClients,
      requestCloudmebTargetsByClients,
      countOfClientTargets,
    })
  )
)(ClientTargets);
