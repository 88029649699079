import React from "react";

//components
import ClientServiceCard from "../ClientServiceCard";

//styles
import { Wrapper, ServiceItemList, Header, Body } from "./styles";

const ClientServiceList = ({data}) => {
  
  const [isShowList, setIsShowList] = React.useState(true);
  
  React.useEffect(() => {
    setIsShowList(data.status !== 'COMPLETED' && data.status !== 'CANCELED');
  }, []);
  
  return (
    <Wrapper>
      <Header>
        <p>
          {data.status}
          <span>{data.services.length}</span>
          {
            (data.status === 'COMPLETED'  || data.status === 'CANCELED') && isShowList &&
            <i
              className="fa fa-angle-up ml-1"
              onClick={() => setIsShowList(!isShowList)}
            />
          }
          {
            (data.status === 'COMPLETED'  || data.status === 'CANCELED') && !isShowList &&
            <i
              className="fa fa-angle-down ml-1"
              onClick={() => setIsShowList(!isShowList)}
            />
          }
        </p>
      </Header>
      <Body>
        {
          data.services.length > 0 &&
            <ServiceItemList>
              {
                isShowList ?
                  data.services.map(item => (
                    <ClientServiceCard data={item} key={item.id} />
                  ))
                  :
                  <div className="pt-3"></div>
              }
            </ServiceItemList>
        }
      </Body>
    </Wrapper>
  )
};

export default ClientServiceList;
