import React, {useEffect} from 'react';
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

//components
import TargetBlock from "@/containers/Targets/TargetBlock";
import DashboardItemList from "@/containers/Dashboard/DashboardItemList";
import {STORE_KEYS} from '@/stores';
import StatusPanel from "../StatusPanel";
import ClientsTargets from '@/containers/ClientsTargets';

//styles
import {
  Container, SubHeader, Title, Content, Center, Right, SubTitle,
} from "../styles";

const Dashboard = ({
  cloudmebTargets, requestCloudmebTargets, isXeroLogged, markTaskAsComplete, activeTargetsLength, requestCloudTasks,
}) => {
  const [detailViewId, setDetailViewId] = React.useState('');

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    requestCloudTasks(),
    requestCloudmebTargets();
  }, {});

  return (
    <>
      <Container>
        <SubHeader>
          <Title>Dashboard</Title>
        </SubHeader>
        <Content>
          <Center>
            <div className={'wrapper'}>
              <DashboardItemList isXeroLogged={isXeroLogged}/>
              <ClientsTargets/>
              <SubTitle pl20 className="pt-4">Cloudmeb Targets <span>{Number(activeTargetsLength) || 0}</span></SubTitle>
              {
                cloudmebTargets.map((item, index) => {
                  const date1 = new Date();
                  const date2 = new Date(item.date);
                  const Difference_In_Time = date2.getTime() - date1.getTime();
                  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                  if (!item.completed && Difference_In_Days < 45) {
                    return (
                      <TargetBlock
                        target={item}
                        showAll={detailViewId !== ''}
                        setDetailViewId={setDetailViewId}
                        key={index}
                        markTaskAsComplete={markTaskAsComplete}
                      />
                    )
                  }
                })
              }
            </div>
          </Center>
          <Right>
            <StatusPanel/>
          </Right>
        </Content>
      </Container>
    </>
  )
}

export default compose(
  inject(STORE_KEYS.TARGETSSTORE, STORE_KEYS.XEROSTORE, STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.TARGETSSTORE]: {
         cloudmebTargets,
         requestCloudmebTargets,
         markTaskAsComplete,
         activeTargetsLength,
         cloudmebTargetsByClients,
       },
       [STORE_KEYS.XEROSTORE]: {
         isXeroLogged,
       },
       [STORE_KEYS.CHECKLISTSTORE]: {
         requestCloudTasks,
       }
     }) => ({
      cloudmebTargets,
      requestCloudmebTargets,
      markTaskAsComplete,
      activeTargetsLength,
      isXeroLogged,
      cloudmebTargetsByClients,
      requestCloudTasks,
    })
  )
)(Dashboard);
