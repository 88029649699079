import React from "react";
import styled from "styled-components";
import TargetItem from "@/components/dashboard/targets/TargetItem";

const TaskList = ({data: tasksData, isMyTask, markTaskAsComplete, isClientTasks = false}) => {
  const data = tasksData || {};
  const [isShowList, setIsShowList] = React.useState(true);

  React.useEffect(() => {
    setIsShowList(data.state !== 'COMPLETED');
  }, []);

  const ListWrapper = styled.div`
    border-radius: 5px;
    background: white;
  `;

  const SubListTitle = styled.div`
    color: #73767b;
    font-family: Circe-Bold;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: ${props => props.mt || 0}px;
    
    span {
      color: #afb9c8;
      margin-left: 5px;
    }
    
    i {
      cursor: pointer;
    }
  `;

  return (
    <>
      <SubListTitle mt={17} className="upper-case">
        {data.state}
        <span>{(data.tasks || []).length}</span>
        {
          data.state === 'COMPLETED' && isShowList &&
          <i
            className="fa fa-angle-up ml-1"
            onClick={() => setIsShowList(!isShowList)}
          />
        }
        {
          data.state === 'COMPLETED' && !isShowList &&
          <i
            className="fa fa-angle-down ml-1"
            onClick={() => setIsShowList(!isShowList)}
          />
        }
      </SubListTitle>
      <ListWrapper>
        {
          isShowList && (data.tasks || []).map(task => (
            <TargetItem
              data={task}
              state={data.state}
              isMyTask={isMyTask}
              key={task.id}
              markTaskAsComplete={markTaskAsComplete}
              isClientTasks={isClientTasks}
            />
          ))
        }
      </ListWrapper>
    </>
  )
};

export default TaskList;
