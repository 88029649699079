import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
  
  .btnWrapper {
    display: flex;
  }
  
  .btn_add_target {
    width: 92px;
    height: 26px;
    opacity: 0.7;
    border-radius: 4px;
    background-color: #7632d2;
    font-family: Circe-Bold;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1px;
    text-align: center;
    color: #ffffff;
    padding-top: 4px;
    margin-left: 20px;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.xxl}) {
    width: calc(100vw - 370px);
    overflow-y: auto;
    
    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border-radius: 2.5px;
      height: 5px;
    }
    
    &::-webkit-scrollbar {
      height: 5px;
    }
    
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;
