import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import MyTeamItem from "@/containers/Targets/MyTeamItem";
import QuickLink from "@/containers/Dashboard/QuickLink";
import { CommonLink } from "@/components/common/button";
import CircleProgressBar from "@/components/common/circle-progressbar";
import Tooltip from "@/components/dashboard/tasks/Tooltip";

import { ProgressPanel, ProgressView, ScoreView, SubTitle } from "./styles";

class StatusPanel extends Component {
  render() {
    const {
      targetOverallProgress,
      taskActivityScore,
      googleDriveLink,

      onboarding_avatar,
      onboarding_Company,
      onboarding_email,
      onboarding_calendly,

      education_avatar,
      education_Company,
      education_Email,
      education_Calendly,

      accounting_avatar,
      accountant_Name,
      accountant_email,
      accounting_calendly,

      billing_avatar,
      billing_Name,
      billing_email,
      billing_calendly,
    } = this.props;

    const my_team = [
      {
        name: onboarding_Company,
        img: onboarding_avatar,
        email: onboarding_email,
        calendly: onboarding_calendly,
        default_img: '/images/Solutions/Service_Accounting.svg',
        property: 'ONBOARDING'
      },
      {
        name: education_Company,
        img: education_avatar,
        email: education_Email,
        calendly: education_Calendly,
        default_img: '/images/Solutions/Service_Accounting.svg',
        property: 'EDUCATION'
      },
      {
        name: accountant_Name,
        img: accounting_avatar,
        email: accountant_email,
        calendly: accounting_calendly,
        default_img: '/images/Solutions/Service_Accounting.svg',
        property: 'SUPPORT'
      },
      {
        name: billing_Name,
        img: billing_avatar,
        email: billing_email,
        calendly: billing_calendly,
        default_img: '/images/Solutions/Service_Legal.svg',
        property: 'BILLING'
      },
    ];

    const quick_links = [
      {
        name: "Xero",
        img: "/images/Solutions/Product_Xero.svg",
        url: "https://login.xero.com/",
        date: "",
      },
      {
        name: "Dext",
        img: "/images/Solutions/Product_ReceiptBank.svg",
        url: "https://app.receipt-bank.com/login",
        date: "",
      },
      {
        name: "Fathom",
        img: "/images/Solutions/Product_Fathom.svg",
        url: "https://app.fathomhq.com/login",
        date: "",
      },
      {
        name: "ApprovalMax",
        img: "/images/Solutions/Product_Approval.svg",
        url: "https://app.approvalmax.com/login",
        date: "",
      }
    ]

    return (
      <div className={'wrapper'}>
        <ProgressPanel>
          <ProgressView>
            <span className="upper-case">OVERALL PROGRESS</span>
            <CircleProgressBar
              value={targetOverallProgress}
              size={80}
              colors={['#8d9bb0', '#fff']}
              cutout={93}
              labelSize={24}
            />
          </ProgressView>
          <ScoreView>
            <div className="title">
              <span className="upper-case">ACTIVITY SCORE</span>
              <Tooltip/>
            </div>
            <CircleProgressBar
              value={taskActivityScore}
              size={80}
              colors={['#8d9bb0', '#fff']}
              cutout={93}
              labelSize={24}
            />
          </ScoreView>
        </ProgressPanel>
        <div className="mt-4 mb-4">
          <SubTitle>My Team</SubTitle>
          {
            my_team.map((item, index) => (
              <MyTeamItem data={item} key={index} />
            ))
          }
        </div>
        <div className="mt-4 mb-4">
          <SubTitle>Shared Drive</SubTitle>
          <CommonLink fullWidth href={googleDriveLink} target="_blank">
            <img src={'/images/dashboard/storage_icon.png'} alt="Storage Icon" className="mr-2"/>
            Open Storage
          </CommonLink>
        </div>
        <div className="mt-4 mb-4">
          <SubTitle>Quick Links</SubTitle>
          <QuickLink data={quick_links}/>
        </div>
      </div>
    );
  }
};


export default compose(
  inject(STORE_KEYS.MYSOLUTIONSSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.MYSOLUTIONSSTORE]: {
         targetOverallProgress,
         taskActivityScore,
         googleDriveLink,
         onboarding_avatar,
         onboarding_Company,
         onboarding_email,
         onboarding_calendly,
         education_avatar,
         education_Company,
         education_Email,
         education_Calendly,
         accounting_avatar,
         accountant_Name,
         accountant_email,
         accounting_calendly,
         billing_avatar,
         billing_Name,
         billing_email,
         billing_calendly,
       },
     }) => ({
      targetOverallProgress,
      taskActivityScore,
      googleDriveLink,
      onboarding_avatar,
      onboarding_Company,
      onboarding_email,
      onboarding_calendly,
      education_avatar,
      education_Company,
      education_Email,
      education_Calendly,
      accounting_avatar,
      accountant_Name,
      accountant_email,
      accounting_calendly,
      billing_avatar,
      billing_Name,
      billing_email,
      billing_calendly,
    })
  )
)(StatusPanel);
