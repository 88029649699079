import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: white;
`;

export const SubHeader = styled.div`
  min-height: 75px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: center;
    padding: 15px 0;
  }
`;

export const Title = styled.div`
  padding-left: 30px;
  font-size: 24px;
  font-family: Circe-Bold;
  color: #171d26;
  width: 20%;
  min-width: 150px;
  white-space: nowrap;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    padding-left: 20px;
  }
`;


export const Tabs = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    justify-content: center;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    justify-content: flex-start;
  }
`;

export const TabItem = styled.div`
  font-size: 20px;
  font-family: Circe-Bold;
  color: #171d26;
  margin-right: 40px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  
  color: ${props => props.active ? '#171d26' : '#647a9d'};
  border-bottom: ${props => props.active ? '3px solid #181e26' : '3px solid #fff'};
  
  &:last-child {
    margin-right: 0;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-right: 20px;
  }
  
  span {
    font-size: 14px;
    color: #9da9bb;
    padding-left: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Left = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  min-width: 250px;
  overflow: auto;
  height: calc(100vh - 176px);
  margin-right: 4px;
  box-sizing: border-box;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  .wrapper {
     padding: 1.5rem 2rem;
    
     @media (max-width: ${props => props.theme.breakpoints.md}) {
      padding: 0;
    }
  }
  
  @media (max-width: 1300px) {
    height: calc(100vh - 166px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    height: calc(100vh - 186px);
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: unset;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
`;

export const Center = styled.div`
  flex: 1;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    border: none;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
  
  .wrapper {
    padding: 0 2rem 20px;
    overflow: auto;
    height: calc(100vh - 176px);
    margin-right: 4px;
    
    @media (max-width: 1300px) {
      height: calc(100vh - 166px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      height: calc(100vh - 186px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      height: unset;
      width: 100%;
      border: none;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: ${props => props.smPadding ? '0 20px 20px 20px' : '0 0 10px 0' };
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border-radius: 2.5px;
      width: 5px;
    }
    
    &::-webkit-scrollbar {
      width: 5px;
      padding: 5px;
    }
    
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
`;

export const Right = styled.div`
  flex: 0 0 20%;
  max-width: 20%;
  min-width: 300px;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    max-width: 100%;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    max-width: 100%;
  }
  
  .wrapper {
    padding: 20px
    overflow: auto;
    height: calc(100vh - 176px);
    margin-right: 4px;
    
    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border-radius: 2.5px;
      width: 5px;
    }
    
    &::-webkit-scrollbar {
      width: 5px;
    }
    
    &::-webkit-scrollbar-track {
      display: none;
    }
    
    @media (max-width: 1300px) {
      height: calc(100vh - 166px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      height: calc(100vh - 186px);
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      height: unset;
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 40px 16px 40px 20px;
    }
  }
`;

export const SubPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .title {
    height: 70px;
    font-family: Circe-Bold;
    font-size: 20px;
    color: #75787d;
    display: flex;
    align-items: center;
    
    span {
      margin-left: 10px;
      margin-top: 4px;
      font-size: 15px;
    }
  }
  
  .content {
    display: flex;
    width: 100%;
  }
  
  .logo_item {
    width: 28px;
  }
`;

export const SubTitle = styled.h2`
  margin: 0 0 13px;
  font-family: Circe-Bold;
  font-size: 20px;
  width: 100%;
  color: #75787d;
  
  span {
    font-size: 14px;
    color: #8d9bb0;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-left: ${props => props.pl20 && '20px'};
  }
`;


