import React from "react";
import styled from "styled-components";

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SignUp = styled.div`
  flex: 1;
  display: flex;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const SignUpForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  background-color: #fff;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const FormContent = styled.div`
  max-width: 480px;
  width: 100%;
  font-size: 30px;
  text-align: center;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;


const ConfirmPage = () => {
    return (
        <Wrapper>
            <SignUp>
                <Brand>
                    <img src="/images/login/img_msg_sent.png" alt=""/>
                </Brand>
                <SignUpForm>
                    <FormContent>
                        Thank you for your registration.
                        Please verify your email.
                    </FormContent>
                </SignUpForm>
            </SignUp>
        </Wrapper>
    )
}

export default ConfirmPage;
