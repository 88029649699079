import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const FooterWrapper = styled.div`
  height: 50px;
  background: #fbfcfd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: Circe-Regular;
  
  p {
    margin-top: 5px;
    color: #74777d;
  }
  
  a {
    color: #5f7699;
  }
`;

export default () => {
  return (
    <FooterWrapper>
      <p>2020 @ Cloudmeb</p>
      <Link to={'/support'}>Support</Link>
    </FooterWrapper>
  )
}


