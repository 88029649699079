import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
`;

export const SubHeader = styled.div`
  padding: 19px 0 19px 30px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  flex-wrap: wrap;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: Circe-Bold;
  color: #171d26;
  width: 20%;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

export const Tabs = styled.div`
  flex: 1;
  display: flex;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-self: flex-start;
  }
`;

export const TabItem = styled(Link)`
  font-size: 20px;
  font-family: Circe-Bold;
  color: #171d26;
  margin-left: 40px;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  text-decoration: none;
  
  color: ${props => props.active === 'active' ? '#171d26' : '#647a9d'};
  border-bottom: ${props => props.active === 'active' ? '3px solid #181e26' : '3px solid #fff'};
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    border-bottom: none;
    padding-left: 10px;
    margin-left: 0;
    border-left: ${props => props.active === 'active' ? '3px solid #181e26' : 'none'};
  }
  
  span {
    font-size: 14px;
    color: #9da9bb;
    padding-left: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin: 0 auto;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const Center = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: auto;
  
  &::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 2.5px;
    width: 5px;
  }
  
  &::-webkit-scrollbar {
    width: 5px;
    padding: 5px;
  }
  
  &::-webkit-scrollbar-track {
    display: none;
  }
  
  .wrapper {
    height: calc(100vh - 126px - 50px);
    padding: 1.5rem 2rem;
    width: 65%;
    
    @media (max-width: 1300px) {
      height: calc(100vh - 116px - 50px);
      width: 75%;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      height: unset;
      width: 85%;
      overflow: initial;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      width: 100%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    border: none;
    width: 100%;
  }
`;

export const SubPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .title {
    font-family: Circe-Bold;
    font-size: 20px;
    color: #75787d;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    span {
      margin-left: 10px;
      margin-top: 4px;
      font-size: 15px;
    }
  }
  
  .content {
    width: 100%;
  }
`;

export const Button = styled.button`
  min-width: 85px;
  border-radius: 5px;
  background: #415c85;
  color: #e7eaef;
  outline: none;
  cursor: pointer;
  font-family: Circe-Bold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  border: none;
  padding: 8px 20px;
  
  :hover {
    background: #2f466a;
  }
`;

export const WhiteButton = styled(Button)`
  background: #f5f7f9;
  color: #6e83a3;
  
  :hover {
    background: #e3e7eb;
  }
`;

export const FooterWrapper = styled.div`
  height: 50px;
  background: #fbfcfd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: Circe-Regular;
  
  p {
    margin-top: 5px;
    color: #74777d;
  }
  
  a {
    color: #5f7699;
  }
`;

export const SubTitle = styled.h2`
  margin-bottom: 20px;
  font-family: Circe-Bold;
  font-size: 20px;
  color: #75787d;
  width: 100%;
  
  span {
    font-size: 14px;
    color: #9ea0a4;
  }
  
  ${props => props.center && css`
    margin-left: 40px;
  `}
`

export const EditBtn = styled.div`
  padding: 6px 10px;
  border: 1px solid #e7e8e9;
  border-radius: 3px;
  color: #7a7d82;
  font-family: Circe-Regular;
  font-size: 10px;
  margin-left: 20px;
  cursor: pointer;
`;

export const BillingInfoItem = styled.div`
  width: 100%;
  font-family: Circe-Regular;
  
  .label {
    font-size: 12px;
    color: #84878b;
  }
  
  .value {
    font-size: 20px;
    color: #181e26;
  }
`;
