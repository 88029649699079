import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import Header from '../header';
import PictureUpload from '../../../components/common/PictureUpload';
import {
  Container, Content, SubPanel, Center, EditBtn, BillingInfoItem
} from "../styles";
import {
  TextField, SaveBtn, CancelBtn, GeneralInfo, BioText
} from "./styles";

const Profile = (props) => {

  const [isEditing, setIsEditing] = React.useState(false);

  const {
    email,
    name,
    Partner_Image__c,
    Calendly_Link__c,
    Partner_linked_In__c,
    Partner_Bio__c,
    updatePartnerImage,
    updateCalendlyLink,
    updatePartnerLinkedIn,
    updatePartnerBio,
    updateName,
    updatePersonalProfile,
  } = props;

  const onEditBtnClick = () => {
    setIsEditing(true);
  };

  const onSaveBtnClick = () => {
    setIsEditing(false);
    updatePersonalProfile();
  };

  const onCancelBtnClick = () => {
    setIsEditing(false);
  };

  return (
    <Container>
      <Header item="profile"/>
      <Content>
        <Center>
          <div className="wrapper">
            <SubPanel>
              <div className="title">
                General
                {
                  !isEditing &&
                  <EditBtn onClick={onEditBtnClick}>EDIT</EditBtn>
                }
              </div>
              <div className="content mb-5">
                <GeneralInfo>
                  <div className={'profile-image'}>
                    <PictureUpload picture={Partner_Image__c} setPicture={updatePartnerImage} isDisabled={!isEditing} />
                  </div>
                  {
                    !isEditing ?
                      <>
                        <div className={'profile-info'}>
                          <div className="row">
                            <div className="col-xl-3 col-md-6 col-sm-12 mb-4">
                              <BillingInfoItem>
                                <label className="label">Name</label>
                                <p className="value">{ name || '_'}</p>
                              </BillingInfoItem>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-12 mb-4">
                              <BillingInfoItem>
                                <label className="label">Calendly</label>
                                {
                                  Calendly_Link__c ?
                                    <p className="value"><img src={"/images/icons/calendly_icon.png"} className="mr-1" alt={'icon'} />{Calendly_Link__c}</p>
                                    :
                                    <p className="value">_</p>
                                }
                              </BillingInfoItem>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-3 col-md-6 col-sm-12 mb-4">
                              <BillingInfoItem>
                                <label className="label">Email</label>
                                <p className="value">{ email || '_'}</p>
                              </BillingInfoItem>
                            </div>
                            <div className="col-xl-3 col-md-6 col-sm-12">
                              <BillingInfoItem>
                                <label className="label">Linkedin</label>
                                {
                                  Partner_linked_In__c ?
                                    <p className="value"><img src={"/images/icons/linkedin_icon.png"} className="mr-1" alt={'icon'} />{Partner_linked_In__c}</p>
                                    :
                                    <p className="value">_</p>
                                }
                              </BillingInfoItem>
                            </div>
                          </div>
                        </div>
                      </>
                      :
                      <div className={'profile-info'}>
                        <div className="row">
                          <div className="col-md-4 sm-12 mb-4">
                            <TextField
                              id="name"
                              label="Name"
                              variant="outlined"
                              value={name}
                              onChange={(e) => updateName(e.target.value || '')}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="col-md-4 sm-12 mb-4">
                            <TextField
                              id="calendly"
                              label="Calendly"
                              variant="outlined"
                              value={Calendly_Link__c}
                              onChange={(e) => updateCalendlyLink(e.target.value)}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-12 mb-4">
                            <TextField
                              id="email"
                              label="Email"
                              variant="outlined"
                              value={email}
                              disabled={true}
                              // onChange={(e) => setEmail(e.target.value)}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <TextField
                              id="linkedin"
                              label="Linkedin"
                              variant="outlined"
                              value={Partner_linked_In__c}
                              onChange={(e) => updatePartnerLinkedIn(e.target.value)}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          </div>
                        </div>
                      </div>
                  }
                </GeneralInfo>
              </div>
            </SubPanel>
            <SubPanel>
              <div className="title">Bio</div>
              <div className="content mb-5">
                {!isEditing ? (
                  <BioText>
                    {Partner_Bio__c || ''}
                  </BioText>
                ) : (
                  <TextField
                    id="name"
                    label="Bio"
                    variant="outlined"
                    value={Partner_Bio__c}
                    multiline
                    rows={4}
                    onChange={(e) => updatePartnerBio(e.target.value || '')}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              </div>
            </SubPanel>
            <SubPanel>
              <div className="title">Password</div>
              <div className="content mb-5">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <TextField
                      id="current_password"
                      label="Current Password"
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-4  col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="new_password"
                      label="New Password"
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-4  col-sm-6 mb-4 mb-lg-0">
                    <TextField
                      id="confirm_password"
                      label="Confirm Password"
                      variant="outlined"
                      type="password"
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                </div>
              </div>
            </SubPanel>
            <div className="d-flex">
              <CancelBtn
                onClick={onCancelBtnClick}
              >
                Cancel
              </CancelBtn>
              {
                !isEditing ?
                  <SaveBtn
                    disabled={true}
                  >
                    Save
                  </SaveBtn>
                  :
                  <SaveBtn
                    onClick={onSaveBtnClick}
                  >
                    Save
                  </SaveBtn>
              }

            </div>

          </div>
        </Center>
      </Content>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.AUTHSTORE, STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: {
         email,
       },
       [STORE_KEYS.SALESFORCESTORE]: {
         name,
         Partner_Image__c,
         Calendly_Link__c,
         Partner_linked_In__c,
         Partner_Bio__c,
         updatePartnerImage,
         updateCalendlyLink,
         updatePartnerLinkedIn,
         updatePartnerBio,
         updateName,
         updatePersonalProfile,
       },
     }) => ({
      email,
      name,
      Partner_Image__c,
      Calendly_Link__c,
      Partner_linked_In__c,
      Partner_Bio__c,
      updatePartnerImage,
      updateCalendlyLink,
      updatePartnerLinkedIn,
      updatePartnerBio,
      updateName,
      updatePersonalProfile,
    })
  )
)(Profile);
