import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import VideoPanel from '../VideoPanel';
import {
  Container, Wrapper, Left, Title, Name, VideoPanelWrapper, Message, Right, Header, Description,
  Info, Price, Warning, CardInfoText, ButtonGroup, CancelButton, PayButton, CloseButton, AddButton
} from './styles';

const AddServiceDialog = (props) => {
  const { item, last4, createSubscriptionService } = props; // product data
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isLock, setIsLock] = React.useState(false);
  const paymentIssue = last4 === '';

  const data =
    {
      id: 1,
      icon: item ? item.Service_Logo_url__c : '',
      name: item ? item.Name : '',
      type: '',
      desc: item ? item.Description__c : '',
      price: item ? item.Cloudmeb_Price__c : '',
      currency: item ? item.CurrencyIsoCode : '',
      frequency: item ? item.Billing_Frequency__c : '',
      video: item ? item.Video_Link__c : '',
    };

  const handleClickPayBtn = () => {
    if (!isLock) {
      setIsLock(true);
      createSubscriptionService(item.id)
        .then(() => {
          setIsLock(false);
          setIsSuccess(true);
        })
        .catch((err) => {
          setIsLock(false);
          console.log('err:', err);
        });
    }
  };

  const handleClosePopup = () => {
    setIsSuccess(false);
    props.handleClose();
  };

  return (
    <Container isShow={props.open}>
      <Wrapper isSuccessPanel={isSuccess}>
        {
          !isSuccess &&
            <Left>
              <VideoPanelWrapper>
                {data.video ? (
                  <VideoPanel link={data.video}/>
                ) : (
                  <h4>No Video</h4>
                )}
              </VideoPanelWrapper>
            </Left>
        }
        <Right isSuccess={isSuccess}>
          <Header>
            <Title>SUBSCRIBE SERVICE</Title>
            <Name>
              <img src={data.icon} alt="Service Image" />
              <div className={'name'}>{data.name} <span className={'type'}>{data.type}</span></div>
            </Name>
          </Header>
          <Description success={isSuccess}>
            {
              !isSuccess ?
                <p>{data.desc}</p>
              :
                <>
                  <Info>
                    <p className={'bold'}>Thank you for your payment</p>
                    <p>Your login credentials will be emailed to you shortly.</p>
                    <p>{`Manager your subscription under\n`} <a href={"/my-solutions"}>{`\nMy Solutions`}</a></p>
                  </Info>
                </>
            }

          </Description>
          {
            !isSuccess ?
              <div>
                <Price>${data.price} <span className="unit">{data.currency}</span> <span>/{data.frequency}</span></Price>
                {
                  paymentIssue &&
                  <Warning><p>You have to add payment method to complete purchase.</p></Warning>
                }
                <ButtonGroup>
                  <CancelButton onClick={props.handleClose}>Cancel</CancelButton>
                  {
                    paymentIssue ? (
                      <AddButton to={'/billing'}>Add Method</AddButton>
                    ) : (
                      <PayButton onClick={() => handleClickPayBtn()} disabled={isLock}>Pay Now</PayButton>
                    )
                  }
                </ButtonGroup>
              </div>
              : <CloseButton onClick={() => handleClosePopup()}>Close</CloseButton>
          }
        </Right>
      </Wrapper>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         last4,
         createSubscriptionService,
       },
     }) => ({
      last4,
      createSubscriptionService,
    })
  )
)(AddServiceDialog);
