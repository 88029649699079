import React from "react";

//components
import TemplateCard from "../../../components/templates/template-card";
import AddTemplateDialog from "../../../components/dialogs/AddTemplateDialog";

//styles
import { Content, Left, Center, SubPanel } from "../../styles";
import { LeftCard } from "../styles";

const Templates = (props) => {

  const { data: xeroTemplates } = props;

  const [openModal, setOpenModal] = React.useState(false);

  const [activeItem, setActiveItem] = React.useState(null);

  const toggleOpenModal = (isOpen, item) => {
    setOpenModal(isOpen);
    setActiveItem(item);
  };

  return (
    <Content>
      <Center smPadding>
        <div className={'wrapper'}>
          <AddTemplateDialog
              open={openModal}
              item={activeItem}
              handleClose={() => setOpenModal(false)}
          />
          <SubPanel>
            <div className={'title'}>All Templates <span>{xeroTemplates.length}</span></div>
            <div className={'row'}>
              {
                xeroTemplates.map((item, index) => (
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 mb-4" key={index}>
                    <TemplateCard data={item} toggleOpenModal={toggleOpenModal} />
                  </div>
                ))
              }
            </div>
          </SubPanel>
        </div>
      </Center>
      <Left>
        <div className={'wrapper'}>
          <LeftCard>
            <div className={'brand'}>
              <img src="/images/dashboard/solutions/img_location.png" alt=""/>
            </div>
            <div className={'description'}>
              <p>Customize your Xero quickly and easily with our templates.</p>
            </div>
          </LeftCard>
        </div>
      </Left>
    </Content>
  )
};

export default Templates;
