import styled, {css} from "styled-components";
import {TextField as MuiTextField} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import {Button, WhiteButton} from "../styles";

export const TextFieldSpacing = styled(MuiTextField)(spacing);

export const TextField = styled(TextFieldSpacing)`
  width: 100%;
  font-family: Circe-Regular !important;
  
  .MuiInputBase-input {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 0px;
    
  }
  
  .MuiInputLabel-outlined {
    font-family: Circe-Regular;
    color: #576f93;
    font-size: 16px;
  }
  
  .MuiFormHelperText-root {
    font-family: Circe-Regular;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #576f93;
  }
  
  .PrivateNotchedOutline-legendLabelled-3 {
    font-size: 14px;
  }
`;

export const SaveBtn = styled(Button)`
  padding: 16px 30px 14px;
  margin-left: 30px;
  
  ${props => props.disabled === true && css`
    background: #f9fafb;
    color: #d4dbe3;
    
    &:hover {
      background: #f9fafb;
    }
  `}
`;

export const CancelBtn = styled(WhiteButton)`
  padding: 16px 30px 14px;
`;

export const GeneralInfo = styled.div`
  display: flex;
  min-height: 140px;
  
  .profile-image {
    margin-right: 50px;
  }
  
  .profile-info {
    width: 100%;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    flex-direction: column;
    align-items: center;
    
    .profile-image {
      margin-right: 0;
      margin-bottom: 30px;
    }
    
    .profile-info {
      width: 100%;
    }
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .profile-info {
      width: 100%;
    }
  }
`;

export const BioText = styled.p`
  font-size: 18px;
  color: #181e26;
  max-width: 680px;
`;
