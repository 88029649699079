import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 20px 20px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f5f7f9;
  
  &:first-child {
    border-top: none;
  }
  
  h3 {
    color: #405c85;
    font-size: 16px;
  }
  
  p {
    color: #97a4b7;
    font-size: 12px;
    
  }
  
  ${props => props.inActive && css`
  
    h3 {
      color: #8d9eb6;
      font-size: 16px;
    }
    
    p {
      color: #adb9cb;
      font-size: 12px;
    }
  `}
  
  .name {
    display: flex;
    align-items: center;
    min-width: 150px;
    width: 22%;
    
    img {
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin-right: 10px;
    }
  }
  
  .subscribed {
    width: 20%;
  }
  
  .status {
    width: 15%;
  }
  
  .price {
    width: 20%;
    
    .price-num {
      p {
        display: inline-block;
      }
      span {
        color: #c3cad6;
      }
    }
    
  }
  
  .action {
    width: 15%;
    min-width: 100px;
    height: 35px;
    display: flex;
    justify-content: flex-end;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    
    .name {
      padding-bottom : 15px;
      justify-content: space-between;
      
      h3 {
        font-size: 20px;
      }
    }
    
    > div {
      width: 100% !important;
    }
  }
`;

export const MobileFooter = styled.div`
  display: none;
  justify-content: space-around;
  border-top: 1px solid #f5f7f9;
  padding-top: 15px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
  }
  
  > div {
    width: unset !important;
  }
`;

