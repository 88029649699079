import React, {useState} from "react";
import styled from "styled-components";
import { ClickAwayListener } from "@material-ui/core";

const Menu = (props) => {
  const [ isShowMenu, setIsShowMenu ] = useState(false);
  const {
    type, id, onClickEdit, onClickDelete,
  } = props;

  const handleClick = (e) => {
    setIsShowMenu(!isShowMenu);
    e.stopPropagation();
  };

  return (
    <Container>
      <DownIcon
        color={type === 'EXPIRED' ? 'white' : ''}
        onClick={(e) => handleClick(e)}
      >
        {
          isShowMenu ?
            <i className="fa fa-angle-up ml-1" />
            :
            <i className="fa fa-angle-down ml-1" />
        }
      </DownIcon>
      {
        isShowMenu &&
        <ClickAwayListener onClickAway={() => setIsShowMenu(false)}>
          <MenuWrapper>
            <SubMenu>
              <Item
                onClick={() => {
                  onClickEdit(id);
                  setIsShowMenu(false);
                }}
              >
                Edit
              </Item>
              <Item
                onClick={() => {
                  onClickDelete(id);
                  setIsShowMenu(false);
                }}
                color={'#d37466'}
              >
                Delete
              </Item>
            </SubMenu>
          </MenuWrapper>
        </ClickAwayListener>
      }
    </Container>
  )
};

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const DownIcon = styled.span`
  margin-left: 5px;
  
  i {
    font-size: 14px !important;
    color: ${props => props.color || '#aab4c4'};
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
  top: 25px;
  padding: 5px 0;
  right: -10px;
  z-index: 100;
`;

const Item = styled.span`
  color: ${props => props.color || '#4b648b'};
  text-decoration: none;
  font-family: Circe-Regular;
  font-size: 14px;
  padding: 7px 15px;
  position: relative;
  
  &:hover {
    background-color: #f7fdfd;
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
`;


export default Menu;
