import React, { useState, useEffect } from "react";
import moment from "moment";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import {
  Container,
  Wrapper,
  Header,
  Name,
  Body,
  Footer,
  Title,
  TextField,
  InputForm,
  SaveButton,
  CloseButton,
  CancelButton,
  DeleteButton,
  DatePicker
} from "./styles";
import { STORE_KEYS } from '@/stores';
import {SvgCalendarIcon, SvgCloseIcon} from "../../SvgIcon";

const AddClientTaskDlg = (props) => {

  const [taskName, setTaskName] = useState('');
  const [dueDate, setDueDate] = useState(moment());
  const [link, setLink] = useState('');
  const {
    dlgMode, createCMBTask, open, handleClose, clientId, targetId, requestCloudTasks, targetCategory,
  } = props;

  useEffect(() => {
    setTaskName('');
    setDueDate(moment());
    setLink('');
  }, []);

  const onSaveBtnClick = () => {
    createCMBTask(clientId, taskName, dueDate, link, dlgMode === "right", targetId)
      .then(() => {
        requestCloudTasks();
        handleClose();
      });
  };

  return (
    <Container isShow={open}>
      <Wrapper>
        <Header>
          <Title>{`ADD ${dlgMode === 'left' ? 'PARTNER' : 'CLIENT'} TASK`}</Title>
          <Name>
            <img src="/images/dashboard/people1.png" alt="img"/>
            <h3>{targetCategory || ''}</h3>
          </Name>
          <CloseButton onClick={handleClose}><SvgCloseIcon/></CloseButton>
        </Header>
        <Body>
          <InputForm>
            <TextField
              id="taskName"
              label="Task Name"
              variant="outlined"
              value={taskName}
              autoFocus
              onChange={(e) => setTaskName(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <div className="custom-datepicker">
              <DatePicker
                label="Due Date"
                value={dueDate}
                format={'d MMMM yyyy'}
                onChange={(date) => setDueDate(date)}
                InputLabelProps={{
                  shrink: true
                }}
                inputVariant="outlined"
              />
              <SvgCalendarIcon/>
            </div>
            <TextField
              id="link"
              label="Link (optional)"
              variant="outlined"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </InputForm>
        </Body>
        <Footer>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <SaveButton
            className="ml-3"
            onClick={() => onSaveBtnClick()}
          >Add Task</SaveButton>
        </Footer>
      </Wrapper>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE, STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         createCMBTask,
       },
       [STORE_KEYS.CHECKLISTSTORE]: {
         requestCloudTasks,
       },
     }) => ({
      createCMBTask,
      requestCloudTasks,
    })
  )
)(AddClientTaskDlg);
