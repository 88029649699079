import styled from "styled-components";

export const Container = styled.div`
  display: ${props => props.isShow ? 'flex' : 'none'};
  position: fixed;
  z-index: 1100;
  padding: 20px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0, 0.5);
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 290px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;
  position: relative;
  
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
`;

export const Title = styled.h3`
  font-size: 14px;
  color: #181e26;
`;

export const Header = styled.div`
  padding: 15px 0 20px;
  
  img {
    width: 27px;
    min-width: 27px;
    height: 27px;
    margin-right: 10px;
  }
  
  h3 {
    font-size: 20px;
    line-height: 20px;
    color: #405c85;
  }
  
  p {
    font-size: 14px;
    white-space: pre-line;
  }
  
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  
  border-top: 1px solid #ebeef2;
  border-bottom: 1px solid #ebeef2;
  padding: 18px 20px;
  
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  span {
    font-size: 11px;
    color: #9eaabc;
    letter-spacing: 1px;
  }
  
  p {
    margin-top: 10px;
    font-size: 18px;
    font-family: Circe-Bold;
    color: #181e26;
    display: flex;
    align-items: center;
  }
  
  .est-time {
    span {
      margin-left: 5px;
    }
  }
  
  .pt-view {
    color: #d78c2c;
  }
  
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

export const CancelButton = styled.button`
  font-family: Circe-Bold;
  font-size: 14px;
  color: #5e7599;
  background: #f5f7f9;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  &:hover {
    background: #e7eaec;
  }
  
`;

export const PayButton = styled.button`
  font-family: Circe-Bold;
  font-size: 14px;
  color: #fff;
  background: #415c85;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  &:hover {
    background: #3b5378;
  }
`;

