import React from "react";
import { TableBody, TableCell, TableHead } from "@material-ui/core";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import BillingPopup from './BillingPopup';
import PaymentCard from "../../../components/dashboard/Settings/PaymentCard";
import Header from '../header';

//styles
import {
  Container, Content, SubPanel, Center, EditBtn, BillingInfoItem
} from "../styles";
import {
  TextFieldSpacing, TextField, SaveBtn, CancelBtn, PaymentList,
  AddPaymentBtn, InvoiceTable, CustomTableHeader, CustomTableRow, PlusIcon
} from "./styles";

const Financial = (props) => {
  const {
    businessName,
    email,
    billingStreet,
    billingCity,
    billingState,
    billingPostalCode,
    billingCountry,
    updateBusinessName,
    updateBillingStreet,
    updateBillingCity,
    updateBillingState,
    updateBillingPostalCode,
    updateBillingCountry,
    updateProfile,
    brand,
    last4,
    exp_month,
    exp_year,
  } = props;

  const isPaymentConnected = last4 !== '';
  const payments = isPaymentConnected ? [
    {
      'id': 1,
      'type': (brand || '').toUpperCase(),
      'address': last4,
      'date': (exp_month && exp_year) ? `${exp_month}/${exp_year}` : ''
    }
  ] : [];
  const [isEditing, setIsEditing] = React.useState(false);
  const [primaryPaymentId, setPrimaryPaymentId] = React.useState(payments[0] ? payments[0].id : '');
  const [openModal, setOpenModal] = React.useState(false);

  const onSaveBtnClick = () => {
    setIsEditing(false);
    updateProfile();
  };

  const onCancelBtnClick = () => {
    setIsEditing(false);
  };

  const onEditBtnClick = () => {
    setIsEditing(true);
  };

  const onDeletePayment = (id) => {
  };

  let id = 0;
  const createData = (name, date, amount, download) => {
    id += 1;
    return { id, name, date, amount, download };
  };

  const rows = [
    // createData("350667", '1 May 2020', 999),
    // createData("350667", '1 May 2020', 999),
    // createData("350667", '1 May 2020', 999),
    // createData("350667", '1 May 2020', 999),
    // createData("350667", '1 May 2020', 999),
    // createData("350667", '1 May 2020', 999),
    // createData("350667", '1 May 2020', 999),
  ];

  return (
    <Container>
      <Header item="billing"/>
      <Content>
        <Center>
          <div className="wrapper">
            <BillingPopup open={openModal} setOpen={setOpenModal} isPaymentConnected={isPaymentConnected}/>

            <SubPanel>
              <div className="title">Payment Methods</div>
              <div className="content mb-5">
                <PaymentList>
                  {
                    payments.map((item, index) => {
                      return (
                        <div className="mr-5 mb-4" key={index}>
                          <PaymentCard
                            data={item}
                            primary={ primaryPaymentId === item.id }
                            setPrimaryId={(id) => setPrimaryPaymentId(id)}
                            deletePayment={(id) => onDeletePayment(id)}
                          />
                        </div>
                      )
                    })
                  }
                  <AddPaymentBtn onClick={() => setOpenModal(true)}><PlusIcon className="fa fa-plus" /></AddPaymentBtn>
                </PaymentList>
              </div>
            </SubPanel>
            {
              !isEditing ?
                <SubPanel>
                  <div className="title">
                    Billing Information
                    <EditBtn onClick={onEditBtnClick}>EDIT</EditBtn>
                  </div>
                  <div className="content mb-5">
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">Company Name</label>
                          <p className="value">{ businessName || '_'}</p>
                        </BillingInfoItem>
                      </div>
                      <div className="col-lg-3 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">Email</label>
                          <p className="value">{ email || '_'}</p>
                        </BillingInfoItem>
                      </div>
                    </div>
                    <div className={'sub-title'}>Billing Address</div>
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">Street</label>
                          <p className="value">{ billingStreet || '_'}</p>
                        </BillingInfoItem>
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">City</label>
                          <p className="value">{ billingCity || '_'}</p>
                        </BillingInfoItem>
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">State/Province</label>
                          <p className="value">{ billingState || '_'}</p>
                        </BillingInfoItem>
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">Postal Code</label>
                          <p className="value">{ billingPostalCode || '_'}</p>
                        </BillingInfoItem>
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <BillingInfoItem>
                          <label className="label">Country</label>
                          <p className="value">{ billingCountry || '_'}</p>
                        </BillingInfoItem>
                      </div>
                    </div>
                  </div>
                </SubPanel>
                :
                <SubPanel>
                  <div className="title">Billing Information</div>
                  <div className="content mb-5">
                    <div className="row">
                      <div className="col-lg-3 col-sm-6 mb-4">
                        <TextField
                          id="company-name"
                          label="Company Name"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={businessName}
                          onChange={(e) => updateBusinessName(e.target.value || '')}
                        />
                      </div>
                      <div className="col-lg-3 col-sm-6 mb-4">
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={email}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <TextField
                          id="street"
                          label="Street"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={billingStreet}
                          onChange={(e) => updateBillingStreet(e.target.value || '')}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <TextField
                          id="city"
                          label="City"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={billingCity}
                          onChange={(e) => updateBillingCity(e.target.value || '')}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <TextField
                          id="province"
                          label="State/Province"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={billingState}
                          onChange={(e) => updateBillingState(e.target.value || '')}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <TextField
                          id="postal-code"
                          label="Postal Code"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={billingPostalCode}
                          onChange={(e) => updateBillingPostalCode(e.target.value || '')}
                        />
                      </div>
                      <div className="col-lg-2 col-sm-6 mb-4">
                        <TextField
                          id="country"
                          label="Country"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={billingCountry}
                          onChange={(e) => updateBillingCountry(e.target.value || '')}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <CancelBtn onClick={onCancelBtnClick}>Cancel</CancelBtn>
                      <SaveBtn onClick={onSaveBtnClick}>Save</SaveBtn>
                    </div>
                  </div>
                </SubPanel>
            }
            <SubPanel>
              <div className="title">Invoices</div>
              <div className="content">
                <InvoiceTable>
                  <TableHead>
                    <CustomTableHeader>
                      <TableCell className="no-left-padding">#</TableCell>
                      <TableCell align="left">Date</TableCell>
                      <TableCell align="left">Amount</TableCell>
                    </CustomTableHeader>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <CustomTableRow key={row.id}>
                        <TableCell component="th" scope="row" className="no-left-padding name">
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.date}</TableCell>
                        <TableCell align="left">${row.amount}</TableCell> </CustomTableRow>
                    ))}
                  </TableBody>
                </InvoiceTable>
              </div>
            </SubPanel>
          </div>
        </Center>
      </Content>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         billingStreet,
         billingCity,
         billingState,
         billingPostalCode,
         billingCountry,
         businessName,
         email,
         updateBusinessName,
         updateBillingStreet,
         updateBillingCity,
         updateBillingState,
         updateBillingPostalCode,
         updateBillingCountry,
         updateProfile,
         brand,
         last4,
         exp_month,
         exp_year,
       },
     }) => ({
      billingStreet,
      billingCity,
      billingState,
      billingPostalCode,
      billingCountry,
      businessName,
      email,
      updateBusinessName,
      updateBillingStreet,
      updateBillingCity,
      updateBillingState,
      updateBillingPostalCode,
      updateBillingCountry,
      updateProfile,
      brand,
      last4,
      exp_month,
      exp_year,
    })
  )
)(Financial);
