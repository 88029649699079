import styled, {css} from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    margin-bottom: 30px;
    border: ${props => props.finished === true ? 'none' : '1px solid #ededee'} ;
    background-color: #fff;
    background-color: ${props => props.finished === true ? '#fafcf9' : '#fff'};
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      border: 0px;
    }    
  `;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.finished === true ? '#a6b79f' : '#75757a'};
    padding: 20px 15px;
    cursor: pointer;
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }
    
    img {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 15px;
    }
    
    .header-item {
    
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      h3 {
        font-size: 20px;
        color: #415c85;
      }
      
      p {
        font-size: 14px;
        color: #aab4c4;
      }
      
      > span {
        font-size: 11px;
        color: #aab4c4;
        margin-bottom: 5px;
      }
      
      i {
        font-size: 24px;
        cursor: pointer;
        color: #8d9bb0;
        
        @media (max-width: ${props => props.theme.breakpoints.md}) {
          display: none;
        }
      }
      
      .target-name {
        ${props => props.finished && css`
          text-decoration: line-through;
          color: #8d9bb0;
        `}
      }
      
      .date {
        color: #7937d3;
      }
      
      .price {
        color: #d88f32;
      }
      
      ${props => props.finished && css`
        h3 {
          color: #b0bcca;
        }
        
        p {
          color: #c7ced6;
        }
        
        > span {
          color: #c7ced6;
        }
        
        .date {
          color: #c6abe8;
        }
        
        .price {
          color: #eccfa7;
          
          span {
            color: #eccfa7;
          }
        }
      `}
    }
      
    .angle_must {
        > i {
          display: block !important;        
          margin-right: 20px;
        }
    }
    
    .h-title {
      flex: 2;
      max-width: 32%;
    }
    
    .h-task {
      flex: 1;
      overflow: hidden;
    }
    
    .h-due-date {
      flex: 1;
      overflow: hidden;
    }
    
    .h-reward {
      flex: 1;
      overflow: hidden;
    }
    
    .h-circle {
      flex: 1;
      overflow: hidden;
    }
  `;

export const MobileHeader = styled(Header)`
    flex-direction: column;
    display: none;
    
    .mb_header_custom {
      border-bottom: 1px solid #e5dfde;
      padding-bottom: 10px !important;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
    
    > div {
      width: 100%;
    }
  `;

export const Body = styled.div`
    border-top: 1px solid #e7e8e9;
    padding: 0 15px;
    // overflow: hidden;
    max-height: 0;
    transition: all 1s ease-out;
    border-top: 0 solid #ededee;
    &.visible {
      max-height: 1000px;
      border-top: 1px solid #ededee;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      border-top: 0;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0;
    overflow: hidden;
    
    max-height: 0;
    transition: all 0.9s ease-out;
    &.visible {
      max-height: 1000px;
      padding: 15px;
    }
`;
