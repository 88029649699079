import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
const moment = require('moment');
//components
import StatusPanel from "../../StatusPanel";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

//styles
import {Content, Center, Right} from "../../styles";
import { TableWrapper } from "./styles";
import CreateClientTargetDlg from "../../../components/dialogs/CreateClientTargetDialog";
import {Container} from "../../Targets/styles";

const CustomTableCell = withStyles(theme => ({
  head: {
    fontFamily: 'Circe-Regular',
    fontSize: 14,
    cursor: 'pointer'
  },
  body: {
    fontFamily: 'Circe-Bold',
    fontSize: 18,
    color: '#456088',
    minWidth: '130px'
  },
  root: {
    border: 'none'
  }
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  box-shadow: 0 0 20px #f5f5f5;
  
  h3 {
    font-size: 18px;
    color: #181E26
  }
  
  p {
    color: #9aa6b9;
    font-size: 14px;
  }
  
  ${props => props.active === false && css`
    td {
      color: #8d9eb6;
    }
    
    h3 {
      color: #909eb2;
      text-decoration: line-through;
    }
  `}
`;

const columns = [
  {
    field: 'company_name',
    title: `Company Name ∙ Contact Person`
  },
  {
    field: 'type',
    title: 'Type'
  },
  {
    field: 'targets',
    title: 'Targets'
  },
  {
    field: 'q1',
    title: 'Q1'
  },
  {
    field: 'q2',
    title: 'Q2'
  },
  {
    field: 'q3',
    title: 'Q3'
  },
  {
    field: 'new_year_end',
    title: 'New Year End'
  },
  {
    field: 'fiscal_year_end',
    title: 'Fiscal Year End'
  },
  {
    field: 'sales_tax_freq',
    title: 'Sales Tax Freq'
  }
];

const TableHeadItem = (props) => {

  const P = styled.p`
    white-space: nowrap;
    margin-right: 11px;
    
    ${props => props.active && css`
      color: #232931;
      font-family: Circe-Bold;
      margin: 0;
    `}
    
    i {
      font-size: 9px;
      color: #b9c0ce;
      margin-left: 3px;
    }
  `;

  return (
    <P
      active={props.active}
    >
      {props.label}
      {
        props.order === 'asc' &&
        <i className="fa fa-arrow-up"/>
      }
      {
        props.order === 'desc' &&
        <i className="fa fa-arrow-down"/>
      }
    </P>
  )
};

const Client = ({ data }) => {

  const [sortItem, setSortItem] = useState({order: "desc", orderBy: 'targets'});
  const [activeClientID, setActiveClientID] = useState('');
  const [activeClientName, setActiveClientName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const handleRequestSort = (field) => {
    const orderBy = field;
    let order = "desc";

    if (sortItem.orderBy === field && sortItem.order === "desc") {
      order = "asc";
    }

    setSortItem({ order, orderBy });
  };

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <Content>
      <Center>
        <div className={'wrapper'} id="center">
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    columns.map((item, index) => (
                      <CustomTableCell
                        align={index === 0 ? 'left' : 'center'}
                        active={true}
                        key={index}
                        onClick={() => handleRequestSort(item.field)}
                      >
                        <TableHeadItem
                          active={item.field === sortItem.orderBy}
                          label={item.title}
                          index={index}
                          order={item.field === sortItem.orderBy && sortItem.order}
                        />
                      </CustomTableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getSorting(sortItem.order, sortItem.orderBy))
                  .map((row, index) => (
                  <CustomTableRow key={index} active={row.targets > 0}>
                    <CustomTableCell component="th" scope="row">
                      <div className="btnWrapper">
                        <div>
                          <h3>{ row.company_name }</h3>
                          <p>{ row.contact_person}</p>
                        </div>
                        <button
                          className="btn_add_target"
                          onClick={() => {
                            setOpenDialog(true);
                            setActiveClientName(row.company_name);
                          }}
                        >
                          ADD TARGET
                        </button>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="center">{row.type}</CustomTableCell>
                    <CustomTableCell align="center">{row.targets}</CustomTableCell>
                    <CustomTableCell align="center">{moment(row.q1).format('DD MMM YY')}</CustomTableCell>
                    <CustomTableCell align="center">{moment(row.q2).format('DD MMM YY')}</CustomTableCell>
                    <CustomTableCell align="center">{moment(row.q3).format('DD MMM YY')}</CustomTableCell>
                    <CustomTableCell align="center">{moment(row.new_year_end).format('DD MMM YY')}</CustomTableCell>
                    <CustomTableCell align="center">{moment(row.fiscal_year_end).format('DD MMM YY')}</CustomTableCell>
                    <CustomTableCell align="center">{row.sales_tax_freq}</CustomTableCell>
                  </CustomTableRow>
                ))}
              </TableBody>
            </Table>
          </TableWrapper>
        </div>
      </Center>
      <Right>
        <StatusPanel />
      </Right>
      <CreateClientTargetDlg
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        activeClientID={activeClientID}
        activeClientName={activeClientName}
      />
    </Content>
  )
};
export default Client;
