import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {MenuItem, TextField as MuiTextField} from "@material-ui/core";
import {spacing} from "@material-ui/system";

import {
  Container,
  Title,
  SubHeader,
  Content,
  Center,
  Button
} from './styles';
import {SubPanel} from "../Settings/styles";


const TextFieldSpacing = styled(MuiTextField)(spacing);

const TextField = styled(TextFieldSpacing)`
  width: 100%;
  font-family: Circe-Regular !important;
  
  .MuiInputBase-input {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
    line-height: 1.5;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  
  // .MuiOutlinedInput-input {
  //   padding: 13px 14px !important;
  // }
  
  .MuiInputLabel-outlined {
    font-family: Circe-Regular;
    color: #576f93;
    font-size: 18px;
  }
  
  .MuiFormHelperText-root {
    font-family: Circe-Regular;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #576f93;
  }
  
  .PrivateNotchedOutline-legendLabelled-3 {
    font-size: 15px;
  }
  
`;

const SaveBtn = styled(Button)`
  padding: 15px 30px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;


const Profile = (props) => {
  
  const categories = [
    {
      value: "1",
      label: "Payment & Subscriptions"
    },
    {
      value: "2",
      label: "Payment"
    },
    {
      value: "3",
      label: "Subscriptions"
    }
  ];
  
  const [category, setCategory] = React.useState(null);
  
  return (
    <>
      <Container>
        <SubHeader>
          <Title>Support</Title>
        </SubHeader>
        <Content>
          <Center>
            <div className="wrapper">
              <SubPanel>
                <div className="title">Create Support Request</div>
                <div className="content mb-4">
                  <div className="row mb-4">
                    <div className="col-xl-4 col-lg-5 col-sm-6">
                      <TextField
                        id="type"
                        select
                        label="Category"
                        onChange={(e) =>setCategory(e.target.value)}
                        variant="outlined"
                        m={2}
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {categories.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-xl-4 col-lg-5 col-sm-6">
                      <TextField
                        id="subject"
                        label="Subject"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        className={'message-input'}
                        id="message"
                        label="Message"
                        variant="outlined"
                        multiline
                        rows="10"
                        rowsMax="15"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </div>
                  </div>
                </div>
              </SubPanel>
              <SaveBtn>Send Request</SaveBtn>
            </div>
          </Center>
        </Content>
      </Container>
    </>
  )
};

export default Profile;
