import React from "react";

//components
import SolutionCard from "../SolutionCard";

//styles
import { Wrapper, SolutionCardList, Header, Body, EmptyBody, Footer, ExploreButton } from "./styles";
import { CommonButton } from "@/components/common/button";

const SolutionPanel = ({data, title}) => {

  const itemList = data;
  const [showAllItems, setShowAllItems] = React.useState(false);

  return (
    <Wrapper>
      <Body>
        {
          itemList.length === 0 ?
            <EmptyBody>
              <p>Add certification to your account.</p>
            </EmptyBody>
            :
            <SolutionCardList>
              {
                showAllItems ?
                  itemList.map(item => (
                    <SolutionCard data={item} key={item.id} />
                  ))
                  :
                  itemList.filter((item, index) => index < 2).map(item => (
                    <SolutionCard data={item} key={item.id} />
                  ))
              }
            </SolutionCardList>
        }
      </Body>
      <Footer>
        {
          !showAllItems && data.length > 1 &&
            <div className="mb-4">
              <ExploreButton onClick={() => setShowAllItems(true)} className="mr-4 mb-3">Explore More Certifications</ExploreButton>
              {
                itemList.filter((item, index) => index >= 2).map(item => (
                  <img src={item.icon} className="mr-3 mobile-hidden exp_icon" key={item.id}/>
                ))
              }
            </div>
        }
        {
          data.length === 0 &&
            <CommonButton onClick={() => setShowAllItems(true)} className="mr-4 mb-3">Explore Certifications</CommonButton>
        }
      </Footer>
    </Wrapper>
  )
};

export default SolutionPanel;
