import React from "react";
import styled from "styled-components";
import { colorLuminance } from '@/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 7px;
  overflow: hidden;
  height: 100%;
  min-height: 220px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    height: unset;
  }
`;

const Brand = styled.div`
  flex: 0 0 30%;
  background-color: ${props => (props.color ? props.color : '#fbfefe')};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 170px;
  padding: 12px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    align-items: center;
    min-height: 100px;
    justify-content: flex-start;
  }
  
  .brand-image {
    width: 80%;
  
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
    
    img {
      width: 100%;
    }
  }
  
  .header {
    h3 {
      font-family:  Circe-Bold;
      font-size: 24px;
      color: white;
    }
    
    p {
      color: white;
    }
  }
  
  img {
    height: 100px;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      height: 80px;
    }
  }
  
  //
  // img {
  //   width: 80%;
  //   max-height: 60%;
  //   max-width: 80%;
  //
  //   @media (max-width: ${props => props.theme.breakpoints.sm}) {
  //     max-height: 80%;
  //   }
  // }
`

const Content = styled.div`
  background-color: ${props => (props.color ? props.color : '#fbfefe')};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
  border-bottom: 1px solid #81949c;
  
  h3 {
    font-size: 20px;
    color: #fbfefe;
  }
  
  p {
    font-size: 14px;
    color: #fbfefe;
  }
`;

const ContentBody = styled.div`
  border-top: 1px solid #81949c;
  border-bottom: 1px solid #81949c;
  padding: 8px 12px 17px;
  flex: 1;
  
  span {
    font-size: 12px;
    color: #81949c;
  }
  
  p {
    font-size: 16px;
    color: #fbfefe;
    white-space: break-spaces;
  }
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px;
  min-height: 50px;
  
  .prev-price {
    font-size: 10px;
    font-family: Circe-Bold;
    text-decoration: line-through;
    color: #46636f;
    
    span {
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
  
  .price {
    font-size: 17px;
    font-family: Circe-ExtraBold;
    color: #fbfefe;
    display: flex;
    align-items: center;
    
    span {
      font-size: 10px;
      font-weight: 400;
      margin-left: 3px;
      letter-spacing: 1px;
      color: #81949c;
    }
  }
`;

export const AddButton = styled.button`
  font-size: 10px;
  padding: 5px 10px 3px;
  font-family: Circe-Regular;
  background-color: #fbfefe;
  border-radius: 3px;
  border: 1px solid #e3e7e8;
  cursor: pointer;
  letter-spacing: 1px;
  
  &:hover {
    background-color: #72767c !important;
  }
`

const SpecialCard = ({ data, toggleOpenModal }) => {

  const {
    Product_Logo_url__c,
    Name,
    Product_Sub_text__c,
    color_code_hover__c,
    Category__c,
    Description__c,
    Cloudmeb_Price__c,
    Retail_Price__c,
    CurrencyIsoCode,
    Frequency__c,
    Special_offer_background_color__c,
  } = data;

  return (
    <Wrapper>
      <Brand color={ color_code_hover__c }>
        <img src={ Product_Logo_url__c } alt='img'/>
      </Brand>
      <Content color={ colorLuminance(color_code_hover__c, -0.8) }>
        <ContentHeader>
          <h3>{ Name }</h3>
          <p>{ Product_Sub_text__c }</p>
        </ContentHeader>
        <ContentBody>
          <span>{ Category__c }</span>
          <p>{ Description__c }</p>
        </ContentBody>
        <ContentFooter>
          <div>
            {
              Retail_Price__c ?
                <p className={'prev-price'}>{ Retail_Price__c } <span>{CurrencyIsoCode}/{Frequency__c}</span></p>
              : <></>
            }
            <p className={'price'}>${ Cloudmeb_Price__c } <span>{CurrencyIsoCode}/{Frequency__c}</span></p>
          </div>
          <AddButton onClick={() => toggleOpenModal(true, data)}>ADD</AddButton>
        </ContentFooter>
      </Content>
    </Wrapper>
  )
}

export default SpecialCard;
