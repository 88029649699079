import React, {useEffect, useState} from "react";
import moment from "moment";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import {
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl as MuiFormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import {
  Container,
  Wrapper,
  Header,
  Name,
  Body,
  Footer,
  Title,
  TextField,
  InputForm,
  SaveButton,
  CloseButton,
  CancelButton,
  DeleteButton,
  DatePicker,
  Select,
  FrequencyLabel,
  FrequencySelect,
  FrequencySlider,
  LabelItem
} from "./styles";
import { STORE_KEYS } from '@/stores';
import {SvgCalendarIcon, SvgCloseIcon} from "../../SvgIcon";
import Tooltip from "../../common/Tooltip";
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const frequencyLabel = [
  {
    level: 1,
    label: 'One-Time'
  },
  {
    level: 2,
    label: 'Weekly'
  },
  {
    level: 3,
    label: 'Bi-Weekly'
  },
  {
    level: 4,
    label: 'Monthly'
  },
  {
    level: 5,
    label: 'Quarterly'
  },
  {
    level: 6,
    label: 'Yearly'
  }

];

const EditClientTargetDlg = (props) => {
  const {
    activeClientName = '',
    activeClientId,
    mTargetId,
    mTargetName,
    mTargetCategory,
    mTargetDueDate,
    mTargetFrequency,
    mTargetDescription,
    editActiveTarget,
    deleteActiveTarget,
    open,
    handleClose,
  } = props;

  const indexOfFrequency = frequencyLabel.map(e => e.label).indexOf(mTargetFrequency);
  const [targetName, setTargetName] = useState(mTargetName);
  const [dueDate, setDueDate] = useState(moment(mTargetDueDate));
  const [category, setCategory] = useState(mTargetCategory);
  const [description, setDescription] = useState(mTargetDescription);
  const [labelWidth, setLabelWidth] = useState(0);
  const [frequency, setFrequency] = useState(indexOfFrequency);
  const [autoRenew, setAutoRenew] = useState(true);
  const [emailNotification, setEmailNotification] = useState(true);
  let inputLabelRef;

  useEffect(() => {
    setLabelWidth(ReactDOM.findDOMNode(inputLabelRef).offsetWidth);
  }, []);

  const onSaveBtnClick = () => {
    const date = moment(dueDate).format('YYYY-M-D');
    const freq = frequencyLabel[frequency - 1].label;
    editActiveTarget(mTargetId, targetName, date, category, freq, description)
      .then(() => {
        handleClose();
      });
  };

  const onDeleteBtnClick = () => {
    deleteActiveTarget(mTargetId)
      .then(() => {
        handleClose();
      });
  };

  return (
    <Container isShow={open}>
      <Wrapper>
        <Header>
          <Title>EDIT CLIENT TARGET</Title>
          <Name>
            <img src="/images/dashboard/people1.png" alt="img"/>
            <h3>{activeClientName}</h3>
          </Name>
          <CloseButton onClick={handleClose}><SvgCloseIcon/></CloseButton>
        </Header>
        <Body>
          <InputForm>
            <TextField
              id="targetName"
              label="Target Name"
              variant="outlined"
              value={targetName}
              autoFocus
              onChange={(e) => setTargetName(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <div className="row">
              <div className="col-sm-6">
                <div className="custom-datepicker">
                  <DatePicker
                    label="Due Date"
                    value={dueDate}
                    format={'d MMMM yyyy'}
                    onChange={(date) => setDueDate(date)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputVariant="outlined"
                  />
                  <SvgCalendarIcon/>
                </div>
              </div>
              <div className="col-sm-6">
                <FormControl m={2} variant="outlined">
                  <InputLabel
                    ref={ref => {
                      inputLabelRef = ref;
                    }}
                    htmlFor="category"
                  >
                    Category
                  </InputLabel>
                  <Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value) }
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputVariant="outlined"
                    name="Category"
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="Category"
                        id="category"
                      />
                    }
                  >
                    <MenuItem value={'Sales Tax Report'}>Sales Tax Report</MenuItem>
                    <MenuItem value={'Company Filing'}>Company Filing</MenuItem>
                    <MenuItem value={'Payroll'}>Payroll</MenuItem>
                    <MenuItem value={'Bookkeeping'}>Bookkeeping</MenuItem>
                    <MenuItem value={'Accounting'}>Accounting</MenuItem>
                    <MenuItem value={'Onboarding'}>Onboarding</MenuItem>
                    <MenuItem value={'Cloudmeb'}>Cloudmeb</MenuItem>
                  </Select>
                </FormControl>
              </div>

            </div>
            <FrequencySelect>
              <label>Frequency</label>
              <FrequencySlider
                value={frequency}
                min={1}
                max={6}
                step={1}
                aria-labelledby="label"
                onChange={(e, val) => setFrequency(val)}
              />
              <FrequencyLabel>
                {
                  frequencyLabel.map((item, index) => {
                    return (
                      <LabelItem
                        active={frequency === item.level}
                        onClick={() => setFrequency(item.level)}
                        key={index}
                      >
                        {item.label}
                      </LabelItem>
                    )
                  })
                }
              </FrequencyLabel>
            </FrequencySelect>
            <TextField
              className="multiline-text"
              id="description"
              label="Description"
              variant="outlined"
              multiline
              rows="6"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoRenew}
                    onChange={() => setAutoRenew(!autoRenew) }
                    value="autoRenew"
                  />
                }
                label="Auto Renew"
              />
              <Tooltip
                width="150"
                content="Auto Renew"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={emailNotification}
                    onChange={() => setEmailNotification(!emailNotification) }
                    value="emailNotification"
                  />
                }
                label="Email Notification"
              />
              <Tooltip
                width="150"
                content="Email Notification"
              />
            </div>
          </InputForm>
        </Body>
        <Footer>
          <DeleteButton
            onClick={onDeleteBtnClick}
          >
            Delete
          </DeleteButton>
          <div>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <SaveButton
              className="ml-3"
              onClick={onSaveBtnClick}
            >
              Save
            </SaveButton>
          </div>
        </Footer>
      </Wrapper>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.TARGETSSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.TARGETSSTORE]: {
         editActiveTarget,
         deleteActiveTarget,
       },
     }) => ({
      editActiveTarget,
      deleteActiveTarget,
    })
  )
)(EditClientTargetDlg);
