import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  
  .help-icon {
    margin-left: 7px;
    cursor: pointer;
    width: 11px;
    height: 11px;
    min-width: 11px;
  }
`;

const Text = styled.div`
  display: ${props => props.isShow ? 'block' : 'none'};
  background: #fff;
  opacity: 0.9;
  width: ${props => props.width}px;
  position: absolute;
  top: 21px;
  right: -50px;
  z-index: 10;
  font-family: Circe-Regular;
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  box-shadow: -2px 0px 5px 1px rgba(0,0,0,0.16);
`;

const Tooltip = (props) => {
  const [isOpened, setIsOpened] = React.useState(false);

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isOpened && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      setIsOpened(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });
  return (
    <Wrapper ref={toggleContainer}>
      <img src={'/images/icons/Icon_Help.svg'} alt={'Help Icon'} className="help-icon" onClick={() => setIsOpened(!isOpened)}/>
      <Text isShow={isOpened} width={props.width || '230'}>{props.content || ''}</Text>
    </Wrapper>
  )
}

export default Tooltip;
