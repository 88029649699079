import React from "react";

//styles
import { Container, Wrapper, Title, Header, Content, ButtonGroup, CancelButton , PayButton, PTView } from "./styles";
import {SvgCloseIcon, SvgPointIcon} from "../../SvgIcon";
import {CloseButton, Info} from "../AddProductDialog/styles";

const AddCertificationDialog = (props) => {

  const {
    data, open, handleClose, createNewCertification
  } = props;

  const [isSuccess, setIsSuccess] = React.useState(false);

  const onAddBtnClick = () => {
    const { Id } = data.itself;
    createNewCertification(Id)
      .then(() => {
        setIsSuccess(true);
      });
  };

  return (
    <Container isShow={open}>
      <Wrapper>
        <SvgCloseIcon className={'close-icon'} onClick={handleClose}/>
        <Title>ADD CERTIFICATION</Title>
        <Header>
          <div className="d-flex mb-4 align-items-center">
            <img src={data.icon} alt={"Icon"}/>
            <h3>{data.name}</h3>
          </div>
          {!isSuccess ? (
            <p>{data.desc}</p>
          ) : (
            <>
              <Info>
                <p className={'bold'}>Thank you for your apply certification</p>
                <p>{`Manager your certifications under\n`} <a href={"/my-certifications"}>{`\nMy Certifications`}</a></p>
              </Info>
            </>
          )}
        </Header>
        {!isSuccess && (
          <Content>
            <div>
              <span>EST. TIME</span>
              <p className={'est-time'}>{data.time}</p>
            </div>
            <div>
              <span>REWARD</span>
              <p className={'pt-view'}><SvgPointIcon className="mr-1"/>{data.price}</p>
            </div>
          </Content>
        )}
        {!isSuccess ? (
          <ButtonGroup>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <PayButton onClick={() => onAddBtnClick()}>Add Now</PayButton>
          </ButtonGroup>
        ) : (
          <CloseButton onClick={() => handleClose()}>Close</CloseButton>
        )}
      </Wrapper>
    </Container>
  )
};

export default AddCertificationDialog;
