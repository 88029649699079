import React, { Component } from "react";
import styled from "styled-components";
import { compose, withProps } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import { STORE_KEYS } from '@/stores';
//components
import LoginTitle from "../../components/login/login-title";
import Input from "../../components/common/Input";
import Button from "../../components/common/button";
import BtnGoogle from "../../components/googleLoginBtn";

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Index = styled.div`
  flex: 1;
  display: flex;
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("./imgs/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  width: 50% !important;
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const LoginForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 30px 0;
  background-color: #fff;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const LoginFormContent = styled.div`
  max-width: 480px;
  width: 100%;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const FormBody = styled.div`
  max-width: 400px;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  > div {
    margin-top: 30px;
  }
`

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const ForgotButton = styled.button`
  max-width: 304px;
  width: 100%;
  min-height: 73px;
  border-radius: 20px;
  border: solid 1px #8d9bb0;
  outline: none;
  cursor: pointer;
  font-family: Circe-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #415c85;
  background: #fff;
  margin-left: 20px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
    width: 100%;
    margin: 0;
    margin-top: 20px;
    min-height: 70px;
  }
`;

const Label = styled.div`
  width: 22px;
  height: 36px;
  font-family: Circe-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #415c85;
  margin-right: 20px;
`;

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.setRedirect();
    }
  }

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value
    });
  };

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  };

  goLogin = () => {
    let { email, password } = this.state;
    email = (email || '').trim();
    password = (password || '').trim();

    if (email.length === 0 || password.length === 0) {
      this.props.showSnackMsg('Email or Password is not valid.');
      return;
    }

    this.props.loginWithMail(email, password)
      .then(() => {
        this.setRedirect();
      })
      .catch(err => {
        this.props.showSnackMsg('Login credential is not correct.');
      });
  };

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to='/home' />
    }
  };

  onProcessAuth = (response) => {
    console.log('response:', response);
    if (!response.error) {
      const { loginWithGoogle } = this.props;
      loginWithGoogle(response)
        .then(() => {
          this.setRedirect();
        })
        .catch(err => {
          this.props.showSnackMsg('Login is failed.');
        });
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <>
        {this.renderRedirect()}
        <Wrapper>
          <Index>
            <Brand>
              <img src="/images/login/img_msg_sent.png" alt=""/>
            </Brand>
            <LoginForm>
              <LoginFormContent>
                <LoginTitle title={'Login'}/>
                <FormBody>
                  <Input
                    label={'Login'}
                    type={'text'}
                    placeholder={'email'}
                    value={email}
                    onChange={this.onChangeEmail}
                  />
                  <Input
                    label={'Password'}
                    type={'password'}
                    placeholder={'password'}
                    value={password}
                    onChange={this.onChangePassword}
                  />
                </FormBody>
                <BtnGroup>
                  <Button
                    label={'Login'}
                    onClick={this.goLogin}
                  />
                  <ForgotButton
                  >
                    Forgot Password
                  </ForgotButton>
                </BtnGroup>
                <BtnGroup>
                  <Label>or</Label>
                  <BtnGoogle
                    label={'Sign in with Google'}
                    onProcessAuth={this.onProcessAuth}
                  />
                </BtnGroup>
              </LoginFormContent>
            </LoginForm>
          </Index>
        </Wrapper>
      </>
    );
  }
}


export default compose(
  inject(STORE_KEYS.AUTHSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.AUTHSTORE]: {
         loginWithMail, loginWithGoogle, isLoggedIn, showSnackMsg,
       },
     }) => ({
      loginWithMail,
      loginWithGoogle,
      isLoggedIn,
      showSnackMsg,
    })
  )
)(LoginPage);
