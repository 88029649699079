import styled from "styled-components";

export const Container = styled.div`
  box-shadow: 0 0 20px #f5f5f5;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    min-width: 220px;
    height: unset;
  }
`

export const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #ebeef2;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: white;
 
  h3 {
    line-height: 24px
    margin: 3px 0 2px 10px;
    font-size: 16px;
    color: #415c85;
  }
  
  p {
    margin-left: 10px;
    font-size: 12px;
    line-height: 18px;
    color: #9aa6b9;
  }
  
  > div {
    flex: 1
  }
`

export const Icon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 100%;
`

export const Content = styled.div`
  flex: 1;
  padding: 10px 15px;
  
  .property {
    font-size: 11px;
    padding-bottom: 5px;
    color: #8295b0;
    letter-spacing: 1px;
  }
`

export const Desc = styled.div`
  display: flex;
  align-items: flex-start;
  
  span {
    font-size: 31px;
    line-height: 14px;
    margin-right: 5px;
  }
  
  p {
    font-size: 14px;
    line-height: 1.6;
    white-space: break-spaces;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #fbfefe;
  
  .time {
    font-size: 12px;
    font-family: Circe-Bold;
    color: #405c85;
    
    span {
      font-size: 11px;
      font-weight: 400;
      margin-left: 8px;
      letter-spacing: 1px;
      color: #bdc2c8;
    }
  }
`

export const AddButton = styled.button`
  font-size: 10px;
  padding: 5px 10px 3px;
  font-family: Circe-Bold;
  background: none;
  border-radius: 3px;
  border: 1px solid #e3e7e8;
  cursor: pointer;
  color: #72767c;
  letter-spacing: 1px;
`;

export const PTView = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
  white-space: nowrap;
  color: #d88f32;
  font-family: Circe-Bold;
`;
