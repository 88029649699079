import React, {useEffect, useState} from "react";
import moment from "moment";
import styled from "styled-components";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import {
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl as MuiFormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import {
  Container,
  Wrapper,
  Header,
  Name,
  Body,
  Footer,
  Title,
  TextField,
  InputForm,
  SaveButton,
  CloseButton,
  CancelButton,
  DatePicker,
  Select,
  FrequencyLabel,
  FrequencySelect,
  FrequencySlider,
  LabelItem
} from "./styles";
import {SvgCalendarIcon, SvgCloseIcon} from "../../SvgIcon";
import { spacing } from "@material-ui/system";
import Tooltip from "../../common/Tooltip";
import { STORE_KEYS } from '@/stores';

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;

const frequencyLabel = [
  {
    level: 1,
    label: 'One-Time'
  },
  {
    level: 2,
    label: 'Weekly'
  },
  {
    level: 3,
    label: 'Bi-weekly'
  },
  {
    level: 4,
    label: 'Monthly'
  },
  {
    level: 5,
    label: 'Quarterly'
  },
  {
    level: 6,
    label: 'Yearly'
  }
];

const CreateClientTargetDlg = (props) => {
  const  {
    activeClientID,
    activeClientName,
    open,
    handleClose,
    createCMBTarget,
    requestCloudmebTargetsByClients,
    requestCloudTasks,
  } = props;
  const [targetName, setTargetName] = useState('');
  const [dueDate, setDueDate] = useState(moment());
  const [category, setCategory] = useState('accounting');
  const [description, setDescription] = useState('');
  const [labelWidth, setLabelWidth] = useState(0);
  const [frequency, setFrequency] = useState(4);
  const [autoRenew, setAutoRenew] = useState(true);
  const [emailNotification, setEmailNotification] = useState(true);

  let inputLabelRef;

  useEffect(() => {
    setLabelWidth(ReactDOM.findDOMNode(inputLabelRef).offsetWidth);
  }, []);

  const onSaveBtnClick = () => {
    createCMBTarget(
      activeClientID, targetName, dueDate, category, frequencyLabel[frequency].label,
      description, autoRenew, emailNotification
    )
      .then(() => {
        handleClose();
        requestCloudmebTargetsByClients();
        requestCloudTasks();
      });
  };

  return (
    <Container isShow={open}>
      <Wrapper>
        <Header>
          <Title>CREATE CLIENT TARGET</Title>
          <Name>
            <img src="/images/dashboard/people1.png" alt="img"/>
            <h3>{activeClientName || 'Client Name'}</h3>
          </Name>
          <CloseButton onClick={handleClose}><SvgCloseIcon/></CloseButton>
        </Header>
        <Body>
          <InputForm>
            <TextField
              id="targetName"
              label="Target Name"
              variant="outlined"
              value={targetName}
              autoFocus
              onChange={(e) => setTargetName(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <div className="row">
              <div className="col-sm-6">
                <div className="custom-datepicker">
                  <DatePicker
                    label="Due Date"
                    value={dueDate}
                    format={'d MMMM yyyy'}
                    onChange={(date) => setDueDate(date)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputVariant="outlined"
                  />
                  <SvgCalendarIcon/>
                </div>
              </div>
              <div className="col-sm-6">
                <FormControl m={2} variant="outlined">
                  <InputLabel
                    ref={ref => {
                      inputLabelRef = ref;
                    }}
                    htmlFor="category"
                  >
                    Category
                  </InputLabel>
                  <Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value) }
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputVariant="outlined"
                    name="Category"
                    input={
                      <OutlinedInput
                        labelWidth={labelWidth}
                        name="Category"
                        id="category"
                      />
                    }
                  >
                    <MenuItem value={'Sales Tax Report'}>Sales Tax Report</MenuItem>
                    <MenuItem value={'Company Filing'}>Company Filing</MenuItem>
                    <MenuItem value={'Payroll'}>Payroll</MenuItem>
                    <MenuItem value={'Bookkeeping'}>Bookkeeping</MenuItem>
                    <MenuItem value={'Accounting'}>Accounting</MenuItem>
                    <MenuItem value={'Onboarding'}>Onboarding</MenuItem>
                    <MenuItem value={'Cloudmeb'}>Cloudmeb</MenuItem>
                  </Select>
                </FormControl>
              </div>

            </div>
            <FrequencySelect>
              <label>Frequency</label>
              <FrequencySlider
                value={frequency}
                min={1}
                max={6}
                step={1}
                aria-labelledby="label"
                onChange={(e, val) => setFrequency(val)}
              />
              <FrequencyLabel>
                {
                  frequencyLabel.map((item, index) => {
                    return (
                      <LabelItem
                        active={frequency === item.level}
                        onClick={() => setFrequency(item.level)}
                        key={index}
                      >
                        {item.label}
                      </LabelItem>
                    )
                  })
                }
              </FrequencyLabel>
            </FrequencySelect>
            <TextField
              className="multiline-text"
              id="description"
              label="Description"
              variant="outlined"
              multiline
              rows="6"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoRenew}
                    onChange={() => setAutoRenew(!autoRenew) }
                    value="autoRenew"
                  />
                }
                label="Auto Renew"
              />
              <Tooltip
                width="150"
                content="Auto Renew"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={emailNotification}
                    onChange={() => setEmailNotification(!emailNotification) }
                    value="emailNotification"
                  />
                }
                label="Email Notification"
              />
              <Tooltip
                width="150"
                content="Email Notification"
              />
            </div>
          </InputForm>
        </Body>
        <Footer>
          <CancelButton onClick={props.handleClose}>Cancel</CancelButton>
          <SaveButton
            className="ml-3"
            onClick={() => onSaveBtnClick()}
          >Create Target</SaveButton>
        </Footer>
      </Wrapper>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE, STORE_KEYS.TARGETSSTORE, STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         createCMBTarget,
       },
       [STORE_KEYS.TARGETSSTORE]: {
         requestCloudmebTargetsByClients,
       },
       [STORE_KEYS.CHECKLISTSTORE]: {
         requestCloudTasks,
       },
     }) => ({
      createCMBTarget,
      requestCloudmebTargetsByClients,
      requestCloudTasks,
    })
  )
)(CreateClientTargetDlg);
