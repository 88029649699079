import React, { useEffect } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

//components
import TargetBlock from "@/containers/Targets/TargetBlock";
import StatusPanel from "@/containers/StatusPanel";
import { STORE_KEYS } from '@/stores';

//styles
import {
  Container, Title, Content, Center, Right, BackToList, SubHeader, TabItem, Tabs, ClientsTargetPanel,
} from './styles';
import CreateClientTargetDlg from "../../components/dialogs/CreateClientTargetDialog";

const TargetPage = ({
    cloudmebTargets, tabItems, requestCloudmebTargets, markTaskAsComplete,
    requestCloudmebTargetsByClients, cloudmebTargetsByClients, requestCloudTasks,
}) => {
  const [selectedTabId, setSelectedTabId] = React.useState('Active');
  const [detailViewId, setDetailViewId] = React.useState('');
  const [activeClientID, setActiveClientID] = React.useState('');
  const [activeClientName, setActiveClientName] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    requestCloudTasks();
    requestCloudmebTargets();
    requestCloudmebTargetsByClients();
  }, {});

  const targetsKeys = Object.keys(cloudmebTargetsByClients);
  const targetsValues = Object.values(cloudmebTargetsByClients);

  return (
    <>
      <Container>
        <SubHeader>
          <Title>Targets</Title>
          <Tabs>
            {
              tabItems.map(item => {
                return (
                  selectedTabId === item.name ?
                    <TabItem active onClick={() => setSelectedTabId(item.name)} key={item.name}>{item.name} <span>{item.count}</span></TabItem>
                    : <TabItem onClick={() => setSelectedTabId(item.name)} key={item.name}>{item.name} <span>{item.count}</span></TabItem>
                )
              })
            }
          </Tabs>
        </SubHeader>
        <Content>
          <Center>
            <div className={'wrapper'} id="center">
              {
                (selectedTabId !== 'By Clients') ?
                    cloudmebTargets.map((item, index) => {
                        if (selectedTabId === 'All') {
                          return (
                            <TargetBlock
                              target={item}
                              showAll={detailViewId !== ''}
                              setDetailViewId={setDetailViewId}
                              key={index}
                              markTaskAsComplete={markTaskAsComplete}
                            />
                          )
                        } else if (selectedTabId === 'Active') {
                          if (!item.completed) {
                            return (
                              <TargetBlock
                                target={item}
                                showAll={detailViewId !== ''}
                                setDetailViewId={setDetailViewId}
                                key={index}
                                markTaskAsComplete={markTaskAsComplete}
                              />
                            )
                          }
                        } else if (selectedTabId === 'Completed') {
                          if (item.completed) {
                            return (
                              <TargetBlock
                                target={item}
                                showAll={detailViewId !== ''}
                                setDetailViewId={setDetailViewId}
                                key={index}
                                markTaskAsComplete={markTaskAsComplete}
                              />
                            )
                          }
                        }
                    }) : targetsKeys.map((key, index) => {
                        // Todo: duplicated code, should make a new component for this
                        // Todo: Replace this with ClientsTargets component
                        return (
                          <ClientsTargetPanel key={index}>
                            <div className={'wrapper_target_header'}>
                              <div className={'title'}>
                                <h3>{(key || "").split(":")[0]}</h3>
                                <p>{(key || "").split(":")[1]}</p>
                              </div>
                              <button
                                className="btn_add_target"
                                onClick={() => {
                                  setActiveClientName((key || "").split(":")[0]);
                                  setActiveClientID((key || "").split(":")[2]);
                                  setOpenDialog(true);
                                }}
                              >
                                ADD TARGET
                              </button>
                            </div>
                            {
                              targetsValues[index].map((item, id) => {
                                return (
                                  <TargetBlock
                                    target={item}
                                    showAll={detailViewId !== ''}
                                    setDetailViewId={setDetailViewId}
                                    showContent={false}
                                    isClientTasks={true}
                                    markTaskAsComplete={markTaskAsComplete}
                                    activeClientName={(key || "").split(":")[0]}
                                    activeClientId={(key || "").split(":")[2]}
                                  />
                                )
                              })
                            }
                          </ClientsTargetPanel>
                        )
                    })
              }
            </div>
          </Center>
          <Right>
            <StatusPanel/>
          </Right>
        </Content>
        <CreateClientTargetDlg
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          activeClientID={activeClientID}
          activeClientName={activeClientName}
        />
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.TARGETSSTORE, STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.TARGETSSTORE]: {
         cloudmebTargets,
         tabItems,
         requestCloudmebTargets,
         markTaskAsComplete,
         requestCloudmebTargetsByClients,
         cloudmebTargetsByClients,
       },
       [STORE_KEYS.CHECKLISTSTORE]: {
         requestCloudTasks,
       }
     }) => ({
      cloudmebTargets,
      tabItems,
      requestCloudmebTargets,
      markTaskAsComplete,
      requestCloudmebTargetsByClients,
      cloudmebTargetsByClients,
      requestCloudTasks,
    })
  )
)(TargetPage);
