import styled from "styled-components";
import {
  TextField as MuiTextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
export const TextField = styled(MuiTextField)(spacing);

export const DatePicker = styled(MuiDatePicker)(spacing);

export const Container = styled.div`
  display: ${props => props.isShow ? 'flex' : 'none'};
  position: fixed;
  z-index: 1100;
  padding: 20px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0, 0.5);
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 365px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  padding: 20px 20px 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef2;
`;

export const Body = styled.div`
  width: 100%;
  padding: 30px 20px 0 20px;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 85px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #fbfefe;
`;

export const Title = styled.h3`
  font-size: 13px;
  color: #181e26;
`;

export const Name =styled.div`
  display: flex;
  align-items: center;
  
  img {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-right: 10px;
  }
  
  h3 {
    font-size: 20px;
    color: #415c85;
  }
`;

export const InputForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .MuiInputBase-input {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
  }
  
  .MuiFormLabel-root {
    font-family: Circe-Regular;
    color: #576f93;
    font-size: 16px;
  }
  
  .MuiTypography-body1 {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
  
  .MuiMenuItem-root {
    font-family: Circe-Regular;
    font-size: 18px;
    color: #181e26;
  }
  
  .MuiFormControl-root {
    width: 100%;
    font-family: Circe-Regular !important;
    height: 43px;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: #576f93;
  }
  
  .MuiSelect-select:focus {
    background: none;
  }
  
  .multiline-text {
    height: unset !important;
  }
  
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #415c85;
  }
  
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
  
  .MuiFormControl-root {
    margin-bottom: 25px;
  }
  
  .custom-datepicker {
    position: relative;
    width: 190px;
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
    }
    
    svg {
      position: absolute;
      right: 10px;
      top: 14px;
    }
  }
`;

export const DeleteButton = styled.button`
  font-family: Circe-Bold;
  font-size: 13px;
  color: #965f56;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
`;

export const CancelButton = styled.button`
  font-family: Circe-Bold;
  font-size: 16px;
  color: #5e7599;
  background: #f5f7f9;
  width: 90px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  :hover {
    background: #e3e7eb;
  }
`;

export const SaveButton = styled.button`
  font-family: Circe-Bold;
  font-size: 16px;
  color: #fff;
  background: #415c85;
  width: 90px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  
  :hover {
    background: #2f466a;
  }
`;

export const CloseButton = styled.span`
  position: absolute;
  top: 12px;
  right: 20px;
  cursor: pointer;
`;
