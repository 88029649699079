import React from "react";
import {observable, action, reaction} from 'mobx';
import {
    getProducts, getServices, getMyTemplates, getProfile, cancelSubscriptionProduct, cancelSubscriptionService
} from "@/lib/rest";

class MySolutionsStore {
    @observable email = '';
    @observable products = []; // my products
    @observable services = []; // my services
    @observable myTemplates = []; // xero templates
    @observable accountId = '';

    @observable onboarding_avatar = '';
    @observable onboarding_Company = '';
    @observable onboarding_email = '';
    @observable onboarding_calendly = '';

    @observable education_avatar = '';
    @observable education_Company = '';
    @observable education_Email = '';
    @observable education_Calendly = '';

    @observable accounting_avatar = '';
    @observable accountant_Name = '';
    @observable accountant_email = '';
    @observable accounting_calendly = '';

    @observable billing_avatar = '';
    @observable billing_Name = '';
    @observable billing_email = '';
    @observable billing_calendly = '';

    @observable targetOverallProgress = '';
    @observable taskActivityScore = '';
    @observable googleDriveLink = '';
    vModeHandle = null;

    constructor(snackbar, authStore, viewModeStore) {
        this.snackbar = snackbar;
        this.isLoggedIn = authStore.isLoggedIn;
        this.email = localStorage.getItem('email');
        this.vModeHandle = viewModeStore;

        reaction(
            () => ({
                isLoggedIn: authStore.isLoggedIn,
                email: authStore.email
            }),
            authObj => {
                this.isLoggedIn = authObj.isLoggedIn;
                this.email = authObj.email;
                if (this.isLoggedIn) {
                    this.requestProducts();
                    this.requestServices();
                    this.requestMyTemplates();
                    this.requestProfile();
                }
            }
        );

        if (this.isLoggedIn) {
            this.requestProducts();
            this.requestServices();
            this.requestMyTemplates();
            this.requestProfile();
        }
    }

    @action.bound requestProducts() {       // This is Cloudmeb Products
        if (!this.email) return;
        this.vModeHandle.setLoadingMode(true);
        getProducts(this.email)
            .then(ret => {
                // console.log('[Products]', ret);
                if (ret.data) {
                    this.products = ret.data || [];
                }
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.products = [];
                this.vModeHandle.setLoadingMode(false);
            });
    }

    @action.bound requestServices() {       // This is Cloudmeb Services
        if (!this.email) return;
        this.vModeHandle.setLoadingMode(true);
        getServices(this.email)
            .then(ret => {
                // console.log('[Services]', ret);
                if (ret.data) {
                    this.services = ret.data || [];
                }
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.services = [];
                this.vModeHandle.setLoadingMode(false);
            })
    }

    @action.bound requestMyTemplates() {      // This is My Templates
        this.vModeHandle.setLoadingMode(true);
        getMyTemplates(this.email)
            .then(ret => {
                // console.log('[Templates]', ret);
                if (ret.data) {
                    this.myTemplates = ret.data || [];
                }
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.myTemplates = [];
                this.vModeHandle.setLoadingMode(false);
            });
    }

    @action.bound requestCancelProduct(productId, subscriptionId) {
        this.vModeHandle.setLoadingMode(true);
        cancelSubscriptionProduct({
            productId: productId,
            subscriptionId: subscriptionId,
        })
            .then(ret => {
                this.showSnackMsg(ret.message);
                this.requestProducts();
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.vModeHandle.setLoadingMode(false);
            });
    }

    @action.bound requestCancelService(serviceId, subscriptionId) {
        this.vModeHandle.setLoadingMode(true);
        cancelSubscriptionService({
            serviceId: serviceId,
            subscriptionId: subscriptionId,
        })
            .then(ret => {
                this.showSnackMsg(ret.message);
                this.requestServices();
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.vModeHandle.setLoadingMode(false);
            })
    }

    @action.bound requestProfile() {
        if (!this.email) return;
        this.vModeHandle.setLoadingMode(true);
        getProfile(this.email)
            .then(ret => {
                if (ret.data) {
                    this.accountId = ret.data[0].accountId || '';
                    this.onboarding_avatar = ret.data[0].onboarding.CM_Onboard_Avatar__c || '';
                    this.onboarding_Company = ret.data[0].onboarding.CM_Onboard_Name__c || '';
                    this.onboarding_email = ret.data[0].onboarding.CM_Onboard_Email__c || '';
                    this.onboarding_calendly = ret.data[0].onboarding.CM_Onboard_Calendly__c || '';

                    this.education_avatar = ret.data[0].education.CM_Education_Avatar__c || '';
                    this.education_Company = ret.data[0].education.CM_Education_Name__c || '';
                    this.education_Email = ret.data[0].education.CM_Education_email__c || '';
                    this.education_Calendly = ret.data[0].education.CM_Education_calendly__c || '';

                    this.accounting_avatar = ret.data[0].support.CM_Support_Avatar__c || '';
                    this.accountant_Name = ret.data[0].support.CM_Support_Name__c || '';
                    this.accountant_email = ret.data[0].support.CM_Support_email__c || '';
                    this.accounting_calendly = ret.data[0].support.CM_Support_calendly__c || '';

                    this.billing_avatar = ret.data[0].billing.CM_Billing_avatar__c || '';
                    this.billing_Name = ret.data[0].billing.CM_billing_Name__c || '';
                    this.billing_email = ret.data[0].billing.CM_billing_email__c || '';
                    this.billing_calendly = ret.data[0].billing.CM_Billing_calendly__c || '';

                    this.targetOverallProgress = ret.data[0].Target_Overall_progress__c || 0;
                    this.taskActivityScore = ret.data[0].Task_Activity_Score__c || 0;
                    this.googleDriveLink = ret.data[0].Google_Drive_Link__c || '';
                }
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.vModeHandle.setLoadingMode(false);
            })
    }

    /**
     *  Snackbar Popup message
     */
    @action.bound showSnackMsg(msg) {
        this.snackbar({
            message: () => (
                <>
          <span>
            <b>{msg}</b>
          </span>
                </>
            )
        });
    }
}

export default (snackbar, authStore, viewModeStore) => new MySolutionsStore(snackbar, authStore, viewModeStore);
