import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  min-width: 200px;
  width: 100%;
  border: 1px solid #d2d7e0;
  border-radius: 10px;
  padding: 18px 8px 18px 14px;
  
  ${props => props.isPrimary === true && css`
    border: 2px solid #8d9bb0;
  `}
`;

const Card = styled.div`
  width: 100%;
  font-family: Circe-Regular;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  
  .payment-method-name {
    font-size: 18px;
    font-weight: bold;
    color: #181e26;
  }
  
`;

const CardBody = styled.div`
  font-family: Circe-Regular;
  font-size: 15px;
  color: #181e26;
  display: flex;
  justify-content: space-between;
  
  .address {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .date {
    margin-right: 20px;
  }
`;

const CreditCardIcon = styled.div`
  width: 20px;
  height: 16px;
  border-radius: 3px;
  background: #5e7599;
  margin-right: 7px;
`;

const DropdownContainer = styled.ul`
   position: absolute;
   top: 22px;
   right: -6px;
   border-radius 5px;
   list-style-type: none;
   background-color: white;
   font-family: Circe-Regular;
   padding: 10px 0;
   z-index: 100;
   box-shadow: 0 5px 25px 0 #e0e0e0;
`;

const Item = styled.li`
  font-size: 14px;
  
  span {
    padding: 7px 20px;
    display: block;
    color: #4b648b;
    text-decoration: none;
    cursor: pointer;
  }
  
  &:hover {
    background-color: #f7fdfd;
    
    span {
      color: #1e242b;
    }
  }
  
  ${props => props.active && css`
    background-color: #f7fdfd;
    
    a {
      color: #1e242b;
    }
  `}
`;

const DownIcon = styled.i`
  font-size: 12px;
  margin-left: 5px;
`;

const SelectedOption = styled.div`
  min-width: 70px;
  color: #98a5b8;
  font-family: Circe-Regular;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PaymentCard = ({data, primary, setPrimaryId, deletePayment}) => {

  const setPrimaryPaymentId = () => {
    setPrimaryId(data.id);
  };

  const setDeletedPaymentId = () => {
    deletePayment(data.id);
  };

  return (
    <Container isPrimary={primary}>
      <Card>
        <CardHeader>
          <h3 className="payment-method-name">{data.type}</h3>
          <ActionDropDown isPrimary={primary} setPrimaryPaymentId={setPrimaryPaymentId} setDeletePaymentId={setDeletedPaymentId}/>
        </CardHeader>
        <CardBody>
          <div className="address">{ data.type === 'VISA' && <CreditCardIcon /> } <p> {data.address}</p></div>
          <p className="date">{data.date}</p>
        </CardBody>
      </Card>
    </Container>
  )
};

const ActionDropDown = ({isPrimary, setPrimaryPaymentId, setDeletePaymentId}) => {

  const [ isShowMenu, toggleMenu ] = useState(false);

  const toggleContainer = React.createRef();

  const onClickHandler = () => {
    toggleMenu(!isShowMenu)
  };

  const onClickOutsideHandler = (event) => {
    if (isShowMenu && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      toggleMenu(false);
    }
  };

  const onClickItem = (id) => {
    if (id === 1) {
      setPrimaryPaymentId();
    } else if (id === 2) {
      setDeletePaymentId();
    }
    toggleMenu(false);

  };

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  });

  return (
    <div ref={toggleContainer}>
      <SelectedOption onClick={() => onClickHandler()}>
        {isPrimary === true && 'PRIMARY'}
        <DownIcon className='fa fa-angle-down' />
      </SelectedOption>
      {
        isShowMenu &&
        <DropdownContainer>
          <Item><span onClick={() => onClickItem(1)}>Make Primary</span></Item>
          <Item><span onClick={() => onClickItem(2)}>Delete</span></Item>
        </DropdownContainer>
      }
    </div>
  )
};

export default PaymentCard;
