import React, {useEffect} from "react";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import {STORE_KEYS} from '@/stores';
import ArticleCard from "../../../components/dashboard/Education/ArticleCard";
import HelpCenter from "../../../components/dashboard/Education/HelpCenter";
import {Content, Right, Center, SubPanel, Button, SubTitle} from "../styles";

const AllEducation = ({ guidesData, getGuidesData, getKnowledgeData, knowledgeData }) => {
  useEffect(() => {
    getKnowledgeData();
    getGuidesData();
  }, [])

  let popularKnowArticle = [];
  if ((knowledgeData || []).length > 0) {
    (knowledgeData || []).map((item, index) => {
      popularKnowArticle.push({
        title: item.name || '',
        img: item.education_logo || '',
        type: item.category[0],
        readNum: item.education_duration,
        link: item.permalink || '',
      })
    });
  }

  let integrations = [];
  if ((guidesData || []).length > 0) {
    (guidesData || []).map((item, index) => {
      integrations.push({
        title: item.name || '',
        img: item.logo || '',
        type: item.category[0],
        readNum: '',
        link: item.permalink || '',
      })
    });
  };

  return (
    <Content>
      <Center>
        <div className={'wrapper'}>
          <SubPanel className="mb-5">
            <div className={'title'}>
              Knowledge Articles
            </div>
            <div className={'row'}>
              {
                popularKnowArticle.map((article, index) => {
                  let clsName = "col-lg-4 mb-4";
                  return (
                      <div className={clsName} key={index}>
                        <ArticleCard article={article} size='sm'/>
                      </div>
                  )
                })
              }
            </div>
            <div>
              <Button>More Articles</Button>
            </div>
          </SubPanel>
          <SubPanel>
            <div className={'title'}>
              Integration Guides
            </div>
            <div className={'row'}>
              {
                integrations.map((article, index) => {
                  return (
                      <div className={'col-xl-3 col-sm-6 mb-4'} key={index}>
                        <ArticleCard article={article} size='md' />
                      </div>
                  )
                })
              }
            </div>
            <div>
              <Button>More Guides</Button>
            </div>
          </SubPanel>
        </div>
      </Center>
      <Right>
        <div className={'wrapper'}>
          <SubTitle>Help Centers</SubTitle>
          <p>Connect with our partner product help centers</p>
          <HelpCenter />
        </div>
      </Right>
    </Content>
  )
}
export default compose(
    inject(STORE_KEYS.KNOWLEDGESTORE),
    observer,
    withProps(
        ({
           [STORE_KEYS.KNOWLEDGESTORE]: {guidesData, getGuidesData, getKnowledgeData, knowledgeData},
         }) => ({
          guidesData,
          getGuidesData,
          getKnowledgeData,
          knowledgeData,
        })
    )
)(AllEducation);
