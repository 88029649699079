import React from "react";
import {productColors} from "../../../../constants";
import styled from "styled-components";

export default () => {

  const Wrapper = styled.div`
    width: 100%;
  `;

  const Item = styled.a`
    width: 100%;
    min-height: 80px;
    padding: 20px;
    display: flex;
    align-items: center;
    background: ${props => props.bgColor || '#fff'};
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    cursor: pointer;
    
    &:hover {
      background: ${props => props.hoverColor || '#fff'};
      
      h3 {
        color: #fff;
      }
    }
    
    img {
      width: 35px;
      height: 35px;
      min-width: 35px;
      margin-right: 15px;
    }
    
    h3 {
      font-size: 16px;
      color: #435d86;
    }
    
  `;

  const data = [
    {
      name: 'Xero Central',
      icon: '/images/Solutions/Product_Xero.svg',
      bgColor: '#f3fbfe',
      hoverColor: productColors['xero'],
      url: 'https://central.xero.com/s/topiccatalog'
    },
    {
      name: 'ReceiptBank',
      icon: '/images/Solutions/Product_ReceiptBank.svg',
      bgColor: '#fef7f3',
      hoverColor: productColors['receiptbank'],
      url: 'https://help.receipt-bank.com/hc/en-us'
    },
    {
      name: 'Fathom',
      icon: '/images/Solutions/Product_Fathom.svg',
      bgColor: '#f5fbf6',
      hoverColor: productColors['fathom'],
      url: 'https://www.fathomhq.com/support'
    },
    {
      name: 'Vend',
      icon: '/images/Solutions/Product_Vend.svg',
      bgColor: '#f5fbf6',
      hoverColor: productColors['vend'],
      url: 'https://support.vendhq.com/hc/en-us'
    },
    {
      name: 'ApprovalMax',
      icon: '/images/Solutions/Product_Approval.svg',
      bgColor: '#f6f9f7',
      hoverColor: productColors['approval'],
      url: 'https://support.approvalmax.com/portal/en-gb/kb/approvalmax-1'
    },
    {
      name: 'Shopify',
      icon: '/images/Solutions/Product_Shopify.svg',
      bgColor: '#f9fcf6',
      hoverColor: productColors['shopify'],
      url: 'https://help.shopify.com/en'
    }
  ];

  return (
    <Wrapper>
      {
        data.map((item, index) => (
          <Item bgColor={item.bgColor} hoverColor={item.hoverColor} key={index} href={item.url} target="_blank">
            <img src={item.icon} alt="Help Center Image" />
            <h3>{item.name}</h3>
          </Item>
        ))
      }
    </Wrapper>
  );
}
