import React, {useEffect} from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import { STORE_KEYS } from '@/stores';

//components
import TeamCard from "@/containers/MySolutions/TeamCard";
import SolutionPanel from "@/containers/MySolutions/SolutionPanel";

//styles
import { SubPanel, Center } from './styles';
import { Container, SubHeader, Title, Content } from "../styles";

const MyCertifications = ({
  myCerts, requestMyCertification,
  onboarding_avatar, onboarding_Company, onboarding_email, onboarding_calendly,
  education_avatar, education_Company, education_Email, education_Calendly,
  accounting_avatar, accountant_Name, accountant_email, accounting_calendly,
  billing_avatar, billing_Name, billing_email, billing_calendly,
}) => {
  const teamData = [
    {
      title: 'ONBOARDING',
      name: onboarding_Company,
      profile_img: onboarding_avatar || '/images/dashboard/team_member_03.png',
      email: onboarding_email || '',
      calendly: onboarding_calendly || '',
      desc: 'Team member will be assigned when you order an onboarding service.',
      bgImage: '/images/Solutions/Service_Accounting.svg'
    },
    {
      title: 'EDUCATION',
      name: education_Company,
      profile_img: education_avatar || '/images/dashboard/team_member_02.png',
      email: education_Email || '',
      calendly: education_Calendly || '',
      desc: 'Team member will be assigned when you order an education service.',
      bgImage: '/images/dashboard/solutions/img_services_002.png'
    },
    {
      title: 'SUPPORT',
      name: accountant_Name,
      profile_img: accounting_avatar || '',
      email: accountant_email || '',
      calendly: accounting_calendly || '',
      desc: 'Team member will be assigned when you order an support service.',
      bgImage: '/images/dashboard/solutions/img_location.png'
    },
    {
      title: 'BILLING',
      name: billing_Name || '',
      profile_img: billing_avatar || '',
      email: billing_email || '',
      calendly: billing_calendly || '',
      desc: 'Team member will be assigned when you order an billing service.',
      bgImage: '/images/dashboard/solutions/img_services_005.png'
    }
  ];

  useEffect(() => {
    requestMyCertification();
  }, [])

  return (
    <>
      <Container>
        <SubHeader>
          <Title>My Certifications</Title>
        </SubHeader>
        <Content>
          <Center>
            <div className={'wrapper'}>
              <SubPanel className="mb-3">
                <div className={'title'}>My Team</div>
                <div className={'content'}>
                  <div className="row">
                    {
                      teamData.map((item, index) => (
                        <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 mb-4" key={index}>
                          <TeamCard data={item} />
                        </div>
                      ))
                    }
                  </div>
                </div>
              </SubPanel>
              <SubPanel>
                <div className={'title'}>My Certifications</div>
                <div className={'content'}>
                  <SolutionPanel data={myCerts} />
                </div>
              </SubPanel>
            </div>
          </Center>
        </Content>
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE, STORE_KEYS.CERTIFICATIONSTORE, STORE_KEYS.MYSOLUTIONSSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode, viewMode },
       [STORE_KEYS.CERTIFICATIONSTORE]: { myCerts, requestMyCertification },
       [STORE_KEYS.MYSOLUTIONSSTORE]: {
         onboarding_avatar,
         onboarding_Company,
         onboarding_email,
         onboarding_calendly,
         education_avatar,
         education_Company,
         education_Email,
         education_Calendly,
         accounting_avatar,
         accountant_Name,
         accountant_email,
         accounting_calendly,
         billing_avatar,
         billing_Name,
         billing_email,
         billing_calendly,
       },
     }) => ({
      setViewMode,
      viewMode,
      myCerts,
      requestMyCertification,
      onboarding_avatar,
      onboarding_Company,
      onboarding_email,
      onboarding_calendly,
      education_avatar,
      education_Company,
      education_Email,
      education_Calendly,
      accounting_avatar,
      accountant_Name,
      accountant_email,
      accounting_calendly,
      billing_avatar,
      billing_Name,
      billing_email,
      billing_calendly,
    })
  )
)(MyCertifications);
