import React from 'react';

import {
  Container,
  Title,
  SubHeader,
  TabItem,
  Tabs,
  FooterWrapper
} from '../styles';

const Header = (props) => {
  const { item } = props;
  const tabItems = [
    {
      id: 2,
      name: 'Billing',
      url: '/billing'
    },
    {
      id: 3,
      name: 'Profile',
      url: '/profile'
    }
  ];

  return (
    <SubHeader>
      <Title>Settings</Title>
      <Tabs>
        {
          tabItems.map(itemData => {
            return (
              <TabItem
                key={itemData.id}
                active={itemData.url === `/${item}` ? 'active' : ''}
                to={itemData.url}
              >
                {itemData.name}
              </TabItem>
            )
          })
        }
      </Tabs>
    </SubHeader>
  )
}

export default Header;
