import React from "react";
import styled from "styled-components";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

//components
import LoginTitle from "../../components/login/login-title";
import Input from "../../components/common/Input";
import Button from "../../components/common/button";
import SignUpStatusBar from "../../components/common/SignUpStatusBar";

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Login = styled.div`
  flex: 1;
  display: flex;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const LoginForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;
  background-color: #fff;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const LoginFormContent = styled.div`
  max-width: 480px;
  width: 100%;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const FormBody = styled.div`
  max-width: 400px;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  > div {
    margin-top: 20px;
  }
`

const InputName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  > div {
    width: 47%
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    > div {
      width: 100%;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
`

const LoginBtnGroup = styled.div`
  display: flex;
  margin-top: 30px;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const PhoneField = styled.div`
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background-color: rgba(65, 92, 133, 0.05);
  box-sizing: border-box;

  font-family: Circe-Regular;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #181e26;

  outline: none;
  border: 0;
  padding: 14px 15px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }

  @media (max-width: 576px) {
    min-height: unset;
    padding: 10px 15px;
  }
  .PhoneInputInput {
    border: none;
    background-color: unset;
    outline:none;
  }
`;

const SignUpPage = ({ setMode, showSnackMsg }) => {

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');

  const onChangeFirstName = (e) => {
    setFirstName(e.target.value || '');
  };

  const onChangeLastName = (e) => {
    setLastName(e.target.value || '');
  };

  const onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value || '');
  };

  const onClickNextBtn = () => {
    if ((firstName || '').trim().length === 0 || (lastName || '').trim().length === 0 || (phoneNumber || '').trim().length === 0) {
      showSnackMsg('Please input personal information correctly.');
      return;
    }
    setMode(2, firstName, lastName, phoneNumber);
  };

  return (
      <Wrapper>
        <Login>
          <Brand>
            <img src="/images/login/img_msg_sent.png" alt=""/>
          </Brand>
          <LoginForm>
            <LoginFormContent>
              <LoginTitle title={'Create Account'}/>
              <FormBody>
                <SignUpStatusBar step={2}/>
                <InputName>
                  <Input label={'First Name'} type={'text'} placeholder={'First Name'} value={firstName} onChange={onChangeFirstName}/>
                  <Input label={'Last Name'} type={'text'} placeholder={'Last Name'} value={lastName} onChange={onChangeLastName}/>
                </InputName>
                <PhoneField>
                  <PhoneInput
                      international
                      placeholder={'Phone'}
                      value={phoneNumber}
                      onChange={setPhoneNumber}/>
                </PhoneField>
              </FormBody>
              <LoginBtnGroup>
                <Button label={'Next'} onClick={onClickNextBtn} />
              </LoginBtnGroup>
            </LoginFormContent>
          </LoginForm>
        </Login>
      </Wrapper>
  )
}

export default SignUpPage;
