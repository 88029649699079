import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

//components
import Clients from "./Clients";
import Services from "./Services";

//styles
import {
  Container, SubHeader, Title, TabItem, Tabs,
} from "../styles";

import { STORE_KEYS } from '@/stores';

const ClientPage = (props) => {

  const {
    clientViewMode: mode,
    setClientViewMode,
    cloudmebServicesForClient,
    requestCloudmebServiceForClients,
    clientsForPartner,
    requestClientsForPartner,
    sizeOfServicesForPartner,
    sizeOfClientsForPartner,
  } = props;

  React.useEffect(() => {
    requestCloudmebServiceForClients();
    requestClientsForPartner();
  }, []);

  const tabItems = [
    {
      id: 1,
      name: 'Clients',
      viewMode: 'client_clients',
      count: sizeOfClientsForPartner || 0,
    },
    {
      id: 2,
      name: 'Services',
      viewMode: 'client_services',
      count: sizeOfServicesForPartner || 0,
    }
  ];

  const onChangeView = (id) => {
    setClientViewMode(id);
  };

  let Content = <></>;

  switch(mode) {
    case 'client_clients':
      Content = <Clients data={clientsForPartner} />;
      break;
    case 'client_services':
      Content = <Services data={cloudmebServicesForClient} />;
      break;
  }

  return (
    <>
      <Container>
        <SubHeader>
          <Title>Clients</Title>
          <Tabs>
            {
              tabItems.map(item => {
                return (
                  <TabItem
                    key={item.id}
                    active={item.viewMode === mode ? 'active' : ''}
                    onClick={() => onChangeView(item.viewMode)}
                  >
                    {item.name}
                    {
                      item.count > 0 &&
                      <span>{item.count}</span>
                    }
                  </TabItem>
                )
              })
            }
          </Tabs>
        </SubHeader>
        { Content }
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE, STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { clientViewMode, setClientViewMode },
       [STORE_KEYS.SALESFORCESTORE]: {
         requestCloudmebServiceForClients,
         cloudmebServicesForClient,
         requestClientsForPartner,
         clientsForPartner,
         sizeOfServicesForPartner,
         sizeOfClientsForPartner,
       },
     }) => ({
      clientViewMode,
      setClientViewMode,
      cloudmebServicesForClient,
      requestCloudmebServiceForClients,
      clientsForPartner,
      requestClientsForPartner,
      sizeOfServicesForPartner,
      sizeOfClientsForPartner,
    })
  )
)(ClientPage);
