import React from 'react';

import {
  Wrapper, Label, MLabel
} from './styles';

export default () => (
  <Wrapper>
    <Label>Coming soon!</Label>
  </Wrapper>
);
