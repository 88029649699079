import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
//components
import ChecklistBlock from "./ChecklistBlock";
import StatusPanel from "@/containers/StatusPanel";

//styles
import {
  Container, Title, Content, Left, Center
} from './styles';
import { SubHeader, TabItem, Tabs } from "./styles";

const Checklist = (props) => {
  const [selectedTabId, setSelectedTabId] = React.useState('Todo');
  const {
    tasks, lengthOfMyTasks, lengthOfTeamTasks, markTaskAsComplete, requestCloudTasks, tabItems,
  } = props;

  return (
    <>
      <Container>
        <SubHeader>
          <Title>Checklists</Title>
          <Tabs>
            {
              tabItems.map(item => {
                return (
                  selectedTabId === item.name ?
                    <TabItem active onClick={() => setSelectedTabId(item.name)} key={item.name}>{item.name} <span>{item.count}</span></TabItem>
                    : <TabItem onClick={() => setSelectedTabId(item.name)} key={item.name}>{item.name} <span>{item.count}</span></TabItem>
                )
              })
            }
          </Tabs>
        </SubHeader>
        <Content>
          <Center>
            <div className={'wrapper'} id="center">
              <ChecklistBlock
                tasks={tasks}
                lengthOfMyTasks={lengthOfMyTasks}
                lengthOfTeamTasks={lengthOfTeamTasks}
                markTaskAsComplete={markTaskAsComplete}
                requestCloudTasks={requestCloudTasks}
                selectedTabId={selectedTabId}
              />
            </div>
          </Center>
          <Left>
            <StatusPanel/>
          </Left>
        </Content>
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.CHECKLISTSTORE]: {
         tasks,
         lengthOfMyTasks,
         lengthOfTeamTasks,
         markTaskAsComplete,
         requestCloudTasks,
         tabItems,
       },
     }) => ({
      tasks,
      lengthOfMyTasks,
      lengthOfTeamTasks,
      markTaskAsComplete,
      requestCloudTasks,
      tabItems,
    })
  )
)(Checklist);
