import React from "react";
import styled from "styled-components";

//components
import LoginTitle from "../../components/login/login-title";
import Input from "../../components/common/Input";
import Button from "../../components/common/button";
import SignUpStatusBar from "../../components/common/SignUpStatusBar";
import Select from "../../components/common/Select";


//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Login = styled.div`
  flex: 1;
  display: flex;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const LoginForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 30px 0;
  background-color: #fff;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const LoginFormContent = styled.div`
  max-width: 480px;
  width: 100%;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const FormBody = styled.div`
  max-width: 400px;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  > div {
    margin-top: 20px;
  }
`

const BusinessTypeAndEmployees = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  > div {
    width: 47%
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    > div {
      width: 100%;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  
  > div {
    width: 47%
  }
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    > div {
      width: 100%;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

const LoginBtnGroup = styled.div`
  display: flex;
  margin-top: 30px;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const StyledArrowIcon = styled.i`
    margin-right: 10px;
 `;

const SignUpPage = ({ setMode, showSnackMsg, regions }) => {
  const [companyName, setCompanyName] = React.useState('');
  const [selectedEmployeeId, setSelectedEmployeeId] = React.useState('');
  const [selectedBusinessTypeId, setSelectedBusinessTypeId] = React.useState('');
  const [selectedCountryId, setSelectedCountryId] = React.useState('');
  const [province, setProvince] = React.useState('');

  const arrowIcon = <StyledArrowIcon className='fa fa-angle-left'/>;

  const employeeOptions = [
    {
      'id': 1, 'name': '5'
    },
    {
      'id': 2, 'name': '10'
    },
    {
      'id': 3, 'name': '15'
    },
  ];

  const businessTypeOptions = [
    {
      'id': 'Registered', 'name': 'Registered'
    },
    {
      'id': 'Partnership', 'name': 'Partnership'
    },
    {
      'id': 'Incorporated', 'name': 'Incorporated'
    },
    {
      'id': 'Freelancer', 'name': 'Freelancer'
    },
    {
      'id': 'Non-Profit', 'name': 'Non-Profit'
    },
  ];

  const onChangeCompanyName = (e) => {
    setCompanyName(e.target.value || '');
  };

  const onClickRegisterBtn = () => {
    if ((companyName || '').trim().length === 0) {
        showSnackMsg('Company Name is required.');
        return;
    }
    if ((selectedBusinessTypeId || '').trim().length === 0) {
      showSnackMsg('Business Type is required.');
      return;
    }
    if ((selectedCountryId || '').trim().length === 0) {
      showSnackMsg('Country is required.');
      return;
    }
    setMode(3, companyName, selectedBusinessTypeId, selectedCountryId);
  };

  return (
    <Wrapper>
      <Login>
        <Brand>
          <img src="/images/login/img_msg_sent.png" alt=""/>
        </Brand>
        <LoginForm>
          <LoginFormContent>
            <LoginTitle title={'Create Account'}/>
            <FormBody>
              <SignUpStatusBar step={3}/>
              <Input
                label={'Company Name'}
                type={'text'}
                placeholder={'Company Name'}
                onChange={onChangeCompanyName}
                value={companyName}
              />
              <BusinessTypeAndEmployees>
                <Select
                  name="transactions"
                  label="Business Type"
                  selectedOptionId={selectedBusinessTypeId}
                  setOption={setSelectedBusinessTypeId}
                  options={businessTypeOptions}
                  placeholder="Select"
                />
                <Select
                  name="employee"
                  icon={arrowIcon}
                  label="Employees"
                  selectedOptionId={selectedEmployeeId}
                  setOption={setSelectedEmployeeId}
                  options={employeeOptions}
                  placeholder="Select"
                />
              </BusinessTypeAndEmployees>
              <Address>
                <Select
                  name="countries"
                  label="Country"
                  selectedOptionId={selectedCountryId}
                  setOption={setSelectedCountryId}
                  options={regions}
                  placeholder="Select"
                />
                <Input label={`Province/State`} type={`text`} placeholder={'State'} onChange={setProvince} />
              </Address>
            </FormBody>
            <LoginBtnGroup>
              <Button label={'Next'} onClick={onClickRegisterBtn} />
            </LoginBtnGroup>
          </LoginFormContent>
        </LoginForm>
      </Login>
    </Wrapper>
  )
}

export default SignUpPage;
