import React from "react";

//components
import ClientServiceList from "../ClientServiceList";

//styles
import { Wrapper, Header, Body } from "./styles";

const ClientServicePanel = ({data = {}, title}) => {

  const [showAllItems, setShowAllItems] = React.useState(true);

  return (
    <Wrapper>
      <Header onClick={ () => setShowAllItems(!showAllItems)}>
        <div className={'title'}>
          <h3>{data.title}<span>{data.count}</span></h3>
        </div>
        <div className={'total-price'}>
          <p>{data.title === 'Recurring' && 'Monthly Revenue' || data.title === 'One Time' && 'All Time Revenue'}</p>
          <h3 className={'price-num'}>${data.total_revenue} <p>{data.currency} { data.title !== 'One Time' && <span>/MO</span>}</p></h3>
        </div>
        {
          showAllItems?
          <i className="fa fa-angle-up"/>
          :
          <i className="fa fa-angle-down"/>
        }
      </Header>
      {
        showAllItems &&
        <Body>
          {
            (data.services || []).map((item, index) => (
              <ClientServiceList data={item} key={index} />
            ))
          }
        </Body>
      }

    </Wrapper>
  )
};

export default ClientServicePanel;
