import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import { Link } from "react-router-dom";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import { STORE_KEYS } from '@/stores';

const UserSettingMenu = (props) => {

  const [ isShowMenu, toggleMenu ] = useState(false);

  const toggleContainer = React.createRef();

  const onClickHandler = () => {
    toggleMenu(!isShowMenu)
  }

  const onClickOutsideHandler = (event) => {
    if (isShowMenu && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      toggleMenu(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  })

  const onChangeView = (id) => {
    const { setViewMode } = props;
    setViewMode(id);
    onClickHandler();
  };

  const menu = {
    my: [
      {
        label: 'My Certifications',
        link: '/my-certifications'
      }
    ],
    settings: [
      {
        label: 'Billing',
        link: '/billing',
        viewMode: 'settings_billing'
      },
      {
        label: 'Profile',
        link: '/profile',
        viewMode: 'settings_profile'
      },
      {
        label: 'Logout',
        link: '/'
      },
    ]
  }

  return (
    <Wrapper ref={toggleContainer}>
      <UserInfo onClick={() => onClickHandler()} >
        <Photo src={props.photo}/>
        <p>{props.name}
          {
            isShowMenu ?
              <i className="fa fa-angle-up ml-1" />
            :
              <i className="fa fa-angle-down ml-1" />
          }
        </p>

      </UserInfo>
      {
        isShowMenu &&
        <Menu>
          <SubMenu top>
            {
              menu.my.map((item, index) => (
                <Item
                  key={index}
                  to={item.link}
                  onClick={() => onClickHandler()}
                >
                  {item.label}
                </Item>
              ))
            }
          </SubMenu>
          <SubMenu>
            <Title>SETTINGS</Title>
            {
              menu.settings.map((item, index) => (
                  <Item
                      key={index}
                      to={item.link}
                      onClick={() => {
                        if (item.label === 'Logout') {
                          localStorage.clear();
                          window.location.reload();
                        }
                        onChangeView(item.viewMode)
                      }}
                  >{item.label}</Item>
              ))
            }
          </SubMenu>
        </Menu>
      }
    </Wrapper>
  )
}

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode, viewMode },
     }) => ({
      setViewMode,
      viewMode,
    })
  )
)(UserSettingMenu);

//styles
const Wrapper = styled.div`
 @media (max-width: ${props => props.theme.breakpoints.sm}) {
   display: none;
 }
`;

const Menu = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
  top: 40px;
  padding: 5px 0;
  right: 10px;
  z-index: 100;
`

const Title = styled.p`
  color: #74787d;
  padding: 5px 15px;
  font-size: 10px !important;
`

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  
  ${props => props.top && css`
    border-bottom: 1px solid #ebeef2;
    padding-bottom: 10px;
    margin-bottom: 10px;
  `}
`;

const Item = styled(Link)`
  color: #4b648b;
  text-decoration: none;
  font-family: Circe-Regular;
  font-size: 14px;
  padding: 7px 15px;
  position: relative;
  
  &:hover {
    background-color: #f7fdfd;
    color: #1e242b;
  }
  
  .fa-long-arrow-alt-right {
    transform: rotate(-45deg);
    font-size: 12px;
    color: #97a3b7;
    margin-left: 6px;
  }
`;

const Icon = styled.i`
  transform: rotateZ(45deg);
  font-size: 10px;
  margin: 0 5px;
  color: #97a3b7;
`

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
  
  p {
    font-size: 12px;
    font-family: Circe-Bold;
    margin: 0;
    line-height: 2;
    letter-spacing: 1px;
    
    i {
      color: #a9b5c8;
    }
  }
  
  @media (max-width: 1100px) and (min-width: 992px) {
    margin-left: 10px;
  }
`;

const Photo = styled.img`
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  border: 2px solid #fff;
`
