import React, {Fragment, useEffect} from "react";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import { STORE_KEYS } from '@/stores';
import {
  SvgChecklistIcon, SvgEducationIcon, SvgSolutionsIcon, SvgTargetIcon,
  SvgLogoIcon, SvgCertificationIcon, SvgClientIcon,
} from "@/components/SvgIcon";

const MobileMenu = (props) => {
  const { isShow, showToggle, data, activeNo, businessName, userInfo } = props;

  const mobileMenuList = [
    {
      id: 1,
      icon: <SvgLogoIcon/>,
      label: 'Dashboard',
      link: '/'
    },
    {
      id: 2,
      icon: <SvgTargetIcon/>,
      label: 'Targets',
      link: '/targets'
    },
    {
      id: 3,
      icon: <SvgChecklistIcon/>,
      label: 'Checklists',
      link: '/checklist'
    },
    {
      id: 4,
      icon: <SvgEducationIcon/>,
      label: 'Education',
      link: '/education'
    },
    {
      id: 5,
      icon: <SvgCertificationIcon/>,
      label: 'Certification',
      link: '/certification'
    },
    {
      id: 6,
      icon: <SvgClientIcon/>,
      label: 'Clients',
      link: '/clients'
    },
  ];

  const settingsMenuList = [
    {
      label: 'Billing',
      link: '/billing',
      viewMode: 'settings_billing'
    },
    {
      label: 'Profile',
      link: '/profile',
      viewMode: 'settings_profile'
    },
  ]

  const Container = styled.div`
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0px;
    background: #66478f;
    z-index: 99;
    
    ${props => props.isShow !== true && css`
      display: none;
    `}
    
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      display: none;
    }
  `;

  const MenuList = styled.div`
    border-top: 1px solid #546c91;
    padding: 30px 40px;
  `;

  const MenuItem = styled(Link)`
    width: 100%;
    height: 45px;
    display: flex;
    border-radius: 5px;
    
    ${props => props.active && css`
      background-color: #546c91;
    `}
    
    p {
      font-size: 18px;
      color: white;
      margin-left: 5px;
    }
  `;

  const MenuIcon = styled.div`
    height: 100%;
    width: 45px
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const SettingPanel = styled.div`
    border-top: 1px solid #546c91;
    padding: 30px 40px 30px 52px;
    
    span {
      font-size: 12px;
      color: #95a4ba;
    }
  `;

  const UserInfo = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    
    img {
      border-radius: 100%;
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
    
    p {
      font-size: 12px;
      font-family: Circe-Bold;
      margin: 0;
      line-height: 2;
      letter-spacing: 1px;
    }
  `;

  const LogoutBtn = styled.button`
    cursor: pointer;
    display: inline-block;
    color: #95a4ba;
    font-size: 14px !important;
    margin-left: 18px !important;
    background: transparent;
    border: 0;
  `;

  const SettingItem = styled(Link)`
    color: white;
    font-size: 18px;
    cursor: pointer;
    font-family: Circe-Regular;
    margin-right: 20px;
    
    &:hover {
      color: white;
    }
  `;

  const toggleContainer = React.createRef();

  const onClickOutsideHandler = (event) => {
    if (isShow && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      showToggle(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  })

  const onChangeView = (id) => {
    const { setViewMode } = props;
    setViewMode(id);
    props.showToggle(false);
  };

  return (
    <Container isShow={isShow} ref={toggleContainer}>
      <MenuList>
        {
          mobileMenuList.map(item => (
            <MenuItem to={item.link} key={item.id} active={item.id === activeNo} onClick={() => showToggle(false)}>
              <MenuIcon>
                {item.icon}
              </MenuIcon>
              <div className="d-flex align-items-center">
                <p>{item.label}</p>
              </div>
            </MenuItem>
          ))
        }
      </MenuList>
      <SettingPanel>
        <UserInfo>
          <img src={userInfo.photo || '/images/icons/Icon_User_Avatar.svg'}/>
          <p>{businessName}</p>
          <LogoutBtn onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}>
            Logout
          </LogoutBtn>
        </UserInfo>
        <div className="mb-3 mt-3">
          <SettingItem
            to={'/my-certifications'}
            onClick={() => showToggle(false)}
          >
            My Certifications
          </SettingItem>
        </div>
        <span>SETTINGS</span>
        <div className="d-flex mt-3 mb-3">
          {
            settingsMenuList.map((item, index) => (
              <SettingItem
                to={item.link}
                onClick={() => onChangeView(item.viewMode)}
                key={index}
              >
                {item.label}
              </SettingItem>
            ))
          }
        </div>
      </SettingPanel>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode, viewMode },
     }) => ({
      setViewMode,
      viewMode,
    })
  )
)(MobileMenu);
