import React from "react";
import styled, {css} from "styled-components";
import axios from "axios";

export default (props) => {

  const { picture, setPicture, isDisabled } = props;

  let fileInput = React.createRef();

  const onChange = (e) => {
    if ( e.target.files.length == 0 )
      return;

    const file = e.target.files[0];
    const acceptList = ["image/png", "image/jpeg", "image/bmp", "image/gif"];

    if ( acceptList.indexOf(file.type) == -1 )
      return;

    let reader  = new FileReader();

    reader.addEventListener("load", () => {
      const formData = new FormData();
      formData.append("file", file);
      axios.post('https://devpartner.cloudmeb.com/data/auth/upload_image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((ret) => {
          setPicture(`https://devpartner.cloudmeb.com${ret.data.path}`);
        })
        .catch((err) => {
          console.log(err);
        })
    });

    reader.readAsDataURL(file);
  };

  const onRemove = () => {
    setPicture(null);

    fileInput.value = null;

    // if ( props.onRemove )
    //   props.onRemove(props.id);
  };

  const shape = props.shape;

  return (
    <Container>
      <Wrapper shape={shape}>
        <ImageWrapper>
          <input type='file' onChange={onChange}
                 ref={(el) =>  fileInput = el} disabled={isDisabled} />
          {
            picture
              ?
              <img className="picture" src={picture} alt="User Img"/>
              :
              <img className="picture default" src="/images/dashboard/upload_img.png" alt="User Img"/>
          }

        </ImageWrapper>
        <BtnGroup>
          {
            picture
              ?
              <CloseBtn handleRemove={onRemove}/>
              :
              null
          }

        </BtnGroup>
      </Wrapper>
    </Container>
  )
};

const CloseBtn = (props) => {
  const Wrapper = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
  `;

  const CloseIcon = styled.div`
    background-color: transparent;
    border: 0;
    height: 20px;
    cursor: pointer;
    
    &:hover {
        outline: none;
    }
  
    .menu-icon{
      background: #97a3b7;
      width: 13px;
      height: 2px;
      margin-top: 8px;
      display: block;
      
      &:first-child {
          transform: rotate(135deg);
      }
      &:last-child {
          transform: rotate(225deg);
          margin-top: -2px;
      }
    }
  `;

  return (
    <Wrapper>
      <CloseIcon onClick={props.handleRemove}>
        <span className={'menu-icon'}/>
        <span className={'menu-icon'}/>
      </CloseIcon>
    </Wrapper>
  )
};

const Container = styled.div`
  display: inline-block;
`;

const Wrapper = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 50%;
  padding: 8px;
  position: relative;
  
  > div {
    border-radius: 50%;
  }
  
  ${props => props.shape === 'rect' && css`
    border-radius: 18px;

    > div {
      border-radius: 18px;
    }
  `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: $gray-100;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  input[type='file'] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    margin: 0;
  }

  .picture {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .picture.default {
    width: 100%;
    height: 100%;
  }
`;

const BtnGroup = styled.div`
  position: absolute;
  z-index: 10;
  right: 17px;
  bottom: 8px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16)
`;
