import React, {Component, useEffect} from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';

import {
  Container,
  Title,
  SubHeader,
  TabItem, Tabs
} from '../styles';

import AllSolutions from "./all";
import Products from "./products";
import Services from "./services";
import Templates from "./templates";

const Solution = (props) => {

  const {
    requestCloudProducts,
    requestCloudServices,
    requestXeroTemplates,
    solutionsMode,
    setSolutionsMode,
    countOfProducts,
    countOfServices,
    cloudProducts,
    cloudSpecialProducts,
    cloudServices,
    cloudSpecialServices,
    xeroTemplates,
    countOfTemplates,
  } = props;

  useEffect(() => {
    requestCloudProducts();
    requestCloudServices();
    requestXeroTemplates();
  }, [])

  const tabItems = [
    {
      id: 1,
      name: 'All',
      viewMode: 'solution_all',
      count: Number(countOfProducts) + Number(countOfServices) + Number(countOfTemplates)
    },
    {
      id: 2,
      name: 'Products',
      viewMode: 'solution_product',
      count: countOfProducts,
    },
    {
      id: 3,
      name: 'Services',
      viewMode: 'solution_service',
      count: countOfServices,
    },
    {
      id: 4,
      name: 'Templates',
      viewMode: 'solution_template',
      count: countOfTemplates,
    },
  ];

  const onChangeView = (id) => {
    setSolutionsMode(id);
  };
  let Content = <></>;

  switch(solutionsMode) {
    case 'solution_all':
      Content = <AllSolutions
        cloudSpecialProducts={cloudSpecialProducts}
        cloudProducts={cloudProducts}
        cloudSpecialServices={cloudSpecialServices}
        cloudServices={cloudServices}
        xeroTemplates={xeroTemplates}
      />;
      break;
    case 'solution_product':
      Content = <Products data={cloudProducts} spData={cloudSpecialProducts}/>;
      break;
    case 'solution_service':
      Content = <Services data={cloudServices} spData={cloudSpecialServices}/>;
      break;
    case 'solution_template':
      Content = <Templates data={xeroTemplates}/>;
      break;
    default:
      Content = <></>;
      break;
  }

  return (
    <>
      <Container>
        <SubHeader>
          <Title>Solutions</Title>
          <Tabs>
            {
              tabItems.map((item, index) => {
                return (
                  <TabItem
                    key={index}
                    active={item.viewMode === solutionsMode ? 'active' : ''}
                    onClick={() => onChangeView(item.viewMode)}
                  >
                    {item.name}
                    <span>{item.count}</span>
                  </TabItem>
                )
              })
            }
          </Tabs>
        </SubHeader>
        { Content }
      </Container>
    </>
  )
}

export default compose(
  inject(
    STORE_KEYS.VIEWMODESTORE, STORE_KEYS.SOLUTIONSSTORE
  ),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setSolutionsMode, solutionsMode },
       [STORE_KEYS.SOLUTIONSSTORE]: {
         requestCloudProducts,
         requestCloudServices,
         requestXeroTemplates,
         countOfProducts,
         cloudSpecialProducts,
         countOfServices,
         cloudSpecialServices,
         cloudProducts,
         cloudServices,
         xeroTemplates,
         countOfTemplates,
       },
     }) => ({
      requestCloudProducts,
      requestCloudServices,
      requestXeroTemplates,
      setSolutionsMode,
      solutionsMode,
      countOfProducts,
      cloudSpecialProducts,
      countOfServices,
      cloudSpecialServices,
      cloudProducts,
      cloudServices,
      xeroTemplates,
      countOfTemplates,
    })
  )
)(Solution);
