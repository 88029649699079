import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import Checkout from "@/components/Checkout";
import { STORE_KEYS } from '@/stores';
import { Wrapper } from './styles';
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";

class Contents extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (paymentMethod) => {
    console.log('paymentMethod$$$$:', paymentMethod);
    let last4 = '', brand = '';
    let exp_month = '', exp_year = '';
    try {
      last4 = paymentMethod.card.last4;
      brand = paymentMethod.card.brand;
      exp_month = paymentMethod.card.exp_month;
      exp_year = paymentMethod.card.exp_year;
    } catch (e) {}

    const { createCustomer, handleClose } = this.props;
    createCustomer(paymentMethod.id, last4, brand, exp_month, exp_year)
      .then(() => {
        handleClose();
      })
      .catch((err) => {
        handleClose();
      });
  };

  render() {
    return (
      <Wrapper>
        <h6>Payment details:</h6>
        <Checkout onSubmit={this.onSubmit} />
        <div className="notify">
          Your billing method will be charged for products and services.<br/>
          <div>
            Secure Payment Powered by <a href="https://stripe.com/" target="_blank">Stripe</a>
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default compose(
  inject(STORE_KEYS.SALESFORCESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.SALESFORCESTORE]: {
         createCustomer,
       },
     }) => ({
      createCustomer,
    })
  )
)(Contents);
