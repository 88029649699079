import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { Link } from 'react-router-dom';

//components
import DropdownMenu from "./dropdown-menu";

export default ({ icon, small_icon, label, count, link, dropdown, active }) => {

  const [isOpenDropdown, toggleDropDown ] = useState(false);

  const toggleContainer = React.createRef();

  const onClickHandler = () => {
    toggleDropDown(!isOpenDropdown)
   }

  const onClickOutsideHandler = (event) => {
    if (isOpenDropdown && toggleContainer.current && !toggleContainer.current.contains( event.target )) {
      toggleDropDown(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', onClickOutsideHandler);
  })

  return (
    <div ref={toggleContainer}>
      <Container active={active} >
        {
          !dropdown ?
            <Menu
              to={link}
            >
              {icon}
              {small_icon}
              <Label>
                {label}
                <span>{count}</span>
              </Label>
            </Menu>
          :
            <>
              <MenuButton
                onClick={() => onClickHandler()}
              >
                {icon}
                {small_icon}
                <Label>
                  {label}
                  <DownIcon className='fa fa-angle-down'>
                  </DownIcon>
                </Label>
              </MenuButton>
              {
                (isOpenDropdown) &&
                <DropdownMenu dropdownList={dropdown} onClick={() => onClickHandler()} />
              }
            </>
        }

      </Container>
    </div>
  );
}

//styles
const Container = styled.li`
  padding: 8px 20px 8px 8px;
  position: relative;
  
  @media (max-width: 1300px) and (min-width: 992px) {
    padding: 6px;
  }
  
  ${props => props.active && css`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  `}
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 10px;
  }
`

const Menu = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  
  :hover {
    color: white;
  }
`

const MenuButton = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  cursor: pointer;
`

const Icon = styled.img`
  width: 22px;
  height: 22px;
  
  @media (max-width: 1300px) and (min-width: 992px) {
    display: none;
  }
`;

const SmallIcon = styled.img`
  width: 18px;
  height: 18px;
  display: none;
  
  @media (max-width: 1300px) and (min-width: 992px) {
    display: block;
  }
`;

const Label = styled.p`
  margin-left: 12px;
  font-family: Circe-Bold;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 1px;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
  
  span {
    margin-left: 5px;
    font-size: 12px;
    color: #a9b5c8;
  }
`;

const DownIcon = styled.i`
  font-size: 12px;
  margin-left: 5px;
  color: #a9b5c8;
`;
