import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 0 20px #f5f5f5;
`;

export const Header = styled.div`
  min-height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  p {
    color: #97a4b7;
    font-size: 12px;
  }
  
  i {
    font-size: 24px;
    cursor: pointer;
    color: #8d9bb0;
  }
  
  .title {
    width: 30%;
    
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: unset;
      margin-right: 20px;
    }
    
     h3 {
      color: #181e26;
      font-size: 18px;
      
      span {
        margin-left: 5px;
        font-size: 12px;
        color: #97a4b7;
      }
    }
  }
  
  .total-price {
    flex: 1;
    
    h3 {
      color: #405c85;
      font-size: 16px;
    }
    
    p {
      color: #97a4b7;
      font-size: 12px;
    }
    
    width: 20%;
    
    .price-num {
      p {
        display: inline-block;
      }
      span {
        color: #c3cad6;
      }
    }
    
  }
`;

export const Body = styled.div`
  padding: 0 20px;
  border-top: 1px solid #f5f7f9;
`;


