import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import { STORE_KEYS } from '@/stores';
import { Wrapper } from "./styles";

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
        }
    }

    componentDidMount() {
        const { confirmMail } = this.props;
        const token = this.props.match.params.token;
        confirmMail(token)
            .then(data => {
                this.setRedirect(true);
            })
            .catch(err => {
                this.setRedirect(false);
            });
    }

    setRedirect = (mode) => {
        this.setState({
            redirect: mode,
        })
    };

    renderRedirect = () => {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/home' />
        }
    };

    render() {
        return (
            <Wrapper>
                {this.renderRedirect()}
                Thank you for your registration. Verifying your confirmation....
            </Wrapper>
        );
    }
}

export default compose(
    inject(STORE_KEYS.AUTHSTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.AUTHSTORE]: {
                 confirmMail,
             },
         }) => ({
            confirmMail,
        })
    )
)(ConfirmEmail);
