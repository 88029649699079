import React, {useEffect} from "react";
import {compose, withProps} from "recompose";
import {inject, observer} from "mobx-react";

import {STORE_KEYS} from '@/stores';
import ArticleCard from "../../../components/dashboard/Education/ArticleCard";
import HelpCenter from "../../../components/dashboard/Education/HelpCenter";
import {Content, Right, Center, SubPanel, WhiteButton, SubTitle} from "../styles";

const Knowledge = ({getKnowledgeData, knowledgeData}) => {

    useEffect(() => {
        getKnowledgeData();
    }, [])

    let popularArticle = [];
    (knowledgeData || []).map((item, index) => {
        popularArticle.push({
            title: item.name || '',
            img: item.education_logo || '',
            type: item.category[0],
            readNum: item.education_duration,
            link: item.permalink || '',
        })
    });

    return (
        <Content>
            <Center>
                <div className={'wrapper'}>
                    <SubPanel>
                        <div className={'title'}>
                            Knowledge Articles
                        </div>
                        <div className={'row'}>
                            {
                                popularArticle.map((article, index) => {
                                    let clsName = "col-lg-4 mb-4";
                                    return (
                                        <div className={clsName} key={index}>
                                            <ArticleCard article={article} size='sm'/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </SubPanel>
                    <div className="text-center">
                        <WhiteButton>Load More</WhiteButton>
                    </div>
                </div>
            </Center>
            <Right>
                <div className={'wrapper'}>
                    <SubTitle>Help Centers</SubTitle>
                    <p>Connect with our partner product help centers</p>
                    <HelpCenter/>
                </div>
            </Right>
        </Content>
    )
}

export default compose(
    inject(STORE_KEYS.KNOWLEDGESTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.KNOWLEDGESTORE]: {getKnowledgeData, knowledgeData},
         }) => ({
            getKnowledgeData,
            knowledgeData,
        })
    )
)(Knowledge);
