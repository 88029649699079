import React, {useState} from "react";
import moment from "moment";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import {
  Container,
  Wrapper,
  Header,
  Name,
  Body,
  Footer,
  Title,
  TextField,
  InputForm,
  SaveButton,
  CloseButton,
  CancelButton,
  DeleteButton,
  DatePicker
} from "./styles";
import {SvgCalendarIcon, SvgCloseIcon} from "../../SvgIcon";

const EditClientTaskDlg = ({
  open, handleClose, mTaskName, mDueDate, mLink, mObjectId, editActiveTask, deleteActiveTask,
}) => {

  const [taskName, setTaskName] = useState(mTaskName);
  const [dueDate, setDueDate] = useState(moment(mDueDate));
  const [link, setLink] = useState(mLink);

  const onSaveBtnClick = () => {
    editActiveTask(mObjectId, taskName, moment(dueDate).format('YYYY-M-D'), link)
      .then(() => {
        handleClose();
      });
  };

  const onDeleteTask = () => {
    deleteActiveTask(mObjectId)
      .then(() => {
        handleClose();
      });
  };

  return (
    <Container isShow={open}>
      <Wrapper>
        <Header>
          <Title>EDIT CLIENT TASK</Title>
          <Name>
            <img src={"/images/dashboard/people1.png"} alt="img"/>
            <h3>Target Name</h3>
          </Name>
          <CloseButton onClick={handleClose}><SvgCloseIcon/></CloseButton>
        </Header>
        <Body>
          <InputForm>
            <TextField
              id="taskName"
              label="Task Name"
              variant="outlined"
              value={taskName}
              autoFocus
              onChange={(e) => setTaskName(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
            <div className="custom-datepicker">
              <DatePicker
                label="Due Date"
                value={dueDate}
                format={'d MMMM yyyy'}
                onChange={(date) => setDueDate(date)}
                InputLabelProps={{
                  shrink: true
                }}
                inputVariant="outlined"
              />
              <SvgCalendarIcon/>
            </div>
            <TextField
              id="link"
              label="Link (optional)"
              variant="outlined"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </InputForm>
        </Body>
        <Footer>
          <DeleteButton onClick={onDeleteTask}>Delete</DeleteButton>
          <div>
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <SaveButton
              className="ml-3"
              onClick={onSaveBtnClick}
            >Save</SaveButton>
          </div>
        </Footer>
      </Wrapper>
    </Container>
  )
};

export default compose(
  inject(STORE_KEYS.CHECKLISTSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.CHECKLISTSTORE]: {
         editActiveTask,
         deleteActiveTask,
       },
     }) => ({
      editActiveTask,
      deleteActiveTask,
    })
  )
)(EditClientTaskDlg);
