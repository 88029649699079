import React from "react";
import styled from "styled-components";
import {CommonLink} from "@/components/common/button";
import QuickLinkItem from "@/containers/Dashboard/QuickLinkItem";

const QuickLink = ({ data }) => {
  const Wrapper = styled.div`
    width: 100%;
  `;

  const NoLinks = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #f7fdfd;
    overflow: hidden;
    padding: 20px;
    
    p {
      text-align: center;
    }
  `;

  const Button = styled(CommonLink)`
    margin-top: 30px;
    width: 100%;
    color: white;
    padding: 10px 20px;
  `;

  return (
    <Wrapper>
      {
        (data || []).length <= 0 ?
          <NoLinks>
            <p>Links will appear when you order any product.</p>
            <Button href="/solution">Explore</Button>
          </NoLinks>
          :
          <>
            <p className="mb-2">Login to your solutions.</p>
            {
              data.map((item, index) => (
                <QuickLinkItem data={item} key={index} />
              ))
            }
          </>
      }
    </Wrapper>
  )

};

export default QuickLink;
