import React, { Component } from 'react';
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import All from "./all";
import Knowledge from "./knowledge";
import Integrations from "./integrations";
import {
  Container, Title, SubHeader, TabItem, Tabs,
} from './styles';

const Education = (props) => {

  let { viewMode: mode, sizeOfKnowData, sizeOfGuidesData } = props;
  let Content = <></>;
  
  const tabItems = [
    {
      id: 1,
      name: 'All',
      viewMode: 'education_all'
    },
    {
      id: 2,
      name: 'Knowledge Articles',
      viewMode: 'education_knowledge',
      count: sizeOfKnowData,
    },
    {
      id: 3,
      name: 'Integration Guides',
      viewMode: 'education_integration',
      count: sizeOfGuidesData,
    }
  ];
  
  const onChangeView = (id) => {
    const { setViewMode } = props;
    setViewMode(id);
  };
  
  if(mode === '' && localStorage.getItem('currentView') !== null) {
    mode = localStorage.getItem('currentView');
  }
  
  switch(mode) {
    case 'education_all':
      Content = <All />;
      localStorage.setItem('currentView', 'education_all');
      break;
    case 'education_knowledge':
      Content = <Knowledge />;
      localStorage.setItem('currentView', 'education_knowledge');
      break;
    case 'education_integration':
      Content = <Integrations />;
      localStorage.setItem('currentView', 'education_integration');
      break;
    default:
      Content = <></>;
      break;
  }
  
  return (
    <>
      <Container>
        <SubHeader>
          <Title>Education</Title>
          <Tabs>
            {
              tabItems.map(item => {
                return (
                  <TabItem
                    key={item.id}
                    active={item.viewMode === mode ? 'active' : ''}
                    onClick={() => onChangeView(item.viewMode)}
                  >
                    {item.name}
                    {
                      item.count > 0 &&
                      <span>+{item.count}</span>
                    }
                  </TabItem>
                )
              })
            }
          </Tabs>
        </SubHeader>
        { Content }
      </Container>
    </>
  )
}

export default compose(
  inject(STORE_KEYS.VIEWMODESTORE, STORE_KEYS.KNOWLEDGESTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.VIEWMODESTORE]: { setViewMode, viewMode },
       [STORE_KEYS.KNOWLEDGESTORE]: { sizeOfKnowData, sizeOfGuidesData },
     }) => ({
      setViewMode,
      viewMode,
      sizeOfKnowData,
      sizeOfGuidesData,
    })
  )
)(Education);
