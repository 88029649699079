import React from 'react';
import {observable, action, reaction} from "mobx";
const moment = require('moment');
import {
  getProfile, updateProfile, markAsReadMsg, updatePersProfile, getServicesForPartner, getClientsForPartner,
  createCloudProducts, createCloudServices, getCloudAdvisors, getCloudPrograms, createSubscriptionTemplate,
  getTeams, getStorages, getMentors, createSubscriptionProduct, createSubscriptionService, createCustomer,
  createCloudmebTarget, createCloudmebTask,
} from "@/lib/rest";

class SalesforceStore {
  @observable isLoggedIn = false;
  @observable email = '';
  @observable cloudProducts = []; // cloud products
  @observable cloudServices = []; // cloud services
  @observable cloudAdvisors = []; // cloud services
  @observable cloudPrograms = []; // cloudPrograms
  @observable cloudmebServicesForClient = []; // cloudmeb services for clients
  @observable clientsForPartner = []; // clients for parnter
  @observable teams = []; // teams
  @observable storages = []; // storages
  @observable mentors = []; // mentors
  @observable monthlyRevenue = 0; // partner monthly revenue
  @observable sizeOfServicesForPartner = 0; // size of services for partners
  @observable sizeOfClientsForPartner = 0; // size of clients for partners

  @observable accountId = '';
  @observable name = '';
  @observable brand = '';
  @observable last4 = '';
  @observable exp_month = '';
  @observable exp_year = '';
  @observable recordType = '';
  @observable businessName = '';
  @observable businessType = '';
  @observable q1Data = '';
  @observable q2Data = '';
  @observable q3Data = '';
  @observable nextYearEnd = '';
  @observable fiscalYearEnd = '';
  @observable phone = '';
  @observable website = '';
  @observable totalPoints = '';
  @observable salesTaxFrequency = '';
  @observable monthlyTransactions = '';
  @observable billingStreet = '';
  @observable billingCity = '';
  @observable billingState = '';
  @observable billingPostalCode = '';
  @observable billingCountry = '';
  @observable Partner_Image__c = '';
  @observable Calendly_Link__c = '';
  @observable Partner_linked_In__c = '';
  @observable Partner_Bio__c = '';
  vModeHandle = null;

  constructor(snackbar, authStore, viewModeStore) {
    this.snackbar = snackbar;
    this.isLoggedIn = authStore.isLoggedIn;
    this.email = localStorage.getItem('email');
    this.vModeHandle = viewModeStore;

    reaction(
      () => ({
        isLoggedIn: authStore.isLoggedIn,
        email: authStore.email
      }),
      authObj => {
        this.isLoggedIn = authObj.isLoggedIn;
        this.email = authObj.email;
        if (this.isLoggedIn) {
          this.requestProfile();
          this.requestCloudAdvisors();
          this.requestCloudPrograms();
          this.requestTeams();
          this.requestStorages();
          this.requestMentors();
          this.requestCloudmebServiceForClients();
          this.requestClientsForPartner();
        }
      }
    );

    if (this.isLoggedIn) {
      this.requestProfile();
      this.requestCloudAdvisors();
      this.requestCloudPrograms();
      this.requestTeams();
      this.requestStorages();
      this.requestMentors();
      this.requestCloudmebServiceForClients();
      this.requestClientsForPartner();
    }
  }

  @action.bound requestProfile() {
    if (!this.email) return;
    this.vModeHandle.setLoadingMode(true);
    getProfile(this.email)
      .then(ret => {
        if (ret.data) {
          this.accountId = ret.data[0].accountId || '';
          this.businessName = ret.data[0].businessName || '';
          this.businessType = ret.data[0].businessType || '';
          this.q1Data = ret.data[0].q1Data || '';
          this.q2Data = ret.data[0].q2Data || '';
          this.q3Data = ret.data[0].q3Data || '';
          this.nextYearEnd = ret.data[0].nextYearEnd || '';
          this.fiscalYearEnd = ret.data[0].fiscalYearEnd || '';
          this.phone = ret.data[0].phone || '';
          this.website = ret.data[0].website || '';
          this.totalPoints = ret.data[0].totalPoints || '';
          this.salesTaxFrequency = ret.data[0].salesTaxFrequency || '';
          this.monthlyTransactions = ret.data[0].monthlyTransactions || '';
          this.recordType = ret.data[0].recordType || '';
          this.billingStreet = ret.data[0].billingStreet || '';
          this.billingCity = ret.data[0].billingCity || '';
          this.billingState = ret.data[0].billingState || '';
          this.billingPostalCode = ret.data[0].billingPostalCode || '';
          this.billingCountry = ret.data[0].billingCountry || '';
          this.name = ret.data[0].name || '';
          this.brand = ret.data[0].brand || '';
          this.last4 = ret.data[0].last4 || '';
          this.exp_month = ret.data[0].exp_month || '';
          this.exp_year = ret.data[0].exp_year || '';
          this.Partner_Image__c = ret.data[0].Partner_Image__c || '';
          this.Calendly_Link__c = ret.data[0].Calendly_Link__c || '';
          this.Partner_linked_In__c = ret.data[0].Partner_linked_In__c || '';
          this.Partner_Bio__c = ret.data[0].Partner_Bio__c || '';
        }
        this.vModeHandle.setLoadingMode(false);
      })
      .catch(() => {
        this.vModeHandle.setLoadingMode(false);
      })
  }

  @action.bound updateBusinessName(businessName) {
    if ((businessName || '').trim().length === 0) return;
    this.businessName = businessName || '';
  }

  @action.bound updateBusinessType(businessType) {
    if ((businessType || '').trim().length === 0) return;
    this.businessType = businessType || '';
  }

  @action.bound updateFiscalYearEnd(fiscalYearEnd) {
    if ((fiscalYearEnd || '').trim().length === 0) return;
    this.fiscalYearEnd = fiscalYearEnd || '';
  }

  @action.bound updateGoogleDriveLink(googleDriveLink) {
    if ((googleDriveLink || '').trim().length === 0) return;
    this.googleDriveLink = googleDriveLink || '';
  }

  @action.bound updateReceiptBankEmail(receiptBankEmail) {
    if ((receiptBankEmail || '').trim().length === 0) return;
    this.receiptBankEmail = receiptBankEmail || '';
  }

  @action.bound updateName(name) {
    if ((name || '').trim().length === 0) return;
    this.name = name || '';
  }

  // ================================================================
  @action.bound updatePartnerImage(Partner_Image__c) {
    this.Partner_Image__c = Partner_Image__c;
  }

  @action.bound updateCalendlyLink(Calendly_Link__c) {
    this.Calendly_Link__c = Calendly_Link__c;
  }

  @action.bound updatePartnerLinkedIn(Partner_linked_In__c) {
    this.Partner_linked_In__c = Partner_linked_In__c;
  }

  @action.bound updatePartnerBio(Partner_Bio__c) {
    this.Partner_Bio__c = Partner_Bio__c;
  }

  // ================================================================
  @action.bound updateBillingStreet(billingStreet) {
    if ((billingStreet || '').trim().length === 0) return;
    this.billingStreet = billingStreet || '';
  }

  @action.bound updateBillingCity(billingCity) {
    if ((billingCity || '').trim().length === 0) return;
    this.billingCity = billingCity || '';
  }

  @action.bound updateBillingState(billingState) {
    if ((billingState || '').trim().length === 0) return;
    this.billingState = billingState || '';
  }

  @action.bound updateBillingPostalCode(billingPostalCode) {
    if ((billingPostalCode || '').trim().length === 0) return;
    this.billingPostalCode = billingPostalCode || '';
  }

  @action.bound updateBillingCountry(billingCountry) {
    if ((billingCountry || '').trim().length === 0) return;
    this.billingCountry = billingCountry || '';
  }

  // ================================================================
  @action.bound updateSalesTaxFrequency(salesTaxFrequency) {
    if ((salesTaxFrequency || '').trim().length === 0) return;
    this.salesTaxFrequency = salesTaxFrequency || '';
  }

  @action.bound updateMonthlyTransactions(monthlyTransactions) {
    if ((monthlyTransactions || '').trim().length === 0) return;
    this.monthlyTransactions = monthlyTransactions || '';
  }

  @action.bound updatePhone(phone) {
    if ((phone || '').trim().length === 0) return;
    this.phone = phone || '';
  }

  @action.bound updateWebsite(website) {
    if ((website || '').trim().length === 0) return;
    this.website = website || '';
  }

  @action.bound updateProfile() {
    const obj = {
      "email": this.email,
      "businessName": this.businessName,
      "businessType": this.businessType,
      "fiscalYearEnd": this.fiscalYearEnd,
      "googleDriveLink": this.googleDriveLink,
      "receiptBankEmail": this.receiptBankEmail,
      "name": this.name,
      "salesTaxFrequency": this.salesTaxFrequency,
      "monthlyTransactions": this.monthlyTransactions,
      "phone": this.phone,
      "website": this.website,
      "billingStreet": this.billingStreet,
      "billingCity": this.billingCity,
      "billingState": this.billingState,
      "billingPostalCode": this.billingPostalCode,
      "billingCountry": this.billingCountry,
    };
    this.vModeHandle.setLoadingMode(true);
    updateProfile(obj)
      .then(ret => {
        // console.log('', ret);
        this.showSnackMsg(ret.message);
        this.vModeHandle.setLoadingMode(false);
      })
      .catch(() => {
        this.vModeHandle.setLoadingMode(false);
      });
  }

  @action.bound updatePersonalProfile() {
    const obj = {
      "email": this.email,
      "Calendly_Link__c": this.Calendly_Link__c,
      "Partner_Bio__c": this.Partner_Bio__c,
      "Partner_linked_In__c": this.Partner_linked_In__c,
      "Name": this.businessName,
      "Partner_Image__c": this.Partner_Image__c,
    };
    this.vModeHandle.setLoadingMode(true);
    updatePersProfile(obj)
      .then(ret => {
        this.showSnackMsg(ret.message);
        this.vModeHandle.setLoadingMode(false);
      })
      .catch(() => {
        this.vModeHandle.setLoadingMode(false);
      });
  }

  @action.bound createNewCMP(cloudProduct, status, startDate, paymentDate, price, users, currency) {
    return new Promise(resolve => {
      this.vModeHandle.setLoadingMode(true);
      createCloudProducts({
        accountId: this.accountId,
        productId: cloudProduct,
        currency,
        status,
        start_date: startDate,
        price,
        salesperson: '',
        payment_start_date: paymentDate,
      })
        .then(ret => {
          // console.log('', ret);
          this.showSnackMsg(ret.message);
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch(() => {
          this.vModeHandle.setLoadingMode(false);
          resolve(false);
        })
    });
  }

  @action.bound createNewCMS(
    cloudService, currency, status, expiryDate, description, related_year, retail_price, payment_start_date,
    pricing_type, partner_price, client_price
  ) {
    return new Promise(resolve => {
      this.vModeHandle.setLoadingMode(true);
      createCloudServices({
        accountId: this.accountId,
        serviceId: cloudService,
        currency: currency,
        status: status,
        expiry_date: expiryDate,
        salesperson: "",
        description: description,
        related_year: related_year,
        retail_price: retail_price,
        payment_start_date: payment_start_date,
        pricing_type: pricing_type,
        partner_price: partner_price,
        client_price: client_price,
        service_provider: ""
      })
        .then(ret => {
          // console.log('', ret);
          this.showSnackMsg(ret.message);
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch(() => {
          this.vModeHandle.setLoadingMode(false);
          resolve(false);
        })
    });
  }

  @action.bound requestCloudAdvisors() {
    this.vModeHandle.setLoadingMode(true);
    getCloudAdvisors()
      .then(ret => {
        if (ret.data) {
          this.cloudAdvisors = ret.data || [];
          this.vModeHandle.setLoadingMode(false);
        }
      })
      .catch(() => {
        this.cloudAdvisors = [];
        this.vModeHandle.setLoadingMode(false);
      })
  }

  @action.bound requestCloudPrograms() {
    this.vModeHandle.setLoadingMode(true);
    getCloudPrograms()
      .then(ret => {
        if (ret.data) {
          this.cloudPrograms = ret.data || [];
          this.vModeHandle.setLoadingMode(false);
        }
      })
      .catch(() => {
        this.cloudPrograms = [];
        this.vModeHandle.setLoadingMode(false);
      })
  }

  @action.bound requestTeams() {
    this.vModeHandle.setLoadingMode(true);
    getTeams(this.email)
      .then(ret => {
        if (ret.data) {
          this.teams = ret.data || [];
        }
        this.vModeHandle.setLoadingMode(false);
      })
      .catch(() => {
        this.teams = [];
        this.vModeHandle.setLoadingMode(false);
      })
  }

  @action.bound requestStorages() {
    this.vModeHandle.setLoadingMode(true);
    getStorages(this.email)
      .then(ret => {
        if (ret.data) {
          this.storages = ret.data || [];
        }
        this.vModeHandle.setLoadingMode(false);
      })
      .catch(() => {
        this.storages = [];
        this.vModeHandle.setLoadingMode(false);
      })
  }

  @action.bound requestMentors() {
    this.vModeHandle.setLoadingMode(true);
    getMentors(this.email)
      .then(ret => {
        if (ret.data) {
          this.mentors = ret.data || [];
          this.vModeHandle.setLoadingMode(false);
        }
      })
      .catch(() => {
        this.mentors = [];
        this.vModeHandle.setLoadingMode(false);
      })
  }

  @action.bound markAsReadMsg(Id, isRead) {
    this.vModeHandle.setLoadingMode(true);
    return new Promise(resolve => {
      markAsReadMsg({Id: Id, mode: isRead})
        .then(() => {
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch(e => {
          this.vModeHandle.setLoadingMode(false);
          resolve(false);
        })
    });
  }

  @action.bound createSubscriptionProduct(productId) {
    this.vModeHandle.setLoadingMode(true);
    return new Promise((resolve, reject) => {
      createSubscriptionProduct({
        productId, accountId: this.accountId, email: this.email,
      })
        .then((data) => {
          this.showSnackMsg(data.message || '');
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch((err) => {
          this.showSnackMsg(err.message || '');
          this.vModeHandle.setLoadingMode(false);
          reject(false);
        })
    });
  }

  @action.bound createSubscriptionService(serviceId) {
    this.vModeHandle.setLoadingMode(true);
    return new Promise((resolve, reject) => {
      createSubscriptionService({
        serviceId, accountId: this.accountId, email: this.email,
      })
        .then((data) => {
          this.showSnackMsg(data.message || '');
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch((err) => {
          this.showSnackMsg(err.message || '');
          this.vModeHandle.setLoadingMode(false);
          reject(false);
        })
    });
  }

  @action.bound createSubscriptionTemplate(templateId) {
    this.vModeHandle.setLoadingMode(true);
    return new Promise((resolve, reject) => {
      createSubscriptionTemplate({
        templateId, accountId: this.accountId, email: this.email,
      })
        .then((data) => {
          this.showSnackMsg(data.message || '');
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch((err) => {
          this.showSnackMsg(err.message || '');
          this.vModeHandle.setLoadingMode(false);
          reject(false);
        })
    });
  }

  @action.bound createCustomer(paymentId, last4, brand, exp_month, exp_year) {
    this.vModeHandle.setLoadingMode(true);
    return new Promise(resolve => {
      createCustomer({
        paymentId, accountId: this.accountId, email: this.email, last4, brand, exp_month, exp_year,
      })
        .then((data) => {
          this.vModeHandle.setLoadingMode(false);
          this.showSnackMsg(data.message || '');
          this.requestProfile();
          resolve(true);
        })
        .catch((err) => {
          this.vModeHandle.setLoadingMode(false);
          this.showSnackMsg(err.message || '');
          resolve(false);
        })
    });
  }

  /**
   *   getServicesForPartner
   */
  @action.bound requestCloudmebServiceForClients() {
    this.vModeHandle.setLoadingMode(true);
    getServicesForPartner()
      .then(ret => {
        if (ret.data) {
          const data = ret.data || [];
          const recurringList = [];
          const onetimeList = [];
          let monthlyRevenue = 0;
          let allTimeRevenue = 0;

          data.map((item, index) => {
            const Pricing_Type__c = (item || {}).Pricing_Type__c;
            if (Pricing_Type__c === "One-Time") {
              onetimeList.push(item);
              if (item.Status__c === "Completed") {
                allTimeRevenue += Number(item.Partner_Price__c);
              }
            } else if (Pricing_Type__c === "Monthly") {
              recurringList.push(item);
              if (item.Status__c === "Inprogress") {
                monthlyRevenue += Number(item.Partner_Price__c);
              }
            }
          });
          this.sizeOfServicesForPartner = data.length;

          this.monthlyRevenue = monthlyRevenue;

          const recurring_services = {
            title: 'Recurring',
            count: recurringList.length,
            total_revenue: monthlyRevenue,
            currency: 'CAD',
            unit: 'MO',
            services: [
              {
                status: 'IN PROGRESS',
                services: []
              },
              {
                status: 'CANCELED',
                services: []
              }
            ],
          };

          const onetime_services = {
            title: 'One Time',
            count: onetimeList.length,
            total_revenue: allTimeRevenue,
            currency: 'CAD',
            services: [
              {
                status: 'IN PROGRESS',
                services: []
              },
              {
                status: 'COMPLETED',
                services: []
              }
            ],
          };

          recurringList.map((item, index) => {
            if (item.Status__c === "Inprogress") {
              recurring_services.services[0].services.push({
                id: index,
                icon: item.Service_Logo_url__c,
                service_name: item.Cloud_Service_name__c || '-',
                client_name: item.Client_Account_Name__c || '-',
                subscribed: item.Start_Date__c || 'N/A',
                status: item.Status__c || '',
                currency: item.CurrencyIsoCode || '',
                unit: 'MO',
                price: item.Partner_Price__c || 0,
              });
            } else if (item.Status__c === "Cancelled") {
              recurring_services.services[1].services.push({
                id: index,
                icon: item.Service_Logo_url__c,
                service_name: item.Cloud_Service_name__c || '-',
                client_name: item.Client_Account_Name__c || '-',
                subscribed: item.Start_Date__c || 'N/A',
                status: item.Status__c || '',
                currency: item.CurrencyIsoCode || '',
                unit: 'MO',
                price: item.Partner_Price__c || 0,
              });
            }
          });

          onetimeList.map((item, index) => {
            if (item.Status__c === "Inprogress") {
              onetime_services.services[0].services.push({
                id: index,
                icon: item.Service_Logo_url__c,
                service_name: item.Cloud_Service_name__c || '-',
                client_name: item.Client_Account_Name__c || '-',
                subscribed: item.Start_Date__c || 'N/A',
                status: item.Status__c || '',
                currency: item.CurrencyIsoCode || '',
                price: item.Partner_Price__c || 0,
              });
            } else if (item.Status__c === "Completed") {
              onetime_services.services[1].services.push({
                id: index,
                icon: item.Service_Logo_url__c,
                service_name: item.Cloud_Service_name__c || '-',
                client_name: item.Client_Account_Name__c || '-',
                subscribed: item.Start_Date__c || 'N/A',
                status: item.Status__c || '',
                currency: item.CurrencyIsoCode || '',
                price: item.Partner_Price__c || 0,
              });
            }
          });

          this.cloudmebServicesForClient = {
            recurring_services,
            onetime_services,
          };
          this.vModeHandle.setLoadingMode(false);
        }
      })
      .catch(() => {
        this.cloudmebServicesForClient = {};
        this.vModeHandle.setLoadingMode(false);
      })
  }

  /**
   *  createCloudmebTask
   */
  @action.bound createCMBTask(
    clientId, taskName, dueDate, link, isTaskForClient, targetId
  ) {
    return new Promise(resolve => {
      if (clientId === this.accountId) {
        return;
      }
      this.vModeHandle.setLoadingMode(true);
      createCloudmebTask({
        clientId,
        partnerId: this.accountId,
        targetId: targetId,
        taskName,
        dueDate,
        link,
        isTaskForClient,
      })
        .then(ret => {
          // console.log('', ret);
          this.showSnackMsg(ret.message);
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch(() => {
          this.vModeHandle.setLoadingMode(false);
          resolve(false);
        })
    });
  }

  /**
   *  createCloudmebTarget
   */
  @action.bound createCMBTarget(
    clientId, targetName, targetDate, category, frequency, description, isAutoRenew, isEmailNotification
  ) {
    return new Promise(resolve => {
      this.vModeHandle.setLoadingMode(true);
      createCloudmebTarget({
        clientId,
        myId: this.accountId,
        targetName,
        targetDate,
        category,
        frequency,
        description,
        isAutoRenew,
        isEmailNotification,
      })
        .then(ret => {
          // console.log('', ret);
          this.showSnackMsg(ret.message);
          this.vModeHandle.setLoadingMode(false);
          resolve(true);
        })
        .catch(() => {
          this.vModeHandle.setLoadingMode(false);
          resolve(false);
        })
    });
  }

  /**
   *   getClientsForPartner
   */
  @action.bound requestClientsForPartner() {
    this.vModeHandle.setLoadingMode(true);
    getClientsForPartner()
      .then(ret => {
        const onboardingClients = ret.data.onboardingClients;
        const bookeepingClients = ret.data.bookeepingClients;
        const accountingClients = ret.data.accountingClients;
        const supportClients = ret.data.supportClients;
        const myClients = [];

        onboardingClients.map((item, index) => {
          myClients.push({
            company_name: item.Name || '-',
            contact_person: item.Communication_Full_Name__c || '-',
            targets: item.Target_Open_count__c || 0,
            q1: moment(item.Q1_Date__c || ''),
            q2: moment(item.Q2_Date__c || ''),
            q3: moment(item.Q2_Date__c || ''),
            new_year_end: moment(item.Next_Year_End__c || ''),
            fiscal_year_end: moment(item.Current_Year_End__c || ''),
            sales_tax_freq: item.Sales_Tax_Frequency__c || '',
            type: 'Onboarding',
          })
        });
        bookeepingClients.map((item, index) => {
          myClients.push({
            company_name: item.Name || '-',
            contact_person: item.Communication_Full_Name__c || '-',
            targets: item.Target_Open_count__c || 0,
            q1: moment(item.Q1_Date__c || ''),
            q2: moment(item.Q2_Date__c || ''),
            q3: moment(item.Q2_Date__c || ''),
            new_year_end: moment(item.Next_Year_End__c || ''),
            fiscal_year_end: moment(item.Current_Year_End__c || ''),
            sales_tax_freq: item.Sales_Tax_Frequency__c || '',
            type: 'Bookkeeping',
          })
        });
        accountingClients.map((item, index) => {
          myClients.push({
            company_name: item.Name || '-',
            contact_person: item.Communication_Full_Name__c || '-',
            targets: item.Target_Open_count__c || 0,
            q1: moment(item.Q1_Date__c || ''),
            q2: moment(item.Q2_Date__c || ''),
            q3: moment(item.Q2_Date__c || ''),
            new_year_end: moment(item.Next_Year_End__c || ''),
            fiscal_year_end: moment(item.Current_Year_End__c || ''),
            sales_tax_freq: item.Sales_Tax_Frequency__c || '',
            type: 'Accounting',
          })
        });
        supportClients.map((item, index) => {
          myClients.push({
            company_name: item.Name || '-',
            contact_person: item.Communication_Full_Name__c || '-',
            targets: item.Target_Open_count__c || 0,
            q1: moment(item.Q1_Date__c || ''),
            q2: moment(item.Q2_Date__c || ''),
            q3: moment(item.Q2_Date__c || ''),
            new_year_end: moment(item.Next_Year_End__c || ''),
            fiscal_year_end: moment(item.Current_Year_End__c || ''),
            sales_tax_freq: item.Sales_Tax_Frequency__c || '',
            type: 'Support',
          })
        });
        this.clientsForPartner = myClients;
        this.sizeOfClientsForPartner = myClients.length;
      })
      .catch(() => {
        this.clientsForPartner = [];
        this.sizeOfClientsForPartner = 0;
        this.vModeHandle.setLoadingMode(false);
      })
  }

  /**
   *  Snackbar Popup message
   */
  @action.bound showSnackMsg(msg) {
    this.snackbar({
      message: () => (
        <>
          <span>
            <b>{msg}</b>
          </span>
        </>
      )
    });
  }
}

export default (snackbar, authStore, viewModeStore) => new SalesforceStore(snackbar, authStore, viewModeStore);
