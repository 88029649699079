import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  p {
    color: #73767b;
    font-family: Circe-Bold;
    font-size: 12px;
    margin-top: 10px;
    letter-spacing: 1px;
    
    span {
      margin-left: 5px;
      font-size: 12px;
      color: #97a4b7;
    }
  }
  
  i {
    cursor: pointer;
  }
`;

export const Body = styled.div`
`;


export const ServiceItemList = styled.div`
  width: 100%;
`;
