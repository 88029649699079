import React, { Component } from "react";
import {Carousel} from "react-bootstrap";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import {SvgKnowledgeIcon, SvgArticleIcon, SvgReadMoreIcon} from "@/components/SvgIcon";
import {
  DashboardItem, Wrapper
} from "./styles";

class Header extends Component {
  render() {
    const {
      myCerts,
      clientsForPartner,
      monthlyRevenue,
    } = this.props;

    let cnt = 0;
    myCerts.map((item) => {
      if (item.status === 'Active')
        cnt ++;
    })

    const items = [];
    items.push(
      <DashboardItem>
        <div className={'title text-center'}>
          # OF CLIENTS
        </div>
        <div className={'content'}>
          <h3 className={'bold'}>{(clientsForPartner || []).length}</h3>
        </div>
      </DashboardItem>,
      <DashboardItem>
        <div className={'title text-center'}>
          # OF CERTIFICATIONS
        </div>
        <div className={'content'}>
          <h3 className={'bold'}>{cnt}</h3>
        </div>
      </DashboardItem>,
      <DashboardItem>
        <div className={'title text-center'}>
          TOTAL MONTHLY REVENUE
        </div>
        <div className={'content'}>
          <h3 className={'bold'}>${monthlyRevenue || 0}</h3>
        </div>
      </DashboardItem>
    );

    const links = []
    links.push(
        <DashboardItem
            bgcolor={'#fbf9fe'}
            titlecolor={'#9159da'}
            contentcolor={'#5b3c83'}
            bold={"true"}
            clickable={"true"}
            isarticle={"true"}
            to="/education"
            key={0}
        >
          <div className={'title'}>
            <SvgKnowledgeIcon/>
            Knowledge Articles
          </div>
          <div className={'content'}>
            <p>Learn our best practices in cloud accounting <SvgReadMoreIcon/></p>
          </div>
        </DashboardItem>,
        <DashboardItem
            titlecolor={'#53cceb'}
            contentcolor={'#457c8e'}
            bold={"true"}
            clickable={"true"}
            isguide={"true"}
            to="/education"
            key={1}
        >
          <div className={'title'}>
            <SvgArticleIcon/>
            Integration Guides
          </div>
          <div className={'content'}>
            <p>Customize your Xero with powerful add-ons <SvgReadMoreIcon/></p>
          </div>
        </DashboardItem>
    )

    return (
      <Wrapper>
        <div className="row mobile-hidden">
          {
            items.map((item, index) => (
              <div className={'item-wrapper'} key={index}>
                {items[index]}
              </div>
            ))
          }
          {links.map((item, index) => (
                <div className={'item-wrapper right'} key={index}>
                  {item}
                </div>
          ))}
        </div>
        <div className="mobile-show">
          <Carousel
              nextIcon={null}
              prevIcon={null}
          >
            {
              links.map((item, index) => (
                  <Carousel.Item key={index}>
                    {item}
                  </Carousel.Item>
              ))
            }
          </Carousel>
        </div>
      </Wrapper>
    );
  }
}

export default compose(
    inject(STORE_KEYS.CERTIFICATIONSTORE, STORE_KEYS.SALESFORCESTORE),
    observer,
    withProps(
        ({
           [STORE_KEYS.CERTIFICATIONSTORE]: {
             myCerts,
           },
           [STORE_KEYS.SALESFORCESTORE]: {
             clientsForPartner,
             monthlyRevenue,
           }
         }) => ({
            myCerts,
            clientsForPartner,
            monthlyRevenue,
        })
    )
)(Header);
