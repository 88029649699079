import React, {useState} from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';

//components
import CertificationCard from "../../components/Certifications/CertificationCard";
import AddCertificationDialog from "../../components/dialogs/AddCertificationDialog";

//styles
import {
  Container, SubHeader, Title, Center, Left,
} from "../styles";
import { LeftCard, CertContent } from "./styles";

const CertificationPage = ({ certifications, createNewCertification }) => {
  const [ openDialog, setOpenDialog ] = useState({id: null, isOpen: false});
  return (
    <>
      <Container>
        <SubHeader>
          <Title>Certification</Title>
        </SubHeader>
        <CertContent>
          {
            openDialog.id !== null &&
              <AddCertificationDialog
                open={openDialog.isOpen}
                handleClose={() => setOpenDialog({id: null, isOpen: false})}
                data={certifications.filter(item => item.id === openDialog.id)[0]}
                createNewCertification={createNewCertification}
              />
          }
          <Center smPadding>
            <div className={'wrapper'} id="center">
              <div className="row" style={{'margin-top': '20px'}}>
                {
                  certifications.map((item, index) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6 col-xs-12 mb-4" key={index}>
                      <CertificationCard data={item} setOpenModal={setOpenDialog}/>
                    </div>
                  ))
                }
              </div>
            </div>
          </Center>
          <Left>
            <div className={'wrapper'}>
              <LeftCard>
                <div className={'brand'}>
                  <img src={"/images/dashboard/solutions/img_services_002.png"} alt=""/>
                </div>
                <div className={'description'}>
                  <p>
                    Start your dream partnership completely online!
                    Take the courses, pass the certifications and help businesses around the world on Xero.
                  </p>
                </div>
              </LeftCard>
            </div>
          </Left>
        </CertContent>
      </Container>
    </>
  )
};

export default compose(
  inject(STORE_KEYS.CERTIFICATIONSTORE),
  observer,
  withProps(
    ({
       [STORE_KEYS.CERTIFICATIONSTORE]: {
         certifications,
         createNewCertification,
       },
     }) => ({
      certifications,
      createNewCertification,
    })
  )
)(CertificationPage);

