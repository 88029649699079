import React from "react";

//styles
import {Wrapper, SmallButton, MobileFooter, ActionBtn} from "./styles";

const SolutionCard = ({data}) => {

  return (
    <Wrapper>
      <div className={'name'}>
        <div className="d-flex align-items-center">
          <img src={data.icon} alt={"Image"} />
          <div>
            <h3>{data.name}</h3>
            <p className={'mobile-hidden'}>{data.property}</p>
          </div>
        </div>
      </div>
      <div className={'subscribed mobile-hidden'}>
        <p>{'Start Date'}</p>
        <h3>{data.startDate || "-"}</h3>
      </div>
      <div className={'subscribed mobile-hidden'}>
        <p>{'Certified'}</p>
        <h3>{data.certifiedDate || "-"}</h3>
      </div>
      <div className={'status mobile-hidden'}>
        {
          <>
            <p>Status</p>
            <h3>{data.status || "Pending"}</h3>
          </>
        }
      </div>
      <MobileFooter>
        <div className={'subscribed'}>
          <p>{'CERTIFIED'}</p>
          <h3>{data.certifiedDate}</h3>
        </div>
        <div className={'status'}>
          {
            <>
              <p>STATUS</p>
              <h3>{data.status || "Pending"}</h3>
            </>
          }
        </div>
      </MobileFooter>
    </Wrapper>
  );
};

export default SolutionCard;
