import React from "react";
import styled from "styled-components";

//components
import LoginTitle from "../../components/login/login-title";
import Button from "../../components/common/button";

import SignUpStatusBar from "../../components/common/SignUpStatusBar";
import MultipleSelect from "@/components/MultipleSelect";
import SimpleSelect from "@/components/SimpleSelect";

//styles
const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Login = styled.div`
  flex: 1;
  display: flex;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-image: url("/images/login/img_msg_sent.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const Brand = styled.div`
  flex: 1;
  background: #f7fdfd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 30px 30px;
  
  > img {
    max-width: 500px;
    width: 100%;
  }
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const LoginForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 30px 30px;
  background-color: #fff;
  
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    background-color: rgba(234, 249, 250, 0.9);
  }
`;

const LoginFormContent = styled.div`
  max-width: 480px;
  width: 100%;
  
   @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

const FormBody = styled.div`
  max-width: 400px;
  
   @media (max-width: ${props => props.theme.breakpoints.lg}) {
    max-width: unset;
  }
  
  > div {
    margin-top: 20px;
  }
`;

const LoginBtnGroup = styled.div`
  display: flex;
  margin-top: 30px;
  
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const PolicyText = styled.div`
  font-family: Circe-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  
  a {
    color: #5e7599;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
`;

const SignUpPage = ({ setMode, showSnackMsg }) => {
  const [trialProducts, onChangeTrialProducts] = React.useState([]);
  const [partnerService, onChangePartnerService] = React.useState([]);
  const [expYears, onChangeExpYears] = React.useState('');

  const onClickSignUpButton = () => {
    if (trialProducts.length === 0) {
      showSnackMsg("Please select Trial Products.");
      return;
    }
    if (partnerService.length === 0) {
      showSnackMsg("Please select Partner Services.");
      return;
    }
    if (expYears === '') {
      showSnackMsg("Please select Experience Years.");
      return;
    }
    setMode(4, trialProducts, partnerService, expYears);
  };

  const trials = ["Xero", "Receipt Bank", "Wagepoint", "Vend", "Shopify", "Harvest", "Timely"];
  const partners = ["Bookkeeping", "Accounting", "Technology Advisory"];
  const expData = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];

  return (
    <Wrapper>
      <Login>
        <Brand>
          <img src={"/images/login/img_msg_sent.png"} alt=""/>
        </Brand>
        <LoginForm>
          <LoginFormContent>
            <LoginTitle title={`Create Account`}/>
            <FormBody>
              <SignUpStatusBar step={4}/>
              <MultipleSelect label={"Trial Products"} data={trials} onChangeItem={onChangeTrialProducts}/>
              <MultipleSelect label={"Partner Services signup"} data={partners} onChangeItem={onChangePartnerService}/>
              <SimpleSelect label={"Experience years"} data={expData} onChangeItem={onChangeExpYears}/>
              <PolicyText>By continuing, you're agreeing to our <a href="#">Customer Terms of Service</a>, <a href="#">Privacy Policy</a> and <a href="#">Cookie Policy</a></PolicyText>
            </FormBody>
            <LoginBtnGroup>
              <Button label={`Sign Up`} onClick={onClickSignUpButton}/>
            </LoginBtnGroup>
          </LoginFormContent>
        </LoginForm>
      </Login>
    </Wrapper>
  )
}

export default SignUpPage;
