import {observable, action, reaction} from 'mobx';
import {
    getCloudCertification, createCloudmebCertification, getMyCertification
} from '@/lib/rest';

class CertificationStore {
    @observable email = '';
    @observable certifications = [];
    @observable myCerts = [];
    @observable accountId = '';
    vModeHandle = null;
    mSolutionHandle = null;
    checklistStoreHandle = null;

    constructor(snackbar, authStore, viewModeStore, mySolutionsStore, checklistStore) {
        this.snackbar = snackbar;
        this.isLoggedIn = authStore.isLoggedIn;
        this.email = localStorage.getItem('email');
        this.vModeHandle = viewModeStore;
        this.checklistStoreHandle = checklistStore;

        reaction(
            () => ({
                isLoggedIn: authStore.isLoggedIn,
                email: authStore.email
            }),
            authObj => {
                this.isLoggedIn = authObj.isLoggedIn;
                this.email = authObj.email;
                if (this.isLoggedIn) {
                    this.requestCloudCertification();
                    this.requestMyCertification();
                }
            }
        );

        reaction(
          () => ({
              accountId: mySolutionsStore.accountId,
          }),
          solutionObj => {
              this.accountId = solutionObj.accountId;
          }
        );

        if (this.isLoggedIn) {
            this.requestCloudCertification();
            this.requestMyCertification();
        }
    }

    @action.bound requestCloudCertification() {
        if (!this.email) return;
        this.vModeHandle.setLoadingMode(true);
        getCloudCertification(this.email)
            .then(ret => {
                if (ret.data) {
                    const certData = [];
                    ret.data.map((item, index) => {
                        certData.push({
                            id: index,
                            icon: item.Provider_Logo__c,
                            name: item.Certification_Name__c,
                            color: '#14B5EA',
                            note: item.Provider_account_name__c,
                            desc: [
                                item.Description__c,
                            ],
                            time: item.Duration__c,
                            price: item.Total_Points__c || 0,
                            itself: item,
                        });
                    })
                    this.certifications = certData;
                }
                this.checklistStoreHandle.requestCloudTasks();
                this.vModeHandle.setLoadingMode(false);
            })
            .catch(() => {
                this.certifications = [];
                this.vModeHandle.setLoadingMode(false);
            });
    }

    @action.bound createNewCertification(certificationId) {
        return new Promise(resolve => {
            this.vModeHandle.setLoadingMode(true);
            createCloudmebCertification({
                accountId: this.accountId,
                certificationId,
            })
              .then(ret => {
                  // console.log('', ret);
                  this.showSnackMsg(ret.message);
                  this.checklistStoreHandle.requestCloudTasks();
                  this.vModeHandle.setLoadingMode(false);
                  resolve(true);
              })
              .catch(() => {
                  this.vModeHandle.setLoadingMode(false);
                  resolve(false);
              })
        });
    }

    @action.bound requestMyCertification() {
        if (!this.email) return;
        this.vModeHandle.setLoadingMode(true);
        getMyCertification()
          .then(ret => {
              if (ret.data) {
                  const certData = [];
                  ret.data.map((item, index) => {
                      certData.push({
                          id: index,
                          icon: item.certification_logo__c || '',
                          name: item.Certification_Name__c,
                          startDate: item.Start_Date__c || '',
                          certifiedDate: item.Certified_Date__c || '',
                          status: item.Status__c || '',
                      });
                  })
                  this.myCerts = certData;
              }
              this.vModeHandle.setLoadingMode(false);
          })
          .catch(() => {
              this.myCerts = [];
              this.vModeHandle.setLoadingMode(false);
          });
    }
}

export default (snackbar, authStore, viewModeStore, mySolutionsStore, checklistStore) =>
  new CertificationStore(snackbar, authStore, viewModeStore, mySolutionsStore, checklistStore);
