import React from "react";

//components
import StatusPanel from "../../StatusPanel";
import ClientsServicePanel from "../../../components/Clients/ClientServicePanel";

//styles
import {Content, Center, Right} from "../../styles";

const Services = ({ data }) => {

  const [detailViewId, setDetailViewId] = React.useState('');

  return (
    <Content>
      <Center>
        <div className={'wrapper'} id="center">
          <ClientsServicePanel data={data.recurring_services} />
          <ClientsServicePanel data={data.onetime_services} />
        </div>
      </Center>
      <Right>
        <StatusPanel />
      </Right>
    </Content>
  )
};

export default Services;
