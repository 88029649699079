import React from "react";
import { compose, withProps } from "recompose";
import { inject, observer } from "mobx-react";

import { STORE_KEYS } from '@/stores';
import {
  Container, Wrapper, Left, Title, Name, Right,
  Header, Description, Info, Price, Warning, ButtonGroup,
  CancelButton, PayButton, CloseButton,
} from "./styles";
import {AddButton} from "../AddServiceDialog/styles";

const AddTemplateDialog = (props) => {

  const [isSuccess, setIsSuccess] = React.useState(false);
  const { item, last4, createSubscriptionTemplate } = props; // template data
  const [isLock, setIsLock] = React.useState(false);
  const paymentIssue = last4 === '';
  const data =
    {
      id: 1,
      icon: item ? item.Template_Logo__c : '',
      name: item ? item.Template_Title__c : '',
      type: '',
      text: item ? item.Description__c : '',
      price: item ? item.Price__c : '',
      unit: item ? item.CurrencyIsoCode : '',
      logo: '/images/dashboard/template_icon.png'
    };

  const handleClickAddBtn = () => {
      if (!isLock) {
          setIsLock(true);
          createSubscriptionTemplate(item.Id)
              .then(() => {
                  setIsLock(false);
                  setIsSuccess(true);
              })
              .catch((err) => {
                  setIsSuccess(false);
                  setIsLock(false);
                  console.log('err:', err);
              });
      }
  };

  return (
    <Container isShow={props.open}>
      <Wrapper isSuccessPanel={true}>
        <Right isSuccess={isSuccess}>
          <Header>
            <Title>BUY XERO TEMPLATE</Title>
            <Name>
              <img src={data.icon} alt="TEMPLATE Image" />
              <div className={'name'}>{data.name} <span className={'type'}>{data.type}</span></div>
            </Name>
          </Header>
          <Description success={isSuccess}>
            {
              !isSuccess ?
                <p>{data.text}<br/><br/></p>
              :
                <>
                  <Info>
                    <p className={'bold'}>Thank you for your payment</p>
                    <p>Your login credentials will be emailed to you shortly.</p>
                    <p>{`Manager your subscription under\n`} <a>{`\nMy Solutions`}</a></p>
                  </Info>
                </>
            }

          </Description>
          {
            !isSuccess ?
              <div>
                <Price>${data.price} <span className="unit">{data.unit}</span></Price>
                {
                  paymentIssue &&
                  <Warning><p>You have to add payment method to complete purchase.</p></Warning>
                }
                <ButtonGroup>
                  <CancelButton onClick={props.handleClose}>Cancel</CancelButton>
                    {
                        paymentIssue ? (
                            <AddButton to={'/billing'}>Add Method</AddButton>
                        ) : (
                            <PayButton onClick={() => handleClickAddBtn()} disabled={isLock}>Pay Now</PayButton>
                        )
                    }
                </ButtonGroup>
              </div>
              : <CloseButton onClick={props.handleClose}>Close</CloseButton>
          }
        </Right>
      </Wrapper>
    </Container>
  )
};

export default compose(
    inject(STORE_KEYS.SALESFORCESTORE),
    observer,
    withProps(
        ({
             [STORE_KEYS.SALESFORCESTORE]: {
                 last4,
                 createSubscriptionTemplate,
             },
         }) => ({
            last4,
            createSubscriptionTemplate,
        })
    )
)(AddTemplateDialog);
