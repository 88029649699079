import React from 'react';

import {
  Wrapper, Label, MLabel
} from './styles';

export default () => (
  <Wrapper>
    <Label>404</Label>
    <MLabel href="/" alt="">Sorry, the page you are looking for could not found.</MLabel>
  </Wrapper>
);
