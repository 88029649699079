import React from "react";
import {CompletedIcon, ListTitle, PTIcon, ShowMoreBtn, TargetList} from "@/containers/Targets/styles";
import CircleProgressBar from "@/components/common/circle-progressbar";
import TaskList from "../TaskList";
import NoTaskPanel from "../NoTaskPanel";
import AddClientTaskDialog from "../../../components/dialogs/AddClientTaskDialog";
import EditMenu from "../../../components/Menu";
import {
  Wrapper, Header, MobileHeader, Body, Footer,
} from './styles';
import EditClientTargetDlg from "../../../components/dialogs/EditClientTargetDialog";

const TargetBlock = ({
  target, showAll, setDetailViewId, markTaskAsComplete, isClientTasks = false, activeClientName = '', activeClientId = '',
}) =>{

  let isAllCompleted = target.completed;

  const Accountcc__c = target.Accountcc__c;

  const targetId = target.targetId;

  const targetName = target.target_name;

  const targetRelatedName = target.target_property;

  const targetCategory = target.category;

  const targetDueDate = target.date;

  const targetFrequency = target.frequency;

  const targetDescription = target.description;

  const [isShowContent, setIsShowContent] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);

  const [openEditTargetDialog, setOpenEditTargetDialog] = React.useState(false);

  const [dlgMode, setDlgMode] = React.useState("left");

  const onClickMoreBtn = (id) => {
    setDetailViewId(id);
    document.getElementById('scroll-top').scrollTop = 0;
  };

  const toggleButton = (isAllCompleted, isShowContent, isMobile) => {
    return (
        <div className={`header-item ml-2 ${isMobile ? 'angle_must' : ''}`}>
          {
            isAllCompleted ?
                <i className="fa fa-angle-down" />
                :
                isShowContent ?
                    <i className="fa fa-angle-up" onClick={() => setIsShowContent(!isShowContent || isAllCompleted)}/>
                    :
                    <i className="fa fa-angle-down" onClick={() => setIsShowContent(!isShowContent)}/>
          }
        </div>
    )
  }

  const onClickTargetEdit = (id) => {
    console.log('======= [onClickTargetEdit]', id);
    setOpenEditTargetDialog(true);
  };

  const onClickTargetDelete = (id) => {
    console.log('======= [onClickTargetDelete]', id);
    setOpenEditTargetDialog(true);
  };

  let cnt = 0;

  return (
    <Wrapper finished={ isAllCompleted } id={'scroll-top'}>
      <Header finished={isAllCompleted} onClick={() => setIsShowContent(!isShowContent)}>
        <div className="d-flex align-items-center h-title" style={{'minWidth': '20%'}}>
          <img src={target.icon} alt="img" className={'header-icon'} />
          <div className={'header-item align-items-start'}>
            <h3 className={'target-name'}>
              {target.target_name}
              {isClientTasks && (
                <EditMenu
                  id={targetId}
                  onClickEdit={onClickTargetEdit}
                  onClickDelete={onClickTargetDelete}
                />
              )}
            </h3>
            <p>{target.target_property}</p>
          </div>
        </div>
        <div className={'header-item h-task'}>
          <span className="upper-case">TASKS</span>
          <h3>{target.tasks_count}</h3>
        </div>
        <div className={'header-item h-due-date'} style={{'minWidth': '15%'}}>
          <span className="upper-case">DUE DATE</span>
          <h3 className={'date'}>{target.dateStr}</h3>
        </div>
        <div className={'header-item h-reward'}>
          <span className="upper-case">REWARD</span>
          <h3 className={'price'}><PTIcon/>{target.price}</h3>
        </div>
        <div className={'h-circle'}>
        {
          isAllCompleted ?
              <CompletedIcon className='fa fa-check-circle' />
              :
              <CircleProgressBar value={target.percentage} cutout={90}/>
        }
        </div>
        <div className={'header-item'}>
          {
              isShowContent ?
                <i className="fa fa-angle-up"/>
                :
                <i className="fa fa-angle-down"/>
          }
        </div>
      </Header>
      <MobileHeader finished={isAllCompleted} onClick={() => setIsShowContent(!isShowContent)}>
        <div className="d-flex align-items-center justify-content-between mb_header_custom">
          <div className="d-flex align-items-center" style={{'minWidth': '20%'}}>
            <img src={target.icon} alt="img" className={'header-icon'} />
            <div className={'header-item align-items-start'}>
              <h3 className={'target-name'}>{target.target_name}</h3>
              <p>{target.target_property}</p>
            </div>
            {toggleButton(isAllCompleted, isShowContent, true)}
          </div>
          <div className="d-flex align-items-center">
            {
              isAllCompleted ?
                <CompletedIcon className='fa fa-check-circle' />
                :
                <CircleProgressBar value={target.percentage} cutout={90}/>
            }
            {toggleButton(isAllCompleted, isShowContent, false)}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-around mt-3">
          <div className={'header-item'}>
            <span className="upper-case">TASKS</span>
            <h3>{target.tasks_count}</h3>
          </div>
          <div className={'header-item'} style={{'minWidth': '15%'}}>
            <span className="upper-case">DUE DATE</span>
            <h3 className={'date'}>{target.dateStr}</h3>
          </div>
          <div className={'header-item'}>
            <span className="upper-case">REWARD</span>
            <h3 className={'price'}><PTIcon/>{target.price}</h3>
          </div>
        </div>
      </MobileHeader>
      {isShowContent && (
        <Body className={isShowContent ? 'visible' : ''}>
          <div className="row">
            <div className="col-xl-6 col-lg-12 d-flex flex-column">
              <ListTitle mt={20}>
                <div className="task_title">
                  My Tasks <span>{target.myTasksLength}</span>
                </div>
                {isClientTasks && (
                  <button
                    className="btn_add_task"
                    onClick={() => {
                      setOpenDialog(true);
                      setDlgMode("left");
                    }}
                  >
                    ADD TASK
                  </button>
                )}
              </ListTitle>
              <TargetList color={'#fafbfb'}>
                {
                  (target.myTasks || []).map((item, index) => {
                    if (item.tasks.length > 0) {
                      cnt++;
                      if (!showAll && cnt > 2) return;
                      return (
                        <TaskList
                          data={item}
                          isMyTask={true}
                          key={index}
                          markTaskAsComplete={markTaskAsComplete}
                          isClientTasks={isClientTasks}
                        />
                      )
                    }
                  })
                }
              </TargetList>
            </div>
            <div className="col-xl-6 col-lg-12 d-flex flex-column">
              <ListTitle mt={20}>
                <div className="task_title">
                  <img src={'/images/Solutions/Product_Cloudmeb.svg'} alt={"img"} />
                  {isClientTasks ? 'Assigned Client Tasks' : 'Assigned Tasks'}
                  <span>{target.teamTasksLength}</span>
                </div>
                {isClientTasks && (
                  <button
                    className="btn_add_task"
                    onClick={() => {
                      setOpenDialog(true);
                      setDlgMode("right");
                    }}
                  >
                    ADD TASK
                  </button>
                )}
              </ListTitle>
              {
                <TargetList color={'#fafbfb'}>
                  {
                    target.teamTasksLength > 0 ? (
                      <>
                        {
                          (target.teamTasks || []).map((item, index) => (item.tasks || []).length > 0 && (
                            <TaskList
                              data={item}
                              isMyTask={false}
                              key={index}
                              markTaskAsComplete={markTaskAsComplete}
                              isClientTasks={isClientTasks}
                            />
                          ))
                        }
                      </>
                    ) : (
                      <NoTaskPanel/>
                    )
                  }
                </TargetList>
              }
            </div>
          </div>
        </Body>
      )}
      {isShowContent && (
        <Footer className={isShowContent ? 'visible' : ''}>
          {
            !showAll && !isAllCompleted &&
            <ShowMoreBtn
              className="upper-case"
              onClick={() => onClickMoreBtn(target.target_name)}
            >
              SHOW MORE
            </ShowMoreBtn>
          }
        </Footer>
      )}
      {openDialog && (
        <AddClientTaskDialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          dlgMode={dlgMode}
          clientId={Accountcc__c}
          targetId={targetId}
          targetCategory={targetRelatedName}
        />
      )}
      {openEditTargetDialog && (
        <EditClientTargetDlg
          open={openEditTargetDialog}
          handleClose={() => setOpenEditTargetDialog(false)}
          activeClientName={activeClientName}
          activeClientId={activeClientId}
          mTargetId={targetId}
          mTargetName={targetName}
          mTargetCategory={targetCategory}
          mTargetDueDate={targetDueDate}
          mTargetFrequency={targetFrequency}
          mTargetDescription={targetDescription}
        />
      )}
    </Wrapper>
  )
};

export default TargetBlock;
