import styled, {css} from "styled-components";
import React, { Component } from "react";
import ListItem from "../../ListItem";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  background-color: ${props => props.color || '#fff'};
  padding: 0 15px 15px 15px;
  height: 100%;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0;
  }
  
  ${props => props.isShow && css`
    display: flex;
  `}
`;

class TaskList extends Component {
  render() {
    const { selectedTabId, data, markTaskAsComplete, isTeam = false } = this.props;
    const dataList = isTeam ? (data || {}).teamTasks : (data || {}).myTasks;
    return (
      <Wrapper color={'#fafbfb'}>
        {
          (dataList || []).map((item, index) => {
            if (selectedTabId === 'All') {
              return item.tasks.length > 0 && (
                <ListItem data={item} isMyTask={!isTeam} key={index} markTaskAsComplete={markTaskAsComplete}/>
              );
            } else if (selectedTabId === 'Todo') {
              return item.tasks.length > 0 && item.state !== 'COMPLETED' && item.state !== 'EXPIRED' && (
                <ListItem data={item} isMyTask={!isTeam} key={index} markTaskAsComplete={markTaskAsComplete}/>
              );
            } else if (selectedTabId === 'Done') {
              return item.tasks.length > 0 && item.state === 'COMPLETED' && (
                <ListItem data={item} isMyTask={!isTeam} key={index} markTaskAsComplete={markTaskAsComplete}/>
              );
            }
          })
        }
      </Wrapper>
    )
  }
}

export default TaskList;

