import React, { useState } from "react";

//styles
import { Container, Icon, Footer, AddButton, Header, Desc, Content, PTView } from "./styles"
import {SvgPointIcon} from "../../SvgIcon";

const CertificationCard = ({data, setOpenModal}) => {

  const [ isHovered, setHover ] = useState(false);
  return (
    <Container onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Header style={{backgroundColor: 'white'}}>
        {
          data.icon &&  <Icon src={data.icon} />
        }
        <div>
          <h3 style={{color: '#405c85'}}>{data.name}</h3>
          <p style={{color: '#97a4b7'}}>{data.note}</p>
        </div>
      </Header>
      <Content>
        {
          data.desc.map((desc, index) => (
            <Desc key={index}>
              {data.desc.length > 1 && <span>&#8226; </span>}
              <p>
                {desc}
              </p>
            </Desc>
          ))
        }
      </Content>
      <Footer>
        <div>
          <p className={'time'}>{data.time}</p>
        </div>
        <PTView><SvgPointIcon className="mr-1"/>{data.price}</PTView>
        <AddButton onClick={() => setOpenModal({id: data.id, isOpen: true})}>ADD</AddButton>
      </Footer>
    </Container>
  );
}

export default CertificationCard;
