import styled from "styled-components";
import {Content} from "../styles";

export const CertContent = styled(Content)`
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
  }
`;

export const LeftCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f3f6;
  border-radius: 7px;
  
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
  
  .brand {
    background-color: #fbfefe;
    padding: 25px;
    
    img {
      width: 100%;
    }
    
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      max-width: 40%;
      display: flex;
      align-items: center;
      padding: 10px 20px;
    }
  }
  
  .description {
    padding: 15px;
    display: flex;
    min-height: 100px;
    
    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;
